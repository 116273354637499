import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "utils/MuiWrapper/components";
import { useEffect, useCallback } from "react";
import { useAlertSnackbarState } from "../../AlertSnackbar/AlertSnackbar";
import { useFormik } from "formik";
import { bomItemSchema } from "./BOMItemSchema";
import { hasError } from "utils/FormikUtilities";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AdHocBOMItemPayloadType } from "types/BOM";
import { addAdHocBOMItemById } from "api/bom";

export const AddBOMItem = ({ isOpen, handleClose }) => {
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { bomId, projectId, projectTemplateId, numComponentsAdded } = useParams();

  const navigate = useNavigate();

  const onSubmit = () => {
    addItem(values);
  };

  const updateNumComponents = useCallback(() => {
    const numComponents = !numComponentsAdded ? 0 : parseInt(numComponentsAdded);
    navigate(`/bom-view/${bomId}/project/${projectId}/added/${numComponents + 1}`);
  }, [numComponentsAdded, bomId, projectId, projectTemplateId]);

  const { mutate: addItem } = useMutation({
    mutationFn: (payload: AdHocBOMItemPayloadType) => {
      return addAdHocBOMItemById(bomId || "", payload);
    },
    onSuccess: () => {
      updateNumComponents();
      setAlert({
        type: "success",
        message: "Line item has been added to the BOM.",
      });
      handleClose(true);
    },
    onError: (error: Error) => {
      console.error(error);
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const { values, errors, handleBlur, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      qty: 1,
      supplier: "",
      supplier_part_number: "",
      manufacturer: "",
      manufacturer_part_number: "",
      description: "",
      price: 0,
      discount: 0,
      total_monthly: 0,
    },
    validationSchema: bomItemSchema,
    onSubmit,
  });

  useEffect(() => {
    resetForm();
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" scroll="paper" aria-labelledby="scroll-dialog-title">
      <DialogContent>
        <DialogTitle id="scroll-dialog-title">Add BOM Item</DialogTitle>
      </DialogContent>
      <DialogContent>
        <TextField
          name="qty"
          margin="normal"
          fullWidth
          label="Quantity"
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.qty}
          error={hasError(touched.qty, errors.qty)}
          helperText={hasError(touched.qty, errors.qty) ? errors.qty : ""}
        />
        <TextField
          name="supplier"
          margin="normal"
          fullWidth
          label="Supplier"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.supplier}
          error={hasError(touched.supplier, errors.supplier)}
          helperText={hasError(touched.supplier, errors.supplier) ? errors.supplier : ""}
        />
        <TextField
          name="supplier_part_number"
          margin="normal"
          fullWidth
          label="Supplier Part Number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.supplier_part_number}
          error={hasError(touched.supplier_part_number, errors.supplier_part_number)}
          helperText={
            hasError(touched.supplier_part_number, errors.supplier_part_number) ? errors.supplier_part_number : ""
          }
        />
        <TextField
          name="manufacturer"
          margin="normal"
          fullWidth
          label="Manufacturer"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.manufacturer}
          error={hasError(touched.manufacturer, errors.manufacturer)}
          helperText={hasError(touched.manufacturer, errors.manufacturer) ? errors.manufacturer : ""}
        />
        <TextField
          name="manufacturer_part_number"
          margin="normal"
          fullWidth
          label="Manufacturer Part Number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.manufacturer_part_number}
          error={hasError(touched.manufacturer_part_number, errors.manufacturer_part_number)}
          helperText={
            hasError(touched.manufacturer_part_number, errors.manufacturer_part_number)
              ? errors.manufacturer_part_number
              : ""
          }
        />
        <TextField
          name="description"
          margin="normal"
          fullWidth
          label="Description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          error={hasError(touched.description, errors.description)}
          helperText={hasError(touched.description, errors.description) ? errors.description : ""}
        />
        <TextField
          name="price"
          margin="normal"
          fullWidth
          label="Price"
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.price}
          error={hasError(touched.price, errors.price)}
          helperText={hasError(touched.price, errors.price) ? errors.price : ""}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TextField
          name="discount"
          margin="normal"
          fullWidth
          label="Discount"
          type="number"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.discount}
          error={hasError(touched.discount, errors.discount)}
          helperText={hasError(touched.discount, errors.discount) ? errors.discount : ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          name="total_monthly"
          margin="normal"
          fullWidth
          label="Total Monthly Cost"
          type="number"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.total_monthly}
          error={hasError(touched.total_monthly, errors.total_monthly)}
          helperText={hasError(touched.total_monthly, errors.total_monthly) ? errors.total_monthly : ""}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Close</Button>
        <form onSubmit={handleSubmit}>
          <Button type="submit" sx={{ float: "right" }}>
            Submit
          </Button>
        </form>
      </DialogActions>
    </Dialog>
  );
};
