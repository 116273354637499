export const enum Action {
  View = 1,
  Create = 2,
  Edit = 4,
  Delete = 8,
}

export const enum Roles {
  Admin = "admin",
  Architect = "architect",
  Manager = "manager",
  Purchaser = "purchaser",
  User = "user",
  Reader = "reader",
}

export const enum BomStatus {
  Pending = "Pending",
  PartialRX = "Partial RX",
  Delivered = "Delivered",
  Purchased = "Purchased",
  ReadyForPurchase = "Ready For Purchase",
  Hold = "Hold",
  ReadyForTechReview = "Ready For Tech Review",
  Donor = "Donor",
  Deleted = "Deleted",
}

export const enum BOMFields {
  PONumber = "po_number",
  RFPNumber = "rfp_number",
}
