import React from "react";
import { render } from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { FortuneTellerUIContextProvider } from "./context";
import { LocalizationProvider, AdapterDateFns } from "utils/MuiWrapper/datepicker";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const root = document.getElementById("root");
const queryClient = new QueryClient();

render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <FortuneTellerUIContextProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </FortuneTellerUIContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  root
);
