import { useMutation } from "@tanstack/react-query";
import { updateTemplate } from "api/template";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useParams } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import { DATACENTER_TEMPLATE } from "utils/constants";

export const DataCenterConfirm = ({ isOpen, setIsOpen, getTemplate }) => {
  const { templateId } = useParams();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => confirm();

  const { mutate: confirm } = useMutation({
    mutationFn: () => updateTemplate(templateId || "", { type: DATACENTER_TEMPLATE }),
    onSuccess: () => {
      getTemplate(templateId);
      handleClose();
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <DialogTitle>
          <Typography sx={{ fontSize: "16px" }}>Data Center Template Confirm</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle> 
      </DialogContent>
      <DialogContent>
        <Typography variant="body2">Would you like to make this template a bi-directional template?</Typography>
        <Typography variant="body2">This is a one-time action.</Typography>
      </DialogContent>
      <DialogContent sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="primary" onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogContent>
    </Dialog>
  );
};
