import React, { createContext, useContext, useMemo, useReducer } from "react";

type FortuneTellerUIState = {
  miniSidenav: boolean;
  menuPinned: boolean;
};

type FortuneTellerUIAction = {
  type: "MINI_SIDENAV" | "MENU_PINNED";
  value: boolean;
};

const initialState: FortuneTellerUIState = {
  miniSidenav: false,
  menuPinned: true,
};

type FortuneTellerUIContext = [
  FortuneTellerUIState,
  React.Dispatch<FortuneTellerUIAction>
];

const FortuneTellerUI = createContext<FortuneTellerUIContext>([
  initialState,
  () => null,
]);

FortuneTellerUI.displayName = "FortuneTellerUIContext";

function reducer(state: FortuneTellerUIState, action: FortuneTellerUIAction) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "MENU_PINNED": {
      return { ...state, menuPinned: action.value };
    }
    default: {
      throw Error(`Unrecognized action type: ${action.type}`);
    }
  }
}

function FortuneTellerUIContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: FortuneTellerUIContext = useMemo(
    () => [state, dispatch],
    [state, dispatch]
  );

  return (
    <FortuneTellerUI.Provider value={value}>
      {children}
    </FortuneTellerUI.Provider>
  );
}

function useFortuneTellerUIContext() {
  return useContext(FortuneTellerUI);
}

const setMenuPinned = (dispatch, value) =>
  dispatch({ type: "MENU_PINNED", value });
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });

export {
  FortuneTellerUIContextProvider,
  useFortuneTellerUIContext,
  setMenuPinned,
  setMiniSidenav,
};
