import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "utils/MuiWrapper/components";
import { Close, MoreVertical, ViewFilled, ViewOffFilled } from "utils/MuiWrapper/icons";
import React, { Fragment, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { _getVariableIndexById } from "api/template";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";

const GlobalVariables = ({
  templateId,
  templateIsPublished,
  variables,
  createGlobalVariable,
  updateGlobalVariable,
  deleteGlobalVariable,
  templateVariables,
  assignTemplateVariable,
  unAssignTemplateVariable,
}) => {
  const variableInitialValue = { id: "", name: "" };

  const [showAll, setShowAll] = useState(true);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogVariable, setDialogVariable] = useState(variableInitialValue);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const selectedTemplateVariableIndex = (id) => {
    return _getVariableIndexById(templateVariables, id);
  };

  const tryGetSelectedTemplateVariable = (variableId) => {
    let v;
    for (let i = 0; i < templateVariables?.length; i++) {
      v = templateVariables[i];
      if (v.variable_id == variableId) {
        return v;
      }
    }
    return undefined;
  };

  const toggleGlobalVariable = (variableId) => {
    if (selectedTemplateVariableIndex(variableId) >= 0) {
      const index = selectedTemplateVariableIndex(variableId);
      const templateVariable = templateVariables[index];
      unAssignTemplateVariable(templateVariable.id);
    }
    if (selectedTemplateVariableIndex(variableId) < 0) {
      assignTemplateVariable(variableId);
    }
  };

  const handleCancel = () => {
    setDialogIsOpen(false);
    setDialogVariable(variableInitialValue);
  };
  const handleOpen = (variable) => {
    setDialogVariable(variable);
    setDialogIsOpen(true);
  };
  const handleSave = () => {
    if (!dialogVariable.name.trim().length) {
      setAlert({
        type: "error",
        message: "Unable to Save Global Variable. Global Variables must have a name.",
      });
      return;
    }

    if (variableInitialValue.id == dialogVariable.id) {
      createGlobalVariable(dialogVariable.name);
    } else {
      updateGlobalVariable(dialogVariable.id, { name: dialogVariable.name });
    }
    handleCancel();
  };

  return (
    <Stack direction="row" spacing={0}>
      <Box sx={{ backgroundColor: "secondary.main" }} width="100%" p={1}>
        <Box p={2} display="flex" alignItems="center">
          <Box marginLeft="2%">Global Variables</Box>
        </Box>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            width="96%"
            sx={
              showAll
                ? {
                    flexWrap: "wrap",
                    maxHeight: "200px",
                    overflowY: "scroll",
                    backgroundColor: "secondary.main",
                  }
                : {
                    flexWrap: "wrap",
                    backgroundColor: "secondary.main",
                  }
            }
          >
            {variables?.results?.map((variable, i) => {
              const templateVar = tryGetSelectedTemplateVariable(variable.id);
              const assignedThisTemplate = templateVar && templateVar.template_id === templateId;

              return (
                <Fragment key={i}>
                  <ButtonGroup
                    disabled={templateIsPublished}
                    color="primary"
                    sx={{ margin: "5px" }}
                    variant={assignedThisTemplate ? "contained" : "outlined"}
                  >
                    <Button
                      sx={{ justifyContent: "left", maxWidth: "400px", textOverflow: "ellipsis", overflow: "hidden" }}
                      onClick={() => toggleGlobalVariable(variable.id)}
                    >
                      {variable.name}
                    </Button>
                    <Button startIcon={<MoreVertical />} onClick={() => handleOpen(variable)} />
                  </ButtonGroup>
                </Fragment>
              );
            })}
          </Box>
        </Box>
        <Dialog
          open={dialogIsOpen}
          onKeyUp={(e) => {
            if (e.key === "Enter") handleSave();
          }}
        >
          <DialogContent dividers>
            <DialogTitle>
              Edit Global Variable
              <IconButton onClick={handleCancel}>
                <Close />
              </IconButton>
            </DialogTitle>
          </DialogContent>
          <DialogContent>
            <TextField
              id="filled-basic"
              hiddenLabel
              fullWidth
              inputRef={(input) => {
                if (input != null) {
                  input.focus();
                }
              }}
              onFocus={(event) => {
                event.target.select();
              }}
              disabled={templateIsPublished}
              value={dialogVariable.name}
              onChange={(e) =>
                setDialogVariable((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            {dialogVariable.id !== variableInitialValue.id && (
              <Button
                disabled={templateIsPublished}
                color="secondary"
                onClick={() => {
                  deleteGlobalVariable(dialogVariable);
                  handleCancel();
                }}
                sx={{
                  marginTop: "1%",
                  marginBottom: "1%",
                }}
              >
                Delete
              </Button>
            )}
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Box flex={1} paddingLeft={1} style={{ marginTop: "3%" }} />
        <Box>
          <Stack direction="row" spacing={0} justifyContent="space-between">
            <Box flex={1}>
              <Stack display="flex" marginTop="0%" justifyContent="center" direction="row" spacing={2}>
                {!showAll && (
                  <Button
                    disabled={templateIsPublished}
                    startIcon={<ViewOffFilled />}
                    onClick={() => setShowAll(!showAll)}
                  >
                    Collapse
                  </Button>
                )}
                {showAll && (
                  <Button
                    disabled={templateIsPublished}
                    startIcon={<ViewFilled />}
                    onClick={() => setShowAll(!showAll)}
                  >
                    Show All
                  </Button>
                )}
                <Button
                  disabled={templateIsPublished}
                  startIcon={<AddIcon />}
                  onClick={() => handleOpen(variableInitialValue)}
                >
                  New Global Variable
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default GlobalVariables;
