import React from "react";
import { BaseSidenav } from "./BaseSidenav";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "utils/MuiWrapper/components";
import { sideNavRoutes } from "routes";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";

import { useFortuneTellerUIContext } from "context";
import { TEXT_FONT_SIZE } from "utils/constants";

export const TopSidenav = ({ ...rest }) => {
  const [{ miniSidenav }] = useFortuneTellerUIContext();
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);

  return (
    <BaseSidenav {...rest}>
      <List>
        {sideNavRoutes.map((route) => (!route.allowList || (user && route.allowList.includes(user._embedded.role.name))) &&
          (!route.blockList || (user && !route.blockList.includes(user._embedded.role.name))) && (
          <ListItem key={route.key} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              component={Link}
              to={route.route}
              sx={{
                minHeight: 48,
                justifyContent: miniSidenav ? "center" : "initial",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: miniSidenav ? "auto" : 3,
                  justifyContent: "center",
                }}
              >
                {route.icon}
              </ListItemIcon>
              <ListItemText
                primary={route.name}
                disableTypography
                sx={{ fontSize: TEXT_FONT_SIZE, opacity: miniSidenav ? 0 : 1 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </BaseSidenav>
  );
};
