import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "utils/MuiWrapper/components";
import { IconButton } from "@nepgpe/components-library";
import { Delete as DeleteIcon } from "utils/MuiWrapper/icons";

import { deleteProjectTemplate } from "api/project";
import ConfirmationDialog from "../shared/ConfirmationDialog";

export default function DeleteProjectTemplate({ projectId, projectTemplateId }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { mutate: deleteProjectTemplateMutation } = useMutation({
    mutationFn: async () => {
      return await deleteProjectTemplate(projectId, projectTemplateId);
    },
    onSuccess: () => {
      setIsConfirmOpen(false);
      queryClient.invalidateQueries(["project", projectId]);
      navigate(`/projects/${projectId}`);
    },
  });

  const handleDeleteClick = () => setIsConfirmOpen(true);

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmOpen}
        title={"Are you sure you want to want to delete the template?"}
        setIsOpen={setIsConfirmOpen}
        confirmAction={deleteProjectTemplateMutation}
        displayText={{ confirm: "Yes", cancel: "No" }}
      />
      <Box>
        <Typography>
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Typography>
      </Box>
    </>
  );
}
