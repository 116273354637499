import { Button, TextField } from "utils/MuiWrapper/components";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { addressSchema } from "./AddressSchema";
import { FormModalProps } from "components/ComponentToolbox/CreateComponent";

export const AddEditAddress = ({ isOpen, handleClose, data }: FormModalProps) => {
  const onSubmit = () => {
    if (id) {
      handleClose({ id: id, ...values });
    } else {
      handleClose(values);
    }
  };
  const onCancel = () => {
    handleClose(null);
  };
  const { values, errors, handleBlur, touched, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: {
      address_line1: "",
      address_line2: "",
      city: "",
      state_region: "",
      country: "",
      postcode: "",
      phone_number: "",
    },
    validationSchema: addressSchema,
    onSubmit,
  });

  const [id, setId] = useState<string>();

  useEffect(() => {
    resetForm();
    if (data) {
      setId(data.id);
      setFieldValue("address_line1", data.address_line1);
      setFieldValue("address_line2", data.address_line2);
      setFieldValue("city", data.city);
      setFieldValue("state_region", data.state_region);
      setFieldValue("country", data.contry);
      setFieldValue("postcode", data.postcode);
      setFieldValue("phone_number", data.phone_number);
    } else {
      setId(undefined);
    }
  }, [isOpen]);

  const hasError = (touched: boolean | undefined, error: string | undefined) => {
    return touched && (error ?? "").length > 0;
  };

  return isOpen ? (
    <>
      <TextField
        name="address_line1"
        margin="normal"
        fullWidth
        autoFocus
        label="Address 1"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.address_line1}
        error={hasError(touched.address_line1, errors.address_line1)}
        helperText={hasError(touched.address_line1, errors.address_line1) ? errors.address_line1 : ""}
      />{" "}
      <TextField
        name="address_line2"
        margin="normal"
        fullWidth
        label="Address 2"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.address_line2}
        error={hasError(touched.address_line2, errors.address_line2)}
        helperText={hasError(touched.address_line2, errors.address_line2) ? errors.address_line2 : ""}
      />
      <TextField
        name="city"
        margin="normal"
        fullWidth
        label="City"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.city}
        error={hasError(touched.city, errors.city)}
        helperText={hasError(touched.city, errors.city) ? errors.city : ""}
      />
      <TextField
        name="state_region"
        margin="normal"
        fullWidth
        label="State/Region"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.state_region}
        error={hasError(touched.state_region, errors.state_region)}
        helperText={hasError(touched.state_region, errors.state_region) ? errors.state_region : ""}
      />
      <TextField
        name="postcode"
        margin="normal"
        fullWidth
        label="PostCode"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.postcode}
        error={hasError(touched.postcode, errors.postcode)}
        helperText={hasError(touched.postcode, errors.postcode) ? errors.postcode : ""}
      />
      <TextField
        name="country"
        margin="normal"
        fullWidth
        label="Country"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.country}
        error={hasError(touched.country, errors.country)}
        helperText={hasError(touched.country, errors.country) ? errors.country : ""}
      />
      <TextField
        name="phone_number"
        margin="normal"
        fullWidth
        label="Phone Number"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.phone_number}
        error={hasError(touched.phone_number, errors.phone_number)}
        helperText={hasError(touched.phone_number, errors.phone_number) ? errors.phone_number : ""}
      />
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onSubmit} sx={{ float: "right" }}>
        {id ? "Update" : "Add"} Address
      </Button>
    </>
  ) : (
    <></>
  );
};
