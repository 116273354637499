import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useBOMViewState } from "components/BOMView/BOMView";
import {
  Dialog,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Button,
  Stack,
  TextField,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import { getBOMLogs, postBomLog } from "api/bom";
import { StandardDate } from "utils/dates";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { UserRenderer } from "utils/AgGridRenderers";

export const AuditLog = ({ isOpen, setIsOpen }) => {
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const { bomId } = useParams();
  const { selectedRowIds } = useBOMViewState((state) => state);
  const { data: BOMLogData } = getBOMLogs(bomId, selectedRowIds.length > 0 ? selectedRowIds[0] : "");
  const queryClient = useQueryClient();


  const columns: ColDef[] = [
    { field: "bom_field", headerName: "Field", width: 150, wrapText: true, autoHeight: true, sortable: true },
    { field: "field_value", headerName: "Value", wrapText: true, autoHeight: true, flex: 1, sortable: true },
    { field: "log_record", headerName: "Comment", wrapText: true, autoHeight: true, flex: 2, sortable: true },
    { field: "created_by", headerName: "User", width: 200, wrapText: true, autoHeight: true, sortable: true, cellRenderer: UserRenderer },
    {
      field: "created_at",
      headerName: "Date",
      valueFormatter: dateFormatter,
      sortable: true,
      sort: "desc",
    },
  ];
  const [gridData, setGridData] = useState([]);
  const [comment, setComment] = useState("");

  function dateFormatter(params) {
    const dateAsString = params.data.created_at;
    const formattedString = StandardDate(dateAsString);
    return formattedString ? formattedString : "";
  }

  useEffect(() => {
    queryClient.invalidateQueries(["bom-logs", selectedRowIds[0]]);
    setComment("");
  }, [isOpen]);

  useEffect(() => {
    if (BOMLogData && BOMLogData.length > 0) {
      const dates = BOMLogData.map((x) => {
        return new Date(x.created_at);
      });
      const minDate = new Date(Math.min.apply(null, dates));
      setGridData(BOMLogData.filter((x) => new Date(x.created_at).getTime() !== minDate.getTime()));
    }
  }, [BOMLogData]);

  const handleClose = () => setIsOpen(false);

  const handleCancel = () => {
    handleClose();
  };
  const handleCommentSubmit = () => {
    if (!comment.length) {
      return;
    }

    postBomLog(bomId, selectedRowIds[0] ?? "", comment)
      .then(() => {
        queryClient.invalidateQueries(["bom-logs", selectedRowIds[0]]);
        setAlert({ type: "success", message: "Comment saved successfully" });
        setComment("");
      })
      .catch((error) => {
        setAlert({ type: "error", message: error.message });
      });
  };

  return (
    <>
      <Dialog open={isOpen} maxWidth={false}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 18 }}>Item History</Typography>
          <IconButton edge="start" onClick={handleCancel}>
            <Close />
          </IconButton>
        </Toolbar>
        <Box sx={{ width: "80em", height: "60em" }} className="ag-theme-alpine-dark ag-theme-custom">
          <AgGridReact rowData={gridData} columnDefs={columns} alwaysShowHorizontalScroll />
        </Box>
        <Divider />
        <Stack direction="row" m="1em">
          <TextField
            fullWidth
            placeholder="Add Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleCommentSubmit();
            }}
          />
          <Button size="medium" onClick={handleCommentSubmit} disabled={!comment?.length}>
            Submit
          </Button>
        </Stack>
        <Divider />
      </Dialog>
    </>
  );
};
