import * as React from "react";
import { useState } from "react";
import { alpha, Button, styled, Stack, Input, InputAdornment, IconButton } from "utils/MuiWrapper/components";
import { Add as AddIcon, Search as SearchIcon, Clear } from "utils/MuiWrapper/icons";

const InputStyled = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:before, &:after": {
    borderBottom: "none",
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  "&:hover:not(.Mui-disabled, .Mui-error):before": {
    borderBottom: "none",
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

type SearchBarType = {
  delegateSearch: (value: string) => void;
  handleShow?: () => void;
  noCreate?: boolean;
  searchTerm?: string;
};

const SearchBar = ({ delegateSearch, handleShow, noCreate = false, searchTerm }: SearchBarType) => {
  return (
    <>
      <Stack
        direction="row"
        sx={{ flexGrow: 1, flexDirection: "row-reverse", borderWidth: "10px", borderColor: "white" }}
        marginBottom={2}
      >
        {noCreate ? null : (
          <Button sx={{ marginLeft: 2, height: 29 }} variant="contained" endIcon={<AddIcon />} onClick={handleShow}>
            Create
          </Button>
        )}
        <SearchInput delegateSearch={delegateSearch} searchTerm={searchTerm} />
      </Stack>
    </>
  );
};

type SearchInputType = {
  delegateSearch: (value: string) => void;
  searchTerm?: string;
};

export const SearchInput = ({ delegateSearch, searchTerm }: SearchInputType) => {
  const [searchText, setSearchText] = useState(searchTerm || "");

  const handleKeyPress = (e) => {
    const key = e.key;
    if (key === "Enter") {
      delegateSearch(searchText);
      return;
    }
  };

  const handleClear = () => {
    setSearchText("");
    delegateSearch("");
  };

  const handleChange = (e) => setSearchText(e?.target?.value);

  return (
    <InputStyled
      value={searchText}
      autoComplete="off"
      placeholder="Search..."
      aria-label={"search"}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        searchText && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear} onMouseDown={handleClear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

export default SearchBar;
