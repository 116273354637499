import * as yup from "yup";

export const addressSchema = yup.object().shape({
  address_line1: yup.string().required("Address is required."),
  address_line2: yup.string(),
  city: yup.string().required("City is required."),
  state_region: yup.string().required("State/Region is required."),
  country: yup.string().required("Country is required."),
  postcode: yup.string().required("Post Code is required."),
  phone_number: yup.string(),
});
