import { useBOMItems, useBOMProject } from "api/bom";
import { useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "utils/MuiWrapper/components";
import { CARD_BG_COLOR } from "utils/constants";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const BudgetPane = ({ projectId }) => {
  const { data: projectBOMData } = useBOMProject(projectId);
  const [bomId, setBomId] = useState("");
  const { data: bomItems } = useBOMItems(bomId, 1, 0, bomId?.length > 0 ?? false);

  useEffect(() => {
    const approvedBom = projectBOMData?.saved_boms?.results?.find((x) => x.approved_at);
    if (approvedBom) {
      setBomId(approvedBom.id);
    } else {
      setBomId(
        projectBOMData?.saved_boms?.results
          ?.filter((x) => x.is_parent)
          .sort((n1, n2) => {
            return n1.last_updated > n2.last_updated;
          })[0]?.id
      );
    }
  }, [projectBOMData]);

  return (
    <>
      <Card
        variant="elevation"
        sx={{ height: "20vh", overflow: "hidden", backgroundColor: CARD_BG_COLOR, borderRadius: "16px" }}
      >
        <CardContent>
          <Stack spacing="0.25em">
            <Typography variant="h1" gutterBottom>
              Budget
            </Typography>
            {bomItems ? (
              <>
                <Typography align="center" variant="h5">
                  Total Cost
                </Typography>
                <Typography align="center" pb="2em">
                  {formatter.format(bomItems?.totals?.total_cost)}
                </Typography>
                <Typography align="center" variant="h5">
                  Total Monthly Cost
                </Typography>
                <Typography align="center">{formatter.format(bomItems?.totals?.total_monthly_cost)}</Typography>
              </>
            ) : (
              <Typography variant="h5" align="center">
                Save an overall BOM to view budget.
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
