import { useRef, useState } from "react";
import { Typography } from "utils/MuiWrapper/components";
import { create } from "zustand";
import { ReceivingBOMGrid } from "./ReceivingBOMGrid";
import TopCard from "./TopCard";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useMutation } from "@tanstack/react-query";
import { bulkPatchReceivingBomItems } from "api/bom";
import { AgGridReact } from "ag-grid-react";
import { AxiosError } from "axios";
import { getReceivingByPONumber } from "api/receiving";
import { ReceivingGridItem } from "./ReceivingGridItem";

export default function Receiving() {
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { searchString } = useReceivingPageState((state) => state);
  const gridRef = useRef<AgGridReact | null>(null);
  const [totalResults, setTotalResults] = useState(0);
  const [allResults, setAllResults] = useState();

  const dataSource = {
    rowCount: undefined,
    getRows: async (params) => {
      if (searchString !== "") {
        try {
          gridRef?.current?.api.showLoadingOverlay();

          const { results, total_results } = await getReceivingByPONumber(searchString, {
            limit: 100,
            offset: params.startRow,
            order_by: "manufacturer",
          });
          setAllResults(results);
          setTotalResults(total_results);
          params.successCallback(results, total_results);
        } catch (error) {
          if (error instanceof AxiosError) {
            const code = error.response?.data?.status;

            if (code !== 404) {
              setAlert({
                type: "error",
                message: `Could not get BOM Item data. ${error?.response?.data?.detail || ""}`,
              });
            }
          }

          setTotalResults(0);
          params.successCallback([], 0);
        } finally {
          gridRef?.current?.api?.hideOverlay();
        }
      } else {
        setTotalResults(0);
        params.successCallback([], 0);
      }
    },
  };

  const { mutate: bulkPatchReceiving } = useMutation({
    mutationFn: (data: object) => bulkPatchReceivingBomItems(data),
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
      gridRef?.current?.api?.setDatasource(dataSource);
    },
    onSuccess: () => {
      gridRef?.current?.api?.setDatasource(dataSource);
    },
  });

  const handleLocationSave = (location?: string) => {
    if (!location || location?.trim() === "") {
      setAlert({ type: "warning", message: "Please enter a Location" });
      return;
    }

    const itemsToUpdate = {};

    gridRef?.current?.api.forEachNode((rowNode) => {
      const bomItemId = rowNode.data.id;
      const received = +rowNode.data.received;

      if (Number.isInteger(received) && received !== 0) {
        itemsToUpdate[bomItemId] = received;
      }
    });

    if (Object.keys(itemsToUpdate).length > 0) {
      const update = {
        bom_item_ids: itemsToUpdate,
        location: location,
      };

      bulkPatchReceiving(update);
    }
  };

  return (
    <>
      <Typography variant="h1">Receiving</Typography>
      <TopCard
        handleLocationSave={handleLocationSave}
        bulkPatchReceiving={bulkPatchReceiving}
        allResults={allResults}
      />
      <ReceivingBOMGrid gridRef={gridRef} dataSource={dataSource} totalResults={totalResults} />
    </>
  );
}

type ReceivingPageStateType = {
  searchString: string;
  setSearchString: (x: string) => void;
  selectedRowIds: [];
  setSelectedRowIDs: (x: []) => void;
  isSelectAllChecked: boolean;
  setIsSelectAllChecked: (x: boolean) => void;
  selectedRowsData: ReceivingGridItem[];
  setSelectedRowsData: (x) => void;
};

export const useReceivingPageState = create<ReceivingPageStateType>((set) => ({
  searchString: "",
  setSearchString: (searchString) => set({ searchString: searchString }),
  selectedRowIds: [],
  setSelectedRowIDs: (selectedRowIds) => set({ selectedRowIds: selectedRowIds }),
  isSelectAllChecked: false,
  setIsSelectAllChecked: (isSelectAllChecked) => set({ isSelectAllChecked: isSelectAllChecked }),
  selectedRowsData: [],
  setSelectedRowsData: (selectedRowsData) => set({ selectedRowsData: selectedRowsData }),
}));
