import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { BOM_ITEM_STATUS_OPTIONS, GROUP_EDIT_BOM_FIELDS, TEXT_FONT_SIZE, BOM_COMMENT_REQUIRED } from "utils/constants";
import { CustomSelect } from "components/shared/controls";
import { useBOMViewState } from "components/BOMView/BOMView";
import { updateBOMItemsById } from "api/bom";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { GroupEditPreview } from "./GroupEditPreview";
import { NorthStarDesktopDatePicker } from "components/DesktopDatePicker";
import { UpdateBomItemsByIdType } from "types/BOM";

export const GroupEdit = ({ isOpen, setIsOpen }) => {
  const { bomId, projectId, numComponentsAdded } = useParams();
  const navigate = useNavigate();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [rowFields, setRowFields] = useState({});
  const [editedFields, setEditedFields] = useState({});
  const [rowUUIDs, setRowUUIDs] = useState([uuidv4()]);
  const { selectedRowIds, setSelectedRowIDs, setIsSelectAllChecked, selectedRowsData } = useBOMViewState(
    (state) => state
  );
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  useEffect(() => {
    setRowFields({});
    setEditedFields({});
    setRowUUIDs([uuidv4()]);
  }, [isOpen]);

  const { mutate: updateItems } = useMutation({
    mutationFn: (payload: UpdateBomItemsByIdType) => updateBOMItemsById(bomId || "", payload),
    onSuccess: (data) => {
      if (data.failed > 0) {
        const uniqueMessages = {};
        Object.keys(data.reason_for_failure).map((id) => {
          uniqueMessages[data.reason_for_failure[id].message] = true;
        });
        const failureMessages = Object.keys(uniqueMessages).join(" ");
        setAlert({
          type: "error",
          message: `${data.failed} of ${data.total} BOM items could not be updated. ${failureMessages}`,
        });
      } else if (data.success > 0) {
        const numComponents = !numComponentsAdded ? 0 : parseInt(numComponentsAdded);
        navigate(`/bom-view/${bomId}/project/${projectId}/added/${numComponents + 1}`);
        setAlert({
          type: "success",
          message: "BOM items successfully updated.",
        });
        setSelectedRowIDs([]);
        setIsSelectAllChecked(false);
      }
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const handleClose = () => setIsOpen(false);

  const remainingFields = () => {
    return GROUP_EDIT_BOM_FIELDS.filter((field) => editedFields[field.value] === undefined);
  };

  const deleteRow = (deleteIndex, uuid) => {
    const fieldName = rowFields[uuid];
    const filteredList = rowUUIDs.filter((row, rowIndex) => deleteIndex !== rowIndex);
    setRowUUIDs(filteredList);

    const editedFieldsCopy = { ...editedFields };
    delete editedFieldsCopy[fieldName];
    setEditedFields(editedFieldsCopy);
  };

  const handleAddNewField = () => {
    setRowUUIDs([...rowUUIDs, uuidv4()]);
  };

  const handleFieldChange = (e, uuid) => {
    const value = e.target.value;
    setRowFields({ ...rowFields, [uuid]: value });
    setEditedFields({ ...editedFields, [value]: "" });
  };

  const handleChangeFieldValue = (e, uuid) => {
    const fieldName = rowFields[uuid];
    const value = fieldName === "qty" && e.target.value !== "" ? parseInt(e.target.value) : e.target.value;
    setEditedFields({ ...editedFields, [fieldName]: value });
  };

  const getDisplayName = (uuid) => {
    const fieldName = rowFields[uuid];
    const fieldObj = GROUP_EDIT_BOM_FIELDS.find((field) => field.value === fieldName);
    return fieldObj?.displayName;
  };

  const getRowValueInput = useCallback(
    (uuid: string) => {
      const field = rowFields[uuid];
      if (field === "status") {
        return (
          <CustomSelect value={editedFields[rowFields[uuid]] || ""} onChange={(e) => handleChangeFieldValue(e, uuid)}>
            {BOM_ITEM_STATUS_OPTIONS.map((item, index) => (
              <MenuItem sx={{ fontSize: TEXT_FONT_SIZE }} key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
        );
      }
      if (field === "qty") {
        return (
          <TextField
            type="number"
            value={editedFields[rowFields[uuid]] ?? ""}
            onChange={(e) => handleChangeFieldValue(e, uuid)}
          ></TextField>
        );
      } else if (["date_required", "po_date"].includes(field)) {
        return (
          <NorthStarDesktopDatePicker
            value={editedFields[rowFields[uuid]] ?? ""}
            onChange={(e) => setEditedFields({ ...editedFields, [rowFields[uuid]]: e })}
            renderInput={(params) => (
              <TextField
                sx={{
                  display: "block",
                }}
                fullWidth
                {...params}
                error={false}
              />
            )}
          />
        );
      } else {
        return (
          <TextField
            value={editedFields[rowFields[uuid]] ?? ""}
            onChange={(e) => handleChangeFieldValue(e, uuid)}
          ></TextField>
        );
      }
    },
    [rowFields, editedFields]
  );

  const handleSave = () => {
    // TODO: Create Types for Bom Items
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (selectedRowsData.filter((x: any) => BOM_COMMENT_REQUIRED.includes(x.status)).length > 0) {
      if (Object.keys(editedFields).includes("comments")) {
        const payload = {
          bom_item_ids: selectedRowIds,
          values: editedFields,
        };

        updateItems(payload);
        handleClose();
      } else {
        setAlert({
          type: "error",
          message: `The BOM item could not be updated. A comment must be provided for these changes.`,
        });
      }
    } else {
      const payload = {
        bom_item_ids: selectedRowIds,
        values: editedFields,
      };

      updateItems(payload);
      handleClose();
    }
  };

  const handleCancel = () => {
    const editedValues = Object.values(editedFields);
    // If user has edited something, prompt them to confirm cancel
    if (editedValues.length) {
      setIsConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmCancel = () => {
    setIsConfirmOpen(false);
    handleClose();
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmOpen}
        title={"Are you sure you want to cancel without saving?"}
        setIsOpen={setIsConfirmOpen}
        confirmAction={handleConfirmCancel}
        displayText={{ confirm: "Yes", cancel: "No" }}
      />
      <Dialog fullScreen open={isOpen}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 18 }}>Group Edit BOM Items</Typography>
          <IconButton edge="start" onClick={handleCancel}>
            <Close />
          </IconButton>
        </Toolbar>

        <List sx={{ marginLeft: 3 }}>
          {rowUUIDs.map((uuid, index) => {
            return (
              <ListItem disableGutters key={index}>
                <IconButton onClick={() => deleteRow(index, uuid)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
                {!rowFields[uuid] ? (
                  <FormControl sx={{ width: "20vw" }}>
                    <CustomSelect value={"Select a field"} onChange={(e) => handleFieldChange(e, uuid)}>
                      <MenuItem
                        disabled
                        key={"Select a field"}
                        sx={{ fontSize: TEXT_FONT_SIZE }}
                        value={"Select a field"}
                      >
                        <Typography sx={{ fontStyle: "italic" }}>{"Select a field"}</Typography>
                      </MenuItem>
                      {remainingFields().map((field, index) => (
                        <MenuItem sx={{ fontSize: TEXT_FONT_SIZE }} key={index} value={field.value}>
                          {field.displayName}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                ) : (
                  <>
                    <FormControl sx={{ width: "20vw" }}>
                      <Typography sx={{ fontSize: TEXT_FONT_SIZE }}>{getDisplayName(uuid)}</Typography>
                    </FormControl>
                    <FormControl required={false} sx={{ marginLeft: 4, width: "30vw" }}>
                      {getRowValueInput(uuid)}
                    </FormControl>
                  </>
                )}
              </ListItem>
            );
          })}
        </List>
        <Box sx={{ marginLeft: 4, marginBottom: 4 }}>
          <Button
            disabled={!remainingFields().length || rowUUIDs.length >= GROUP_EDIT_BOM_FIELDS.length}
            onClick={handleAddNewField}
          >
            Add New
          </Button>
        </Box>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
        <Divider />
        <GroupEditPreview />
      </Dialog>
    </>
  );
};
