import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Autocomplete,
  FormControl,
  TextField,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "utils/MuiWrapper/components";
import { useState, useEffect } from "react";
import { getManufacturers } from "api/manufacturer";
import { useDebounce } from "utils/helpers";
import { patchComponent, postComponent } from "api/component";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { useFormik } from "formik";
import { componentSchema } from "./ComponentSchema";
import {
  DeleteNetworkPortRelationship,
  PatchNetworkPortRelationship,
  PostNetworkPortRelationship,
  useNetworkPorts,
} from "api/ports";
import { hasError } from "utils/FormikUtilities";

export type FormModalProps = {
  isOpen: boolean;
  handleClose: (component) => void;
  data?;
};
export type NetworkPortRelationship = {
  id: string;
  network_port_id: string;
  qty: number;
  action: string;
};

export type ManufacturerAutocompleteData = {
  id?: string;
  name: string;
};

const NetworkPortTableCell = ({ port }) => {
  return (
    <TableCell component="th" scope="row">
      {port?.media_type}: {port?.sfp_type} - {port?.speed}
    </TableCell>
  );
};

export const CreateComponent = ({ isOpen, handleClose, data }: FormModalProps) => {
  const [id, setId] = useState<string>();
  const [manufacturer, setManufacturer] = useState<ManufacturerAutocompleteData>();
  const [manufacturers, setManufacturers] = useState<ManufacturerAutocompleteData[]>([{ name: "" }]);
  const [manufacturerInput, setManufacturerInput] = useState<string>("");
  const debouncedSearchValue = useDebounce(manufacturerInput, 500);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { data: networkPorts } = useNetworkPorts(true);
  const [deletedNetworkPorts, setDeletedNetworkPorts] = useState<NetworkPortRelationship[]>([]);
  const [componentNetworkPorts, setComponentNetworkPorts] = useState<NetworkPortRelationship[]>([]);
  const [showManufacturerEdit, setShowManufacturerEdit] = useState<boolean>(false);

  const onSubmit = () => {
    if (id) {
      patchComponent(id, values).then(
        (response) => {
          setAlert({ type: "success", message: "The component was changed successfully" });
          handleNetworkPortRelationships(id)
            .then(() => {
              resetForm();
              handleClose(response?.data);
            })
            .catch((error) => {
              setAlert({ type: "error", message: error.message });
              resetForm();
            });
        },
        (error) => {
          setAlert({ type: "error", message: error.message });
        }
      );
    } else {
      postComponent(values)
        .then((response) => {
          setAlert({ type: "success", message: "The component was created successfully" });
          handleNetworkPortRelationships(response.data.id)
            .then(() => {
              resetForm();
              handleClose(response?.data);
            })
            .catch((error) => {
              setAlert({ type: "error", message: error.message });
            });
        })
        .catch((error) => {
          setAlert({ type: "error", message: error.message });
        });
    }
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      manufacturer_id: "",
      part_number: "",
      part_description: "",
      price: "",
      discount: "",
      weight: "",
      rack_units: "",
      ac_connections: "",
      power_usage: "",
    },
    validationSchema: componentSchema,
    onSubmit,
  });

  function handleNetworkPortRelationships(id) {
    const promises: Promise<unknown>[] = [];
    deletedNetworkPorts.forEach((port) => promises.push(DeleteNetworkPortRelationship(id ?? "", port.id)));

    const newPorts = componentNetworkPorts.filter((x) => x.action === "ADD");
    if (newPorts.length) {
      promises.push(
        PostNetworkPortRelationship(id ?? "", {
          network_ports: newPorts.map((x) => ({ network_port_id: x.network_port_id, qty: x.qty })),
        })
      );
    }

    componentNetworkPorts
      .filter((x) => x.action !== "ADD")
      .forEach((port) => {
        promises.push(PatchNetworkPortRelationship(id ?? "", port));
      });
    return Promise.all(promises);
  }

  useEffect(() => {
    if (debouncedSearchValue.length > 0)
      getManufacturers({ quick_search: debouncedSearchValue }).then((data) => {
        setManufacturers(data.results);
      });
  }, [debouncedSearchValue]);

  useEffect(() => {
    setShowManufacturerEdit(false);
    resetForm();
    if (data) {
      setId(data.id);
      setManufacturer(data.manufacturer);
      setFieldValue("manufacturer_id", data.manufacturer.id);
      setFieldValue("part_number", data.part_number);
      setFieldValue("part_description", data.part_description);
      setFieldValue("price", data.price);
      setFieldValue("discount", data.discount);
      setFieldValue("weight", data.weight);
      setFieldValue("rack_units", data.rack_units);
      setFieldValue("ac_connections", data.ac_connections);
      setFieldValue("power_usage", data.power_usage);
      setComponentNetworkPorts(
        data.network_ports.map((port) => ({
          id: port.id,
          network_port_id: port.network_port.id,
          qty: port.qty,
          action: "UPDATE",
        }))
      );
      setDeletedNetworkPorts([]);
    } else {
      setId(undefined);
      setManufacturer({ name: "" });
      setComponentNetworkPorts([]);
      setDeletedNetworkPorts([]);
    }
  }, [isOpen]);

  const handleTextChange = (newValue) => {
    setManufacturerInput(newValue);
  };

  const handleQtyChange = (index, value) => {
    const data = [...componentNetworkPorts];
    const item = componentNetworkPorts[index];
    item.qty = value as number;
    data.splice(index, 1, item);
    setComponentNetworkPorts(data);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" scroll="paper" aria-labelledby="scroll-dialog-title">
      <DialogContent>
        <DialogTitle id="scroll-dialog-title">{id ? "Edit" : "Add"} Component</DialogTitle>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={10}>
          <Grid item md={6}>
            {!id || showManufacturerEdit ? (
              <FormControl margin="normal" fullWidth>
                <Autocomplete
                  options={manufacturers}
                  getOptionLabel={(option: ManufacturerAutocompleteData) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Manufacturer"
                      error={hasError(touched.manufacturer_id, errors.manufacturer_id)}
                    />
                  )}
                  inputValue={manufacturerInput}
                  onInputChange={(event, newInputValue) => {
                    handleTextChange(newInputValue);
                  }}
                  fullWidth
                  filterOptions={(x) => x}
                  isOptionEqualToValue={() => true}
                  value={manufacturer}
                  onChange={(event, newValue) => {
                    setManufacturer(newValue ?? undefined);
                    setFieldValue("manufacturer_id", newValue?.id);
                  }}
                />
                <FormHelperText>
                  {hasError(touched.manufacturer_id, errors.manufacturer_id) ? errors.manufacturer_id : ""}
                </FormHelperText>
              </FormControl>
            ) : (
              <Stack direction="row" alignItems="end">
                <TextField fullWidth label="Manufacturer" value={manufacturer?.name} disabled />
                <Button variant="unboxed" size="medium" onClick={() => setShowManufacturerEdit(true)}>
                  Change
                </Button>
              </Stack>
            )}
            <TextField
              name="part_number"
              margin="normal"
              fullWidth
              label="Part Number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.part_number}
              error={hasError(touched.part_number, errors.part_number)}
              helperText={hasError(touched.part_number, errors.part_number) ? errors.part_number : ""}
            />
            <TextField
              name="part_description"
              margin="normal"
              fullWidth
              label="Part Description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.part_description}
              error={hasError(touched.part_description, errors.part_description)}
              helperText={hasError(touched.part_description, errors.part_description) ? errors.part_description : ""}
            />
            <TextField
              name="price"
              margin="normal"
              fullWidth
              label="Price"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              error={hasError(touched.price, errors.price)}
              helperText={hasError(touched.price, errors.price) ? errors.price : ""}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              name="discount"
              margin="normal"
              fullWidth
              label="Discount"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.discount}
              error={hasError(touched.discount, errors.discount)}
              helperText={hasError(touched.discount, errors.discount) ? errors.discount : ""}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
            <TextField
              name="ac_connections"
              margin="normal"
              fullWidth
              label="AC Connections"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ac_connections}
              error={hasError(touched.ac_connections, errors.ac_connections)}
              helperText={hasError(touched.ac_connections, errors.ac_connections) ? errors.ac_connections : ""}
            />
            <TextField
              name="power_usage"
              margin="normal"
              fullWidth
              label="Power Usage"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.power_usage}
              error={hasError(touched.power_usage, errors.power_usage)}
              helperText={hasError(touched.power_usage, errors.power_usage) ? errors.power_usage : ""}
              InputProps={{
                endAdornment: <InputAdornment position="end">watts</InputAdornment>,
              }}
            />
            <TextField
              name="rack_units"
              margin="normal"
              fullWidth
              label="Rack Units"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.rack_units}
              error={hasError(touched.rack_units, errors.rack_units)}
              helperText={hasError(touched.rack_units, errors.rack_units) ? errors.rack_units : ""}
              InputProps={{
                endAdornment: <InputAdornment position="end">RU</InputAdornment>,
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Weight"
              name="weight"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.weight}
              error={hasError(touched.weight, errors.weight)}
              helperText={hasError(touched.weight, errors.weight) ? errors.weight : ""}
              InputProps={{
                endAdornment: <InputAdornment position="end">grams</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Grid container sx={{ alignItems: "flex-end" }}>
              <Grid item md={6}>
                <h4>Network Ports</h4>
              </Grid>
              <Grid item md={6}>
                <h5>Add Network Port</h5>
                <Select
                  value="0"
                  onChange={(e) =>
                    setComponentNetworkPorts([
                      ...componentNetworkPorts,
                      {
                        network_port_id: e.target.value,
                        qty: 1,
                        action: "ADD",
                      } as NetworkPortRelationship,
                    ])
                  }
                >
                  <MenuItem key="-1" disabled value="0">
                    -- Select Network Port Type --
                  </MenuItem>
                  {networkPorts?.results?.map((port, index) => (
                    <MenuItem key={index} value={port.id}>
                      {port.media_type}: {port.sfp_type} - {port.speed}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {componentNetworkPorts?.length > 0 && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Qty</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {componentNetworkPorts.map((port, index: number) => (
                      <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <NetworkPortTableCell port={networkPorts.results.find((x) => x.id === port.network_port_id)} />
                        <TableCell align="right">
                          <TextField
                            type="number"
                            value={port.qty}
                            name={`network_ports[${index}].qty`}
                            onChange={(e) => handleQtyChange(index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            size="xsmall"
                            onClick={() => {
                              setDeletedNetworkPorts([
                                ...deletedNetworkPorts,
                                ...componentNetworkPorts.filter((x, arrIndex) => arrIndex === index),
                              ]);
                              setComponentNetworkPorts([
                                ...componentNetworkPorts.filter((x, arrIndex) => arrIndex !== index),
                              ]);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <form onSubmit={handleSubmit}>
          <Button type="submit" sx={{ float: "right" }}>
            Submit
          </Button>
        </form>
      </DialogActions>
    </Dialog>
  );
};
