import { UseMutateFunction } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Alert,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";

type ConfirmDialogTypes = {
  isOpen: boolean;
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmAction: UseMutateFunction<any, any, void, unknown>;
  displayText?: { confirm: string; cancel: string };
  children?;
  errorMessage?: string;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
};

export default function ConfirmationDialog({
  isOpen,
  title,
  setIsOpen,
  confirmAction,
  displayText,
  children,
  errorMessage,
  setErrorMessage,
}: ConfirmDialogTypes) {
  const handleCancel = () => {
    setIsOpen(false);
    if (setErrorMessage) setErrorMessage("");
  };
  const handleConfirm = () => {
    confirmAction();
  };

  return (
    <Box display="flex">
      <Dialog
        open={isOpen}
        onKeyUp={(e) => {
          if (e.key === "Enter") handleConfirm();
        }}
      >
        <DialogContent dividers>
          <DialogTitle sx={{ marginRight: 2 }}>
            {title}
            <IconButton onClick={handleCancel}>
              <Close />
            </IconButton>
          </DialogTitle>
        </DialogContent>
        {children && (
          <DialogContent>
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "10px" }}>
                {errorMessage}
              </Alert>
            )}
            {children}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCancel}>{displayText?.cancel || "Cancel"}</Button>
          <Button color="primary" onClick={handleConfirm}>
            {displayText?.confirm || "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
