import {
  Container,
  TextField,
  Stack,
  Typography,
  Button,
  ButtonGroup,
  IconButton,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Alert,
} from "utils/MuiWrapper/components";
import { Delete as DeleteIcon } from "utils/MuiWrapper/icons";

export const ComponentCatalog = () => {
  return (
    <Container sx={{ paddingBottom: 32 }}>
      <Stack direction="column" spacing={8}>
        <Typography variant="h2">TextField</Typography>
        <Box>
          <TextField label="Default TextField" />
        </Box>
        <Box>
          <TextField label="TextField w/placeholder" placeholder="This is placeholder text" />
        </Box>
        <Box>
          <TextField label="TextField w/helper text" helperText="This is some helper text" />
        </Box>
        <Box>
          <TextField label="TextField w/error" error />
        </Box>
        <Box>
          <TextField label="TextField w/error w/helper text" error helperText="This field is invalid" />
        </Box>
        <Box>
          <TextField label="TextField w/disabled" disabled value="value" />
        </Box>

        <Typography variant="h2">Button</Typography>

        <ButtonGroup>
          <Button>Default Button</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button size="small">Small Button</Button>
        </ButtonGroup>

        <Box>
          <Typography>IconButton</Typography>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Box>

        <Box>
          <Typography>Large IconButton</Typography>
          <IconButton>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Typography variant="h2">Select</Typography>

        <FormControl>
          <InputLabel>Default Select</InputLabel>
          <Select label="Default Select">
            <MenuItem value="vanilla">Vanilla</MenuItem>
            <MenuItem value="chocolate">Chocolate</MenuItem>
            <MenuItem value="secret breakfast">Secret Breakfast</MenuItem>
          </Select>
        </FormControl>

        <FormControl error>
          <InputLabel>Select w/error</InputLabel>

          <Select label="Select w/error">
            <MenuItem value="vanilla">Vanilla</MenuItem>
            <MenuItem value="chocolate">Chocolate</MenuItem>
            <MenuItem value="secret breakfast">Secret Breakfast</MenuItem>
          </Select>
        </FormControl>

        <p>Helper text is not supported on Select components.</p>

        <Typography variant="h2">Checkbox</Typography>

        <FormControlLabel control={<Checkbox />} label="Default Checkbox" />
        <FormControlLabel disabled control={<Checkbox />} label="Checkbox w/disabled" />

        <Typography variant="h2">Toast</Typography>

        <Alert severity="error">This is an error alert</Alert>
        <Alert severity="warning">This is a warning alert</Alert>
        <Alert severity="info">This is an info alert</Alert>
        <Alert severity="success">This is a success alert</Alert>
      </Stack>
    </Container>
  );
};
