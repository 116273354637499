import { Button } from "utils/MuiWrapper/components";
import { Dialog, Divider, IconButton, Toolbar, Typography, DialogActions } from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import ReactToPrint from "react-to-print";
import { LabelGrid } from "./LabelGrid";
import { ReactInstance } from "react";

export const PrintModal = ({ isOpen, setIsOpen }) => {
  const handleClose = () => setIsOpen(false);
  let printRef: ReactInstance;

  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" scroll="paper">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 18 }}>Create Label</Typography>
          <IconButton edge="start" onClick={handleCancel}>
            <Close />
          </IconButton>
        </Toolbar>
        <LabelGrid ref={(el: ReactInstance) => (printRef = el)} />
        <Divider />
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <ReactToPrint
            trigger={() => {
              return <Button>Print</Button>;
            }}
            content={() => printRef}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
