import { useQuery } from "@tanstack/react-query";
import { getRequest } from "http/axiosClient";
import { formatError, patchRequest } from "http/client";
import { User } from "types/User";
import { getAllPages } from "./helper-requests";

export async function getUser(id: string) {
  try {
    if (!id) throw Error("invalid id");
    const res = await getRequest("/v1/api/users/" + id);
    return res.data;
  } catch (error) {
    return formatError("Could not fetch user details", error);
  }
}

export const useGetUser = (id?: string, hasToken?: boolean) => {
  return useQuery<User>(["get_user", id], () => getUser(id ?? ""), {
    enabled: hasToken ?? true,
    retry: false,
  });
};

export async function getUsers() {
  try {
    const res = await getAllPages("/v1/api/users");
    return res;
  } catch (error) {
    return formatError(`Could not fetch users`, error);
  }
}

export const useGetUsers = (enabled = true) => {
  return useQuery({
    queryKey: ["get_users"],
    queryFn: async () => await getUsers(),
    retry: false,
    enabled: enabled,
  });
};

export async function changePassword(userID, password, password2) {
  try {
    const res = await patchRequest(`/v1/api/users/${userID}`, { password: { first: password, second: password2 } });
    return res.data;
  } catch (error) {
    return formatError("Could not change ", error);
  }
}
