import { Box, Link, Stack, Typography } from "utils/MuiWrapper/components";
import { Link as RouterLink } from "react-router-dom";

import { useDetailPaneStateStore } from "./DetailPaneState";
import { useProjectForDetail } from "api/project";
import { format, formatDistance } from "date-fns";
import { useBOMProject } from "api/bom";
import { useFetchTemplate } from "api/template";
import ProjectStatusLabel from "components/ProjectsPage/ProjectStatusLabel";

function formatRelativeDate(ds: string): string {
  try {
    const date = Date.parse(ds);
    if (date === undefined || isNaN(date)) return "";
    return formatDistance(date, new Date(), { addSuffix: true });
  } catch {
    return "";
  }
}
function formatDate(ds: string): string {
  try {
    const date = Date.parse(ds);
    if (date === undefined || isNaN(date)) return "";
    return format(date, "dd MMM yyyy");
  } catch {
    return "";
  }
}

const DetailPane = () => {
  const itemType = useDetailPaneStateStore((state) => state.type);
  const itemId = useDetailPaneStateStore((state) => state.id);

  const { data: project } = useProjectForDetail(itemType == "Project" && !!itemId, itemId || "");
  const { data: projectBOMData } = useBOMProject(itemId || "", itemType == "Project" && !!itemId);

  const { data: template } = useFetchTemplate(itemId || "", itemType == "Template" && !!itemId);

  return (
    <>
      {itemType == "Project" && !!itemId && (
        <Box mx={2} mt={2} sx={{ width: "100%", padding: "10px" }}>
          <Stack direction="column">
            <Stack direction="row">
              <Box sx={{ width: "80%" }}>
                <h2 style={{ marginLeft: "2%" }}>{project?.name}</h2>
              </Box>
              <Box sx={{ width: "20%" }}>
                <Link to={`/projects/${itemId}`} component={RouterLink}>
                  View Full Dashboard
                </Link>
              </Box>
            </Stack>
            <Stack
              direction="row"
              borderRadius={3}
              sx={{ bgcolor: "#13172c;" }}
              padding={2}
              my={5}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Stack direction="column" spacing={2}>
                <h3>Start Date</h3>
                <p style={{ textAlign: "center" }}>{formatDate(project?.start_date)}</p>
              </Stack>
              <Stack direction="column" spacing={2}>
                <h3>End Date</h3>
                <p style={{ textAlign: "center" }}>{formatDate(project?.end_date)}</p>
              </Stack>
              <Stack direction="column" spacing={2}>
                <h3>Status</h3>
                <ProjectStatusLabel projectStatus={project?.status} />
              </Stack>
            </Stack>
            <h3 style={{ marginBottom: "0.5em" }}>Recent Templates</h3>
            <Box mb={3} sx={{ width: "100%" }}>
              {project?.project_templates
                ?.sort((a, b) => {
                  return b.last_modified > a.last_modified ? 1 : -1;
                })
                .slice(0, 5)
                .map((t, index) => {
                  return (
                    <Stack
                      key={index}
                      p={3}
                      mt={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ backgroundColor: "#13172c;", borderRadius: "16px" }}
                    >
                      <Typography>{t.name}</Typography>

                      <Typography>Last updated: {formatRelativeDate(t.last_modified)}</Typography>
                    </Stack>
                  );
                })}
            </Box>
            <h3 style={{ marginBottom: "0.5em" }}>Recent BOM</h3>
            <Box sx={{ width: "100%" }}>
              {projectBOMData?.saved_boms?.results
                ?.sort((a, b) => {
                  return b.last_updated > a.last_updated ? 1 : -1;
                })
                .slice(0, 1)
                .map((b, index) => {
                  return (
                    <Stack key={index} mb={3}>
                      <Stack
                        p={3}
                        direction="row"
                        justifyContent="space-between"
                        sx={{ backgroundColor: "#13172c;", borderRadius: "16px" }}
                      >
                        <Typography>
                          {b.name} {b.version}
                        </Typography>

                        <Typography>Last updated: {formatRelativeDate(b?.last_updated)}</Typography>
                      </Stack>
                      {b.children?.map((c, cIndex) => {
                        return (
                          <Stack
                            key={cIndex}
                            p={3}
                            mt={1}
                            ml={6}
                            direction="row"
                            justifyContent="space-between"
                            sx={{ backgroundColor: "#13172c;", borderRadius: "16px" }}
                          >
                            <Typography>{c.name}</Typography>

                            <Typography>Last updated: {formatRelativeDate(c?.last_updated)}</Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  );
                })}
            </Box>
          </Stack>
        </Box>
      )}
      {itemType == "Template" && !!itemId && (
        <Box mx={2} mt={2} sx={{ width: "100%", padding: "10px" }}>
          <Stack direction="column">
            <Stack direction="row">
              <Box sx={{ width: "80%" }}>
                <h2 style={{ marginLeft: "2%" }}>{template?.name}</h2>
              </Box>
              <Box sx={{ width: "20%" }}>
                <Link to={`/template-builder/${itemId}`} component={RouterLink}>
                  {template?.status !== "in_progress" ? "View Template" : "Edit Template"}
                </Link>
              </Box>
            </Stack>
            <Stack
              direction="row"
              borderRadius={3}
              sx={{ bgcolor: "#13172c;" }}
              padding={2}
              my={2}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Stack direction="column" spacing={2}>
                <h3>Created</h3>
                <p style={{ textAlign: "center" }}>{formatRelativeDate(template?.created_at)}</p>
              </Stack>
              <Stack direction="column" spacing={2}>
                <h3>Last Revised</h3>
                <p style={{ textAlign: "center" }}>{formatRelativeDate(template?.updated_at)}</p>
              </Stack>
              <Stack direction="column" spacing={2}>
                <h3>Status</h3>
                <ProjectStatusLabel projectStatus={template?.status} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default DetailPane;
