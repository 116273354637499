import { formatError } from "http/client";
import { getRequest } from "../http/axiosClient";
import { ReceivingParamsType } from "types/Queries";

export async function getReceivingBOMItems(params: ReceivingParamsType) {
  try {
    const res = await getRequest(`v1/api/bom/items/receiving`, params);
    return res.data;
  } catch (error) {
    return formatError("Unable to get items", error);
  }
}

export async function getReceivingByPONumber(searchString: string, params: ReceivingParamsType) {
  try {
    const res = await getRequest(`/v1/api/bom/receiving/ponumber/${searchString}`, params);
    return res.data;
  } catch (error) {
    return formatError("Unable to get items", error);
  }
}
