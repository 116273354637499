import React from "react";
import { Button, Menu, MenuItem } from "utils/MuiWrapper/components";
import { MoreVertical } from "utils/MuiWrapper/icons";
import { CloneTemplateModal } from "components/Templates/CloneTemplateModal";
import { Template } from "types/Template";

interface ITemplateMenuOptions {
  template: Template;
}

export function TemplateMenuOptions(props: ITemplateMenuOptions) {
  const { template } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isCloneModalOpen, setCloneModal] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CloneTemplateModal
        isCloneModalOpen={isCloneModalOpen}
        setCloneModal={setCloneModal}
        templateId={template.id}
        templateName={template.name}
      />

      <Button color="secondary" startIcon={<MoreVertical />} onClick={(event) => handleClick(event)} />

      <Menu id="basic-menu" anchorEl={anchorEl} open={anchorEl !== null} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setCloneModal(true);
          }}
        >
          Clone
        </MenuItem>
      </Menu>
    </>
  );
}
