import { Typography } from "utils/MuiWrapper/components";
import { TechReviewGrid } from "./TechReviewGrid";

export const TechReview = () => {
  return (
    <>
      <Typography variant="h1">Ready for Tech Review</Typography>
      <TechReviewGrid />
    </>
  );
};
