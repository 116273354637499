import React, { useEffect, useState, useCallback } from "react";
import { fetchFieldCollections, createFieldCollection, useVariablesForTemplate } from "api/template";
import Iteration from "./iteration";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";

const IterationTwo = ({ templateId, templateIsPublished }) => {
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [iterationId, setIterationId] = useState("");
  const templateVariables = useVariablesForTemplate(templateId, { type: "standard" });

  const createIteration = useCallback(async () => {
    try {
      const data = {
        name: "Iteration 2",
        ordinal: 2,
        clonable: false,
        multipliable: false,
        type: "iteration",
      };
      const fieldCollection = await createFieldCollection(templateId, data);
      setIterationId(fieldCollection.id);
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ type: "error", message: error.message });
      } else {
        setAlert({ type: "error", message: "There was an error while creating the iteration." });
      }
    }
  }, [templateId]);

  const getIteration = useCallback(
    async (templateId) => {
      try {
        const data = await fetchFieldCollections(templateId);
        const iteration = data.results.find((item) => item.type === "iteration" && item.ordinal === 2);
        if (iteration) {
          setIterationId(iteration.id);
        } else {
          createIteration();
        }
      } catch (error) {
        if (error instanceof Error) {
          setAlert({ type: "error", message: error.message });
        } else {
          setAlert({ type: "error", message: "There was an error while getting the iteration." });
        }
      }
    },
    [createIteration]
  );

  useEffect(() => {
    if (templateId) {
      getIteration(templateId);
    }
  }, [getIteration, templateId]);

  return (
    <Iteration
      templateId={templateId}
      templateIsPublished={templateIsPublished}
      iterationId={iterationId}
      sequenceId={2}
      selectOptions={templateVariables}
    />
  );
};

export default IterationTwo;
