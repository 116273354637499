import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getRequest, deleteRequest, createRequest, updateRequest } from "http/axiosClient";
import { formatError, postRequest } from "http/client";
import {
  AdHocBOMItemPayloadType,
  AddBOMItemPayloadType,
  BomItemType,
  ProjectBomType,
  UpdateBomItemsByIdType,
} from "types/BOM";
import { QueryParamsType } from "types/Queries";

/**
 * Returns BOM items
 * @param {uuid} bomId
 * @param {number} offset
 * @returns see https://api.ft-test1.tfclabs.com/v1/api/docs/#tag/BomItems/operation/get_get_bom_items
 */
export const useBOMItems = (bomId, limit, offset, enabled = true) => {
  return useQuery({
    queryKey: ["bom-items", bomId, limit, offset],
    queryFn: () => fetchBOMItemsById(bomId, { limit, offset }),
    enabled: enabled,
  });
};

export const useBOMEntities = (bomId: string, limit: number, offset: number) => {
  return useQuery({
    queryKey: ["bom-entities", bomId, limit, offset],
    queryFn: () => fetchBOMEntitiesById(bomId, limit, offset),
  });
};

// Fetch BOM entities by ID
export async function fetchBOMEntitiesById(bomId: string, limit: number, offset: number) {
  try {
    const res = await getRequest(`/v1/api/bom/${bomId}`, {
      limit,
      offset,
    });
    return res.data;
  } catch (error) {
    return formatError(`BOM items could not be fetched`, error);
  }
}

// Fetch BOM items by ID
export async function fetchBOMItemsById(bomId: string, params: QueryParamsType) {
  try {
    const res = await getRequest(`/v1/api/bom/${bomId}/item`, params);
    return res.data;
  } catch (error) {
    return formatError(`BOM items could not be fetched`, error);
  }
}

// Save BOM
export async function createBOMById(
  project_id: string | undefined,
  project_template_id: string | undefined,
  name: string
) {
  try {
    const res = await createRequest(`/v1/api/bom`, {
      project_template_id,
      project_id,
      name,
    });
    return res.data;
  } catch (error) {
    return formatError(`The BOM could not be saved`, error);
  }
}

// Delete BOM
export async function deleteBOMById(bomId) {
  try {
    const res = await deleteRequest(`/v1/api/bom/${bomId}`);
    return res.data;
  } catch (error) {
    return formatError(`The BOM could not be deleted`, error);
  }
}

// Update BOM item
export async function updateBOMItemById(data: BomItemType) {
  try {
    const res = await updateRequest(`/v1/api/bom/${data.bomId}/item/${data.bomItemId}`, data.body);
    return res.data;
  } catch (error) {
    console.error(error);
    if (error instanceof AxiosError) {
      const errorMessage = error?.response?.data?.errors?.join(" ");
      return Promise.reject(
        new Error(`The BOM item could not be updated. ${errorMessage || error?.response?.data?.error || ""}`)
      );
    } else {
      return Promise.reject(new Error(`The BOM item could not be updated.`));
    }
  }
}

// Fetch bom by project id
export async function fetchBomByProjectId(projectId) {
  try {
    const res = await getRequest(`/v1/api/bom/project/${projectId}?output=ui`);
    return res.data;
  } catch (error) {
    return formatError(`Could not get BOM data`, error);
  }
}

// Update BOM item
export async function createProjectBom(data: ProjectBomType) {
  try {
    const res = await updateRequest(`/v1/api/bom/project/${data.projectId}`, data.body);
    return res.data;
  } catch (error) {
    console.log(error);
    if (error instanceof AxiosError) {
      const errorMessage = error?.response?.data?.errors?.join(" ");
      return Promise.reject(new Error(`The Project BOM could not be created. ${errorMessage || ""}`));
    } else return Promise.reject(new Error(`The Project BOM could not be created.`));
  }
}

export const useBOMProject = (projectId, enabled = true) => {
  return useQuery({
    queryKey: ["bom-project", projectId],
    queryFn: async () => {
      try {
        const res = await getRequest(`/v1/api/bom/project/${projectId}?output=ui`);
        return res.data;
      } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not fetch BOMs for project."));
      }
    },
    retry: false,
    enabled: enabled,
  });
};

export async function addBOMItemById(bomId: string, payload: AddBOMItemPayloadType) {
  try {
    const res = await createRequest(`/v1/api/bom/${bomId}/item`, {
      component_id: payload.componentId,
      qty: payload.quantity,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(new Error(`The component could not be added to the BOM.`));
  }
}
export async function addAdHocBOMItemById(bomId: string, payload: AdHocBOMItemPayloadType) {
  try {
    const res = await createRequest(`/v1/api/bom/${bomId}/item`, payload);
    return res.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(new Error(`The line item could not be added to the BOM.`));
  }
}

export async function updateBOMItemsById(bomId: string, payload: UpdateBomItemsByIdType) {
  try {
    const res = await updateRequest(`/v1/api/bom/${bomId}/item`, payload);
    return res.data;
  } catch (error) {
    return formatError("Unable to update BOM items", error);
  }
}

// Approve BOM for project
export async function approveBOMbyId(bomId: string, projectId) {
  try {
    const res = await updateRequest(`/v1/api/bom/${bomId}/project/${projectId}/approve`, {});
    return res.data;
  } catch (error) {
    return formatError("Unable to approve BOM for project", error);
  }
}

export const getBOMLogs = (bomId, bomItemId, enabled = true) => {
  return useQuery({
    queryKey: ["bom-logs", bomItemId],
    queryFn: async () => {
      try {
        if (bomId == "" || bomItemId == "") {
          return [];
        }
        const res = await getRequest(`/v1/api/bom/${bomId}/item/${bomItemId}/log`);

        return res.data.results;
      } catch (error) {
        console.error(error);
        return Promise.reject(new Error("Could not fetch logs for BOM item."));
      }
    },
    retry: false,
    enabled: enabled,
  });
};
export async function postBomLog(bomId, bomItemId, comment) {
  try {
    await postRequest(`/v1/api/bom/${bomId}/item/${bomItemId}/log`, { comment: comment });
  } catch (error) {
    return formatError(`Could not post comment`, error);
  }
}

export async function fetchPONumbers(bomPONumber: string) {
  try {
    const res = await getRequest(`/v1/api/bom/items/po/${bomPONumber}`);
    return res.data;
  } catch (error) {
    return formatError(`Could not get BOM PO numbers`, error);
  }
}

export async function bulkPatchReceivingBomItems(data) {
  try {
    const res = await updateRequest(`/v1/api/bom/item`, data);
    return res.data;
  } catch (error) {
    return formatError(`Could not update BOM Items`, error);
  }
}
