import * as React from "react";
import { Drawer as MuiDrawer, styled, Box, Tooltip, Link } from "utils/MuiWrapper/components";
import { Link as RouterLink } from "react-router-dom";
import { setMenuPinned, setMiniSidenav, useFortuneTellerUIContext } from "context";
import brand from "../../assets/images/northstar-logo.svg";
import { IconButton } from "utils/MuiWrapper/components";
import { PushPin, PushPinOutlined } from "@mui/icons-material";
import UserAvatar from "./UserAvatar";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme, width) => ({
  width: width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 260,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(!open && {
    ...{
      width: 260,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    },
    "& .MuiDrawer-paper": openedMixin(theme, 260),
  }),
  ...(open && {
    ...{
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    },
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function BaseSidenav({ children, width, ...rest }) {
  const [context, dispatch] = useFortuneTellerUIContext();
  const { miniSidenav, menuPinned } = context;

  const handleMenuIcon = () => {
    setMenuPinned(dispatch, !menuPinned);
    menuPinned && setMiniSidenav(dispatch, !miniSidenav);
  };

  return (
    <Drawer
      variant="permanent"
      {...rest}
      open={miniSidenav}
      PaperProps={{ sx: { backgroundColor: "#15192e;" } }}
      sx={{ zIndex: "99", height: "100%" }}
    >
      <DrawerHeader sx={{ width, marginBottom: "-3em" }}>
        {
          <IconButton sx={{ ml: "auto", position: "absolute", mb: "0.5em" }} onClick={handleMenuIcon}>
            {menuPinned ? <PushPin color="primary" /> : <PushPinOutlined color="primary" />}
          </IconButton>
        }
      </DrawerHeader>
      {!miniSidenav && (
        <>
          <Box sx={{ width: width - 20, paddingTop: 0, paddingLeft: 2 }}>
            <Tooltip title="Northstar">
              <Link to="/" component={RouterLink}>
                <img src={brand} alt="logo"></img>
              </Link>
            </Tooltip>
            <UserAvatar />
          </Box>
          <Box mt={2} height={2} bgcolor="secondary.main"></Box>
        </>
      )}
      {children}
    </Drawer>
  );
}
