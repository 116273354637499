import { BOM_ITEM_STATUS_OPTIONS } from "utils/constants";
import { DateEditor } from "./DateEditor";
import { BomStatus } from "utils/enums";
import { DateRenderer, NumberRenderer, PercentageRenderer, PriceRenderer } from "utils/AgGridRenderers";

const editableAccess = [BomStatus.ReadyForPurchase, BomStatus.PartialRX, BomStatus.Delivered, BomStatus.Purchased];

export const StatusColDef = (isBOMEditable) => {
  return [
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      editable: isBOMEditable,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: BOM_ITEM_STATUS_OPTIONS,
      },
    },
  ];
};

export const InitialColumnDefs = (isBOMEditable) => {
  return [
    {
      headerName: "Quantity",
      field: "qty",
      cellRenderer: NumberRenderer,
      editable: (params) => isBOMEditable && params?.data?.status !== BomStatus.Deleted,
    },
    { field: "supplier", headerName: "Supplier", editable: isBOMEditable },
    { field: "supplier_part_number", headerName: "Supplier Part No", editable: isBOMEditable },
    { field: "manufacturer", headerName: "Manufacturer" },
    { field: "manufacturer_part_number", headerName: "Manufacturer Part No" },
    { field: "description", headerName: "Description" },
    { field: "price", headerName: "Price", cellRenderer: PriceRenderer, editable: false },
    { field: "discount", headerName: "Discount", cellRenderer: PercentageRenderer, editable: false },
    { field: "net_cost", headerName: "Net Cost", cellRenderer: PriceRenderer, editable: false },
    {
      field: "total_cost",
      headerName: "Total Cost",
      cellRenderer: PriceRenderer,
      editable: false,
    },
    {
      field: "total_monthly",
      headerName: "Total Monthly",
      cellRenderer: PriceRenderer,
      editable: isBOMEditable,
    },
  ];
};

export const EditEndColDefs = (isBOMEditable: boolean) => {
  return [
    {
      headerName: "Quantity Received",
      field: "qty_rx",
    },
    {
      headerName: "Quote Ref",
      field: "quote_ref",
      editable: (params) => isBOMEditable && params?.data?.status !== BomStatus.Deleted,
    },
    {
      headerName: "Date Required",
      field: "date_required",
      editable: (params) => isBOMEditable && params?.data?.status !== BomStatus.Deleted,
      cellEditor: DateEditor,
      cellRenderer: DateRenderer,
    },
    {
      headerName: "RFP Number",
      field: "rfp_number",
      editable: (params) => isBOMEditable && editableAccess.includes(params?.data?.status),
    },
    {
      headerName: "PO Number",
      field: "po_number",
      editable: (params) => isBOMEditable && editableAccess.includes(params?.data?.status),
    },
    {
      headerName: "PO Date",
      field: "po_date",
      editable: (params) => isBOMEditable && editableAccess.includes(params?.data?.status),
      cellEditor: DateEditor,
      cellRenderer: DateRenderer,
    },
  ];
};
