import { useQuery } from "@tanstack/react-query";
import { getRequest, patchRequest, postRequest } from "../http/componentClient";
import { QueryParamsType } from "../types/Queries";
import { deleteRequest, formatError } from "http/client";

export async function getNetworkPorts(params?: QueryParamsType) {
  try {
    const res = await getRequest("/v1/api/networkports", params);
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(new Error(`Could not fetch network ports.`));
  }
}

export const useNetworkPorts = (enabled = true) => {
  return useQuery({
    queryKey: ["network-ports"],
    queryFn: async () => {
      return await getNetworkPorts({ limit: 10000 });
    },
    retry: false,
    enabled: enabled,
  });
};

export async function PostNetworkPortRelationship(id: string, data) {
  try {
    const res = await postRequest(`/v1/api/components/${id}/networkports`, data);
    return res;
  } catch (error) {
    console.log(error);
    return formatError(`Could not save network port`, error);
  }
}
export async function PatchNetworkPortRelationship(id: string, data) {
  try {
    const res = await patchRequest(`/v1/api/components/${id}/networkports/${data.id}`, { qty: +data.qty });
    return res;
  } catch (error) {
    console.log(error);
    return formatError(`Could not save network port`, error);
  }
}
export async function DeleteNetworkPortRelationship(id: string, networkPortId: string) {
  try {
    const res = await deleteRequest(`/v1/api/components/${id}/networkports/${networkPortId}`);
    return res;
  } catch (error) {
    return formatError("Could not delete network port", error);
  }
}
