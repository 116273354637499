import React from "react";
import { Button, Menu, MenuItem, Tooltip } from "utils/MuiWrapper/components";
import { MoreVertical } from "utils/MuiWrapper/icons";
import { CloneToAnotherProjectModal } from "./CloneToAnotherProjectModal";
import { ProjectTemplate } from "types/ProjectTemplate";
import { Project } from "types/Project";
import { deleteProjectTemplate } from "api/project";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { ARCHIVED_PROJECT } from "utils/constants";
interface TemplateSectionMenuOptionsType {
  projectTemplate: ProjectTemplate;
  parentProject: Project;
  refetchProjectQuery: () => void;
}

export function TemplateSectionMenuOptions(props: TemplateSectionMenuOptionsType) {
  const { projectTemplate, parentProject, refetchProjectQuery } = props;

  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isCloneModalOpen, setCloneModal] = React.useState(false);
  const [presetProject, setPresetProject] = React.useState<Project | null>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: deleteProjectTemplateMutation } = useMutation({
    mutationFn: async () => {
      return await deleteProjectTemplate(parentProject.id, projectTemplate.id);
    },
    onSuccess: () => {
      setIsDeleteModalOpen(false);
      queryClient.invalidateQueries(["project", parentProject.id]);
      queryClient.invalidateQueries(["bom-project", parentProject.id]);
    },
  });

  return (
    <>
      <CloneToAnotherProjectModal
        isCloneModalOpen={isCloneModalOpen}
        setCloneModal={setCloneModal}
        projectTemplate={projectTemplate}
        parentProjectId={parentProject.id}
        refetchProjectQuery={refetchProjectQuery}
        presetProject={presetProject}
        setPresetProject={setPresetProject}
      />
      <ConfirmationDialog
        isOpen={isDeleteModalOpen}
        title={"Are you sure you want to remove this template from the project?"}
        setIsOpen={setIsDeleteModalOpen}
        confirmAction={deleteProjectTemplateMutation}
        displayText={{ confirm: "Yes", cancel: "No" }}
      />
      <Tooltip title="Options">
        <Button
          sx={{ height: "100%", width: "40px", borderRadius: "0 100% 100% 0" }}
          color="secondary"
          startIcon={<MoreVertical />}
          onClick={(event) => handleClick(event)}
        />
      </Tooltip>
      <Menu id="basic-menu" anchorEl={anchorEl} open={anchorEl !== null} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setCloneModal(true);
          }}
        >
          Clone to Another Project
        </MenuItem>
        {parentProject?.status !== ARCHIVED_PROJECT && (
          <>
            <MenuItem
              onClick={() => {
                setPresetProject(parentProject);
                setCloneModal(true);
              }}
            >
              Clone to this Project
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPresetProject(parentProject);
                setIsDeleteModalOpen(true);
              }}
            >
              Delete Template
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}
