import { Box, Grid, Typography, IconButton } from "utils/MuiWrapper/components";
import { Delete as DeleteIcon, Edit as EditIcon } from "utils/MuiWrapper/icons";
import { deleteScalerMapping, useProjectLocations } from "api/project-scaler";
import { useParams } from "react-router-dom";
import { BOM } from "types/BOM";
import { useEffect, useState } from "react";
import { useScalerStore } from "./ScalerState";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useProject } from "api/project";
import { APPROVED_PROJECT, ARCHIVED_PROJECT } from "utils/constants";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";
import { hasPermission, useProjectTeamManagement } from "utils/helpers";
import { Action, Roles } from "utils/enums";

export const BOMScaler = ({ template, rowData }) => {
  const { projectId } = useParams();
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const { data: project } = useProject(projectId);
  const { isProjectMember } = useProjectTeamManagement(project, userId);
  const { setIsAddOpen, standardBOMs, setTemplate, setEditScaler } = useScalerStore((state) => state);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const queryClient = useQueryClient();

  const { data: locations } = useProjectLocations(projectId);
  const [bomInfo, setBomInfo] = useState<BOM>();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    if (standardBOMs) {
      const bom = standardBOMs.find((bom: BOM) => bom.id === rowData.template_bom_id);
      setBomInfo(bom);
    }
  }, [standardBOMs, rowData]);

  const { mutate: deleteScaler } = useMutation({
    mutationFn: (id: string) => deleteScalerMapping(projectId || "", id),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-dc-scalers", projectId]);
      setIsConfirmOpen(false);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const handleEdit = () => {
    setEditScaler(rowData);
    setTemplate(template);
    setIsAddOpen(true);
  };

  const handleDelete = () => {
    deleteScaler(rowData.id);
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 3 }}>
      <ConfirmationDialog
        isOpen={isConfirmOpen}
        title={"Are you sure you want to want to delete the scaler mapping?"}
        setIsOpen={setIsConfirmOpen}
        confirmAction={handleDelete}
        displayText={{ confirm: "Yes", cancel: "No" }}
      />
      <Grid item xs={4}>
        <Typography>
          {bomInfo?.name} - {bomInfo?.version || ""}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>{rowData.scaler_qty}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{locations?.results?.find((result) => result.id === rowData.project_location_id)?.name}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Box>
          {(isProjectMember || user?._embedded.role.name === Roles.Architect) &&
           hasPermission(user?.permissions?.scaler, Action.Edit) && 
           project?.status !== ARCHIVED_PROJECT && (
            <IconButton disabled={project?.status === APPROVED_PROJECT} onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          )}
          {(isProjectMember || user?._embedded.role.name === Roles.Architect) && 
          hasPermission(user?.permissions?.scaler, Action.Delete) && 
          project?.status !== ARCHIVED_PROJECT && (
            <IconButton disabled={project?.status === APPROVED_PROJECT} onClick={() => setIsConfirmOpen(true)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
