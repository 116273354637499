import { create } from "zustand";

export const useSearchComponentStateStore = create<SearchComponentState>((set) => ({
  preserve: false,
  searchTerm: "",
  updatePreserve: (preserve) => set(() => ({ preserve: preserve })),
  updateSearchTerm: (searchTerm) => set(() => ({ searchTerm: searchTerm })),
}));

interface SearchComponentState {
  preserve: boolean;
  searchTerm: string;
  updatePreserve: (preserve: boolean) => void;
  updateSearchTerm: (searchTerm: string) => void;
}
