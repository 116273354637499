import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  Select,
  DialogActions,
  Typography,
  MenuItem,
  List,
  ListItem,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";

import { updateProjectById, useProject } from "api/project";
import { APPROVED_PROJECT, IN_PROGRESS_BOM, SAVED_BOM } from "utils/constants";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { approveBOMbyId, useBOMProject } from "api/bom";
import { StandardDate } from "utils/dates";

const MARGIN_RIGHT = 8;

export function ApprovedProjectModal({ projectId }) {
  const queryClient = useQueryClient();
  const [isOpen, setOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [selectedBOM, setSelectedBOM] = useState("");
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { data: projectBOMData } = useBOMProject(projectId);
  const { data: project } = useProject(projectId);

  const { mutate: approveBOM } = useMutation<unknown, AxiosError>({
    mutationFn: () => {
      return approveBOMbyId(selectedBOM, project?.id);
    },
    onSuccess: () => {
      setAlert({ type: "success", message: "Successfully Approved Project" });
      queryClient.invalidateQueries(["project", projectId]);
      queryClient.invalidateQueries(["bom-project", projectId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message || "",
      });
    },
  });

  const { mutate: approveProject } = useMutation<unknown, AxiosError>({
    mutationFn: () => {
      return updateProjectById(projectId, {
        status: APPROVED_PROJECT,
      });
    },
    onSuccess: () => {
      approveBOM();
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message || "",
      });
    },
  });

  useEffect(() => {
    if (projectBOMData?.saved_boms?.results) {
      const firstBOM = projectBOMData?.saved_boms?.results.find(
        (result) => result.is_parent && result.status === SAVED_BOM
      );
      if (firstBOM) setSelectedBOM(firstBOM.id);
    }
  }, [projectBOMData]);

  const toggleModal = () => {
    setOpen((prev) => !prev);
  };

  const isValid = () => {
    return project.capex_code && project.capex_code.length > 0;
  };

  const savedParentBOMs = useCallback(() => {
    return projectBOMData?.saved_boms?.results.filter((result) => result.is_parent && result.status === SAVED_BOM);
  }, [projectBOMData]);

  const handleSelectBOM = (value) => {
    setSelectedBOM(value.id);
  };

  return (
    <Box>
      {project?.status === IN_PROGRESS_BOM && (
        <Button
          disabled={!savedParentBOMs()?.length}
          fullWidth
          sx={{
            padding: "2em",
            fontSize: "14px",
          }}
          onClick={() => {
            if (isValid()) {
              toggleModal();
            } else {
              setErrorOpen((prev) => !prev);
            }
          }}
        >
          Approve Project
        </Button>
      )}
      <Dialog open={isOpen}>
        <DialogContent dividers>
          <DialogTitle marginRight="10px">
            You are about to approve the project for the following BOM:
            <IconButton
              onClick={() => {
                toggleModal();
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        </DialogContent>
        <DialogContent>
          <FormControl
            fullWidth
            sx={{
              mt: 5,
              mb: 5,
            }}
          >
            <Select
              labelId="project-approve-bom-select-label"
              id="project-approve-bom-select"
              label="Project Approval BOM"
              sx={{ inlineSize: "min-content" }}
              value={selectedBOM}
            >
              {savedParentBOMs()?.map((result) => (
                <MenuItem key={result.id} value={result.id} onClick={() => handleSelectBOM(result)}>
                  <Typography variant="body2" sx={{ color: "inherit" }}>
                    <Box component="span" sx={{ display: "inline", marginRight: MARGIN_RIGHT }}>
                      {result.name}
                    </Box>
                    <Box component="span" sx={{ display: "inline", marginRight: MARGIN_RIGHT }}>
                      {result.version}
                    </Box>
                    <Box component="span" sx={{ display: "inline" }}>{`Updated: ${StandardDate(
                      result.last_updated
                    )}`}</Box>
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              approveProject();
              toggleModal();
            }}
            sx={{
              marginLeft: "auto",
            }}
            color="primary"
          >
            Approve Project
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isErrorOpen}>
        <DialogContent dividers>
          <DialogTitle marginRight="10px">
            Some additional information is needed before you can approve this project.
            <IconButton
              onClick={() => {
                setErrorOpen((prev) => !prev);
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        </DialogContent>
        <DialogContent>
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            {project?.capex_code && project?.capex_code.length > 0 ? (
              <></>
            ) : (
              <ListItem sx={{ display: "list-item" }}>
                This project must have a capex code before it can be approved.
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Box>
  );
}
