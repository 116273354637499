import { create } from "zustand";

interface LibraryTabState {
  tabIndex: number;
  searchTerm: string;
  limit: number;
  offset: number;
  updateTabIndex: (tabIndex: number) => void;
  updateSearchTerm: (searchTerm: string) => void;
  updateLimit: (limit: number) => void;
  updateOffset: (offset: number) => void;
}

export const useLibraryTabStore = create<LibraryTabState>((set) => ({
  tabIndex: 0,
  searchTerm: "",
  limit: 10,
  offset: 0,
  updateTabIndex: (tabIndex: number) => set(() => ({ tabIndex })),
  updateSearchTerm: (searchTerm: string) => set(() => ({ searchTerm })),
  updateLimit: (limit: number) => set(() => ({ limit })),
  updateOffset: (offset: number) => set(() => ({ offset })),
}));
