import { BOM } from "types/BOM";
import { create } from "zustand";

interface ScalerState {
  standardBOMs: BOM[];
  setStandardBOMs: (x) => void;
  isAddOpen: boolean;
  setIsAddOpen: (x) => void;
  template: { template_id: string; id: string } | undefined;
  setTemplate: (x) => void;
  editScaler:
    | {
        datacenter_id: string;
        project_location_id: string;
        template_bom_id: string;
        scaler_qty: number | undefined;
        id: string;
        project_template_id: string;
      }
    | undefined;
  setEditScaler: (x) => void;
}

export const useScalerStore = create<ScalerState>((set) => ({
  standardBOMs: [],
  setStandardBOMs: (standardBOMs) => set({ standardBOMs }),
  isAddOpen: false,
  setIsAddOpen: (isAddOpen) => set({ isAddOpen }),
  template: undefined,
  setTemplate: (template) => set({ template }),
  editScaler: undefined,
  setEditScaler: (editScaler) => set({ editScaler }),
}));
