import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TableCell,
  styled,
  tableCellClasses,
  IconButton,
  Grid,
  Tooltip,
  Paper,
  Avatar,
  FormControl,
  Stack,
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
} from "utils/MuiWrapper/components";
import { Close, Edit } from "utils/MuiWrapper/icons";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useProject, updateProjectTeam, deleteProjectTeamMember } from "api/project";
import { useGetUser, useGetUsers } from "api/auth";
import { CARD_BG_COLOR, ARCHIVED_PROJECT } from "utils/constants";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { User } from "types/User";
import { TeamMember } from "types/Project";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { Roles } from "utils/enums";
import { getAvatarInitials } from "components/Sidenav/UserAvatar";
import { EditTeam } from "./EditTeam";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "medium",
    borderBottom: "none",
    padding: "0 0 5px 10px",
  },
}));

export const ProjectTeam = () => {
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { projectId } = useParams();
  const { data: project, isSuccess, isLoading } = useProject(projectId);
  const queryClient = useQueryClient();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { data: users } = useGetUsers();

  const handleClickEdit = () => {
    setIsConfirmOpen(true);
  };

  const { mutate: editProjectTeam } = useMutation({
    mutationFn: (data: TeamMember) => updateProjectTeam(project.id, data),
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
    onSuccess: (data, variables, _) => {
      queryClient.invalidateQueries(["project", projectId]);
      setAlert({
        type: "success",
        message: `${variables.role === "project_manager" ? "project manager" : "project member"} added`,
      });
    },
  });

  const { mutate: removeProjectTeamMember } = useMutation({
    mutationFn: (data: TeamMember) => deleteProjectTeamMember(project.id, data.user_id),
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
    onSuccess: (data, variables, _) => {
      queryClient.invalidateQueries(["project", projectId]);
      setAlert({
        type: "success",
        message: `${variables.role === "project_manager" ? "project manager" : "project member"} removed`,
      });
    },
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isSuccess) {
    return <Typography>Error</Typography>;
  }

  const usersMap = new Map<string, User>();
  users?.results?.map((user) => usersMap.set(user.id, user));

  const projectManagerIds: Array<string> = [];
  const projectMemberIds: Array<string> = [];
  project?.project_teams?.map((teamMember: TeamMember) =>
    teamMember.role === "project_manager"
      ? projectManagerIds.push(teamMember.user_id)
      : projectMemberIds.push(teamMember.user_id)
  );

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  return (
    <>
      <Card
        variant="elevation"
        sx={{ height: "48.5vh", overflow: "auto", backgroundColor: CARD_BG_COLOR, borderRadius: "16px" }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h1" gutterBottom>
                Project Team
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {((userId !== undefined && projectManagerIds.includes(userId)) ||
                user?._embedded?.role?.name === Roles.Architect) &&
                project?.status !== ARCHIVED_PROJECT && (
                <Tooltip title="Edit project info">
                  <IconButton onClick={handleClickEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
              <Dialog title={"Edit Project Info"} open={isConfirmOpen}>
                <DialogContent dividers>
                  <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                    <IconButton onClick={() => setIsConfirmOpen(false)}>
                      <Close />
                    </IconButton>
                  </Box>
                  <DialogTitle align="center" sx={{ pt: 0 }}>
                    Manage Project Team
                  </DialogTitle>
                </DialogContent>
                <DialogContent>
                  <EditTeam
                    project={project}
                    editProjectTeam={editProjectTeam}
                    deleteProjectTeamMember={removeProjectTeamMember}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
          <Typography variant="h5">Project Managers</Typography>
          <Paper
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              listStyle: "none",
              p: 0.5,
              m: 0,
              backgroundColor: CARD_BG_COLOR,
            }}
            component="ul"
          >
            {projectManagerIds?.map((teamMemberId: string) => {
              return (
                <ListItem key={teamMemberId} sx={{ padding: "1em" }}>
                  <Stack direction="row">
                    <Avatar>{getAvatarInitials(usersMap.get(teamMemberId)?.name ?? "")}</Avatar>
                    <FormControl sx={{ ml: "13px" }} size="small" fullWidth>
                      <Typography sx={{ margin: "auto" }}>{usersMap.get(teamMemberId)?.name ?? ""}</Typography>
                    </FormControl>
                  </Stack>
                </ListItem>
              );
            })}
          </Paper>
          <Typography pt={5} variant="h5">
            Project Team Members
          </Typography>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
              backgroundColor: CARD_BG_COLOR,
            }}
            component="ul"
          >
            {projectMemberIds?.map((teamMemberId: string) => {
              return (
                <ListItem key={teamMemberId} sx={{ padding: "1em" }}>
                  <Stack direction="row">
                    <Tooltip title={usersMap.get(teamMemberId)?.name ?? ""}>
                      <Avatar>{getAvatarInitials(usersMap.get(teamMemberId)?.name ?? "")}</Avatar>
                    </Tooltip>
                  </Stack>
                </ListItem>
              );
            })}
          </Paper>
        </CardContent>
      </Card>
    </>
  );
};
