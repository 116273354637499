import * as yup from "yup";

export const bomItemSchema = yup.object().shape({
  qty: yup.number().integer().min(0, "Qty cannot be negative").required("Qty is required"),
  supplier: yup.string().required("Supplier is required."),
  supplier_part_number: yup.string().required("Part Number is required."),
  manufacturer: yup.string().required("Manufacturer is required."),
  manufacturer_part_number: yup.string().required("Part Number is required."),
  description: yup.string().required("Part Description is required."),
  price: yup.number().min(0, "Price cannot be negative.").required("Price is required."),
  discount: yup.number().integer().min(0, "Discount cannot be negative.").required("Discount is required."),
  total_monthly: yup.number().min(0, "Total Monthly cannot be negative.").required("Total Monthly is required."),
});
