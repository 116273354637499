import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
  Typography,
  TextField,
  Stack,
  Button,
} from "utils/MuiWrapper/components";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Close } from "utils/MuiWrapper/icons";
import { create } from "zustand";
import { useTemplates } from "api/template";
import { addTemplateToProject as apiAddTemplateToProject } from "api/project";
import TemplatesTable from "components/shared/TemplatesTable";
import { Template } from "types/Template";
import { SearchInput } from "./SearchBar";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";

export const AssignTemplateToProjectModal = () => {
  return (
    <>
      <AddTemplateModal />
      <SelectTemplateModal />
    </>
  );
};

const AddTemplateModal = () => {
  const close = useAssignTemplateToProjectModalState((state) => state.close);
  const templateName = useAssignTemplateToProjectModalState((state) => state.templateName);
  const setTemplateName = useAssignTemplateToProjectModalState((state) => state.setTemplateName);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate: addTemplateToProject } = useMutation<Template, Error, { templateId: string; name: string }>({
    mutationFn: ({ templateId, name }) => apiAddTemplateToProject(projectId || "", templateId, { name }),
    onSuccess: (data) => {
      setTemplateName("");
      setSelectedTemplate(null);
      queryClient.invalidateQueries(["project", projectId]);
      close();
      navigate(`/user-view/project/${projectId}/template/${data.template_id}/project-template/${data.id}`);
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });
  const isOpen = useAssignTemplateToProjectModalState((state) => state.isOpen);
  const openSelectTemplateModal = useSelectTemplateModalState((state) => state.open);

  const selectedTemplate = useAssignTemplateToProjectModalState((state) => state.selectedTemplate);
  const setSelectedTemplate = useAssignTemplateToProjectModalState((state) => state.setSelectedTemplate);

  useEffect(() => {
    if (!isOpen) {
      setTemplateName("");
      setSelectedTemplate(null);
    }
  }, [isOpen]);

  const handleAdd = () => {
    if (!templateName.trim()) {
      setAlert({ type: "warning", message: "Please enter a template name." });
    } else if (!selectedTemplate) {
      setAlert({ type: "warning", message: "Please select a template." });
    } else {
      addTemplateToProject({
        templateId: selectedTemplate.id,
        name: templateName,
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md">
      <DialogContent sx={{ overflow: "hidden" }}>
        <DialogTitle>
          Add Template
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>
      </DialogContent>
      <DialogContent>
        <Stack direction="column" spacing={4}>
          <TextField
            autoFocus
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Project Template Name"
            onKeyUp={(e) => {
              if (e.key === "Enter") handleAdd();
            }}
          />
          {selectedTemplate && <Typography>Template: {selectedTemplate.name}</Typography>}
          {!selectedTemplate && <Typography>No template selected</Typography>}
          <Box>
            <Button onClick={() => openSelectTemplateModal()}>Select a Template</Button>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};
const SelectTemplateModal = () => {
  const [limit, setLimit] = useState(10);
  const templateType = useSelectTemplateModalState((state) => state.templateType);
  const [searchText, setSearchText] = useState("");
  const isOpen = useSelectTemplateModalState((state) => state.isOpen);
  const close = useSelectTemplateModalState((state) => state.close);
  const [offset, setOffset] = useState(0);
  const { data: templatesData } = useTemplates({
    type: templateType,
    quick_search: searchText,
    offset: offset * limit,
    limit: limit,
    status: "published",
  });
  const setSelectedTemplate = useAssignTemplateToProjectModalState((state) => state.setSelectedTemplate);

  useEffect(() => {
    setSearchText("");
  }, [isOpen]);

  const handleSearch = (searchQuery) => {
    setOffset(0);
    setSearchText(searchQuery);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setLimit(value);
  };

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md">
      <DialogContent sx={{ overflow: "hidden" }}>
        <DialogTitle>
          Select a Template
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>
      </DialogContent>
      <DialogContent>
        <Stack direction="column" spacing={4}>
          <Box>
            <Box sx={{ marginBottom: "10px", width: "30%" }}>
              <SearchInput delegateSearch={handleSearch} />
            </Box>
            <TemplatesTable
              limit={limit}
              templates={templatesData}
              onSelectRow={(template) => {
                setSelectedTemplate(template);
                close();
              }}
              offset={offset}
              setOffset={setOffset}
              setRows={handleChangeRowsPerPage}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

type SelectTemplateModalState = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  templateType: string;
  setTemplateType: (x: string) => void;
};

export const useSelectTemplateModalState = create<SelectTemplateModalState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  templateType: "standard",
  setTemplateType: (templateType) => set({ templateType }),
}));

type AssignTemplateToProjectModalState = {
  isOpen: boolean;
  open: () => void;
  close: () => void;

  templateName: string;
  setTemplateName: (x: string) => void;
  selectedTemplate: Template | null;
  setSelectedTemplate: (x: Template | null) => void;
};

export const useAssignTemplateToProjectModalState = create<AssignTemplateToProjectModalState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),

  templateName: "",
  setTemplateName: (templateName) => set({ templateName }),
  selectedTemplate: null,
  setSelectedTemplate: (template) => set({ selectedTemplate: template }),
}));
