import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import SearchBar from "../shared/SearchBar";
import { CreateManufacturer } from "./CreateManufacturer";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { getManufacturers } from "api/manufacturer";
import { FormControl, FormHelperText, MenuItem, Select, Typography } from "utils/MuiWrapper/components";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { StandardDate } from "utils/dates";
import { AxiosError } from "axios";

const Manufacturers = () => {
  const [showModal, setShowModal] = useState(false);

  const [quickSearchText, setQuickSearchText] = useState<string>();
  const [selected, setSelected] = useState();

  const [totalResults, setTotalResults] = useState(0);
  const gridRef = useRef<AgGridReact | null>(null);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const handleModalClose = () => {
    setShowModal(false);
    gridRef?.current?.api?.setDatasource(dataSource);
  };

  // #region Grid Dependencies
  const columns: ColDef[] = [
    {
      field: "name",
      headerName: "Manufacturer Name",
      flex: 1,
      suppressSizeToFit: true,
      resizable: false,
      sortable: true,
    },
    {
      field: "created_at",
      headerName: "Created",
      resizable: false,
      sortable: true,
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
    {
      field: "updated_at",
      headerName: "Last Modified",
      resizable: false,
      sortable: true,
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
  ];

  const dataSource = {
    rowCount: undefined,
    getRows: async (params) => {
      try {
        gridRef?.current?.api.showLoadingOverlay();
        const res = await getManufacturers({
          limit: 100,
          offset: params.startRow,
          quick_search: quickSearchText,
          order_by: params.sortModel[0]?.colId,
          order_direction: params.sortModel[0]?.sort,
        });
        const { results, total_results } = res;
        setTotalResults(total_results);
        params.successCallback(results, total_results);
      } catch (error) {
        console.log("get data error: ", error);
        if (error instanceof AxiosError) {
          setAlert({
            type: "error",
            message: `Could not get manufacturer data. ${error?.response?.data?.detail || ""}`,
          });
        } else {
          setAlert({
            type: "error",
            message: `Could not get manufacturer data.}`,
          });
        }
      } finally {
        gridRef?.current?.api?.hideOverlay();
      }
    },
  };

  const onGridReady = useCallback(
    (params) => {
      params.api.setDatasource(dataSource);
    },
    [totalResults, quickSearchText]
  );

  const searchManufacturers = (searchString: string) => {
    setQuickSearchText(searchString);
  };
  useEffect(() => {
    gridRef?.current?.api?.setDatasource(dataSource);
  }, [quickSearchText]);

  const onPageSizeChanged = useCallback((event) => {
    gridRef?.current?.api?.paginationSetPageSize(parseInt(event.target.value));
    gridRef?.current?.api?.setDatasource(dataSource);
  }, []);

  const onSelectionChanged = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows();
    if (selectedRows) {
      setSelected(selectedRows[0]);
      setShowModal(true);
    }
  };
  // #endregion Grid Dependencies

  return (
    <>
      <Typography variant="h1">Manufacturers</Typography>

      <CreateManufacturer isOpen={showModal} data={selected} handleClose={handleModalClose} />
      <SearchBar
        delegateSearch={searchManufacturers}
        handleShow={() => {
          setSelected(undefined);
          setShowModal(true);
        }}
      />
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ width: "100%", height: "84vh" }} className="ag-theme-alpine-dark ag-theme-custom">
          <AgGridReact
            ref={gridRef}
            alwaysShowHorizontalScroll
            columnDefs={columns}
            infiniteInitialRowCount={totalResults}
            onGridReady={onGridReady}
            rowModelType={"infinite"}
            rowSelection="single"
            pagination={true}
            onSelectionChanged={onSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
      <FormControl sx={{ float: "right", mt: 2 }}>
        <Select value={100} label="Page Size" onChange={onPageSizeChanged}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
        </Select>
        <FormHelperText sx={{ mt: 1 }}>Page Size</FormHelperText>
      </FormControl>
    </>
  );
};

export default Manufacturers;
