import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest, patchRequest } from "../http/componentClient";
import { QueryParamsType } from "types/Queries";
import { formatError } from "http/client";

export async function getComponents(params?: QueryParamsType) {
  try {
    const res = await getRequest("/v1/api/components", params);
    return res.data;
  } catch (error) {
    return formatError(`Could not fetch components`, error);
  }
}

export const useGetComponents = (params?: QueryParamsType) => {
  return useQuery({
    queryKey: ["get_components", params],
    queryFn: () => getComponents(params),
  });
};

export async function fetchComponentAttributes() {
  try {
    const res = await getRequest(`/v1/api/components/attributes`);
    console.debug("Get Component Attributes", res);
    return res.data;
  } catch (error) {
    return formatError("Could not fetch component attributes", error);
  }
}

export const useGetComponentAttributes = () => {
  return useQuery({
    queryKey: ["get_component_attributes"],
    queryFn: () => fetchComponentAttributes(),
  });
};

export async function postComponent(data) {
  try {
    const res = await postRequest(`/v1/api/components`, data);
    return res;
  } catch (error) {
    return formatError(`Could not save component`, error);
  }
}

export async function patchComponent(id, data) {
  try {
    const res = await patchRequest(`/v1/api/components/${id}`, data);
    return res;
  } catch (error) {
    return formatError(`Could not update component`, error);
  }
}
