import { forwardRef, memo, useEffect, useRef, useState } from "react";
import { TextField } from "utils/MuiWrapper/components";
import { useImperativeHandle } from "react";
import { formatISO, isValid } from "date-fns";
import { NorthStarDesktopDatePicker } from "components/DesktopDatePicker";

type DateEditorProps = {
  value;
};

export const DateEditor = memo(
  forwardRef((props: DateEditorProps, ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      refInput?.current?.focus();
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return isValid(value) ? formatISO(value) : value;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        // isCancelAfterEnd() {
        //   return value;
        // },
      };
    });

    const onChange = (value) => {
      setValue(value);
    };

    return (
      <NorthStarDesktopDatePicker
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            sx={{
              display: "block",
            }}
            name="date_required"
            fullWidth
            {...params}
          />
        )}
      />
    );
  })
);
