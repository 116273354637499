import {
  DynamicForm,
  Folder,
  GroupWork,
  HomeRepairService,
  Receipt,
  ShoppingCart,
  RateReview,
} from "utils/MuiWrapper/icons";

import Templates from "./components/Templates/Templates";
import TemplateBuilder from "components/TemplateBuilder/TemplateBuilder";
import TemplateUserView from "components/TemplateUserView/TemplateUserView";
import { BOMView } from "./components/BOMView/BOMView";
import Manufacturers from "components/Manufacturers/Manufacturers";
import ProjectsPage from "./components/ProjectsPage";
import { ProjectDetailViewPage } from "components/ProjectDetailViewPage";
import { ComponentCatalog } from "components/ComponentCatalog";
import HomePage from "components/Library/HomePage";
import { TemplateScalerPage } from "components/TemplateScaler/TemplateScalerPage";
import ComponentToolbox from "components/ComponentToolbox/ComponentToolbox";
import Receiving from "components/Receiving";
import Purchasing from "components/Purchasing/Purchasing";
import { TechReview } from "components/TechReview/TechReview";
import { Roles } from "utils/enums";

const routes = [
  {
    name: "Library",
    key: "home",
    sideNav: true,
    sideNavType: "top",
    route: "/",
    icon: <Folder />,
    component: <HomePage />,
  },
  {
    name: "Templates",
    key: "templates",
    sideNav: false,
    sideNavType: "top",
    route: "/templates",
    icon: <DynamicForm />,
    component: <Templates />,
  },
  {
    name: "Projects",
    key: "projects-page",
    sideNav: false,
    sideNavType: "top",
    route: "/projects-page",
    icon: <GroupWork />,
    component: <ProjectsPage />,
  },
  {
    name: "Tech Review",
    key: "tech-review",
    sideNav: true,
    sideNavType: "top",
    route: "/tech-review",
    icon: <RateReview />,
    component: <TechReview />,
    roles: [Roles.Manager],
  },
  {
    name: "Purchasing",
    key: "purchasing",
    sideNav: true,
    sideNavType: "top",
    route: "/purchasing",
    icon: <ShoppingCart />,
    component: <Purchasing />,
    allowList: [Roles.Purchaser],
  },
  {
    name: "Receiving",
    key: "receiving",
    sideNav: true,
    sideNavType: "top",
    route: "/receiving",
    icon: <Receipt />,
    component: <Receiving />,
    blockList: [Roles.Purchaser]
  },
  {
    name: "Components",
    key: "components",
    sideNav: true,
    sideNavType: "top",
    route: "/components",
    icon: <HomeRepairService />,
    component: <ComponentToolbox />,
  },
  {
    name: "Manufacturers",
    key: "manufacturers",
    sideNav: false,
    sideNavType: "top",
    route: "/manufacturers",
    icon: <HomeRepairService />,
    component: <Manufacturers />,
  },
  {
    name: "BOM Table",
    key: "bom-table",
    sideNavType: "project",
    route: "/bom-view/:bomId/project/:projectId/project-template/:projectTemplateId",
    component: <BOMView />,
  },
  {
    name: "BOM Table",
    key: "bom-table",
    sideNavType: "project",
    route: "/bom-view/:bomId/project/:projectId/added/:numComponentsAdded",
    component: <BOMView />,
  },
  {
    name: "Template Builder",
    key: "template-builder",
    sideNavType: "top",
    route: "/template-builder/:templateId",
    component: <TemplateBuilder />,
    allowList: [Roles.Architect],
  },
  {
    name: "User View",
    key: "user-view",
    route: "/user-view/project/:projectId/template/:templateId/project-template/:projectTemplateId",
    sideNavType: "project",
    component: <TemplateUserView />,
  },
  {
    name: "Project Detail View",
    key: "project-detail-view",
    sideNavType: "project",
    route: "/projects/:projectId",
    component: <ProjectDetailViewPage />,
  },
  {
    name: "Template Scaler",
    key: "template-scaler",
    sideNavType: "project",
    route: "/projects/:projectId/template-scaler",
    component: <TemplateScalerPage />,
  },
  {
    name: "Component Catalog",
    key: "component-catalog",
    sideNav: false,
    route: "/component-catalog",
    component: <ComponentCatalog />,
  },
];

export const findRoute = (key) => {
  routes.find((route) => route.key === key);
};

export const sideNavRoutes = routes.filter((route) => route.sideNav === true);

export const projectSideNavRoutes = routes.filter((route) => route.sideNavType === "project");

export default routes;
