import { create } from "zustand";

type ProjectIdName = {
  [id: string]: string;
};

type ProjectType = {
  projectLookup: ProjectIdName | null;
  updateProjectLookup: (x: ProjectIdName) => void;
};

export const useProjectLookupState = create<ProjectType>((set) => ({
  projectLookup: null,
  updateProjectLookup: (projectLookup) => set({ projectLookup }),
}));
