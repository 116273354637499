import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addLocationToProject,
  deleteLocationFromProject,
  updateLocationInProject,
  useProjectLocations,
} from "api/project-scaler";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "utils/MuiWrapper/components";
import { Clear, ChevronRight } from "utils/MuiWrapper/icons";
import { ScalerLocation } from "types/TemplateScaler";
import { APPROVED_PROJECT, TEXT_FONT_SIZE, ARCHIVED_PROJECT } from "utils/constants";
import { useProject } from "api/project";

export const ProjectLocations = ({ open, setOpen }) => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { data: project } = useProject(projectId);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [newLocation, setNewLocation] = useState("");
  const [editId, setEditId] = useState("");
  const [editText, setEditText] = useState("");

  const { data: locations } = useProjectLocations(projectId);
  const { mutate: addLocation } = useMutation({
    mutationFn: () => addLocationToProject(projectId, newLocation),
    onSuccess: () => {
      setNewLocation("");
      queryClient.invalidateQueries(["project-locations", projectId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });
  const { mutate: deleteLocation } = useMutation({
    mutationFn: (locationId: string) => deleteLocationFromProject(projectId, locationId),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-locations", projectId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });
  const { mutate: editLocation } = useMutation({
    mutationFn: (data: { locationId: string; name: string }) =>
      updateLocationInProject(projectId, data.locationId, data.name),
    onSuccess: () => {
      queryClient.invalidateQueries(["project-locations", projectId]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const handleClose = () => setOpen(false);

  const handleChangeNewLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLocation(event.target.value);
  };
  const handleAdd = () => {
    addLocation();
  };

  const handleAddEnter = (event) => {
    if (event.key === "Enter") {
      handleAdd();
      event.target.blur();
    }
  };

  const handleDelete = (id: string) => {
    deleteLocation(id);
  };

  const handleSave = (location: ScalerLocation) => {
    editLocation({ locationId: location.id, name: editText });
    setEditId("");
    setEditText("");
  };

  const handleEditText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditText(event.target.value);
  };

  const handleTextClick = (e, location: ScalerLocation) => {
    if (project?.status === APPROVED_PROJECT || project?.status === ARCHIVED_PROJECT) return;
    setEditId(location?.id);
    setEditText(location?.name);
  };

  return (
    <Drawer open={open} anchor="right" ModalProps={{ onBackdropClick: handleClose }}>
      <IconButton onClick={handleClose}>
        <ChevronRight fontSize="medium" />
      </IconButton>
      <Divider />
      <Box sx={{ marginLeft: 3, marginTop: 2 }}>
        <Typography variant="h2" sx={{ marginBottom: 3 }}>
          Locations
        </Typography>
        {project?.status !== APPROVED_PROJECT && project?.status !== ARCHIVED_PROJECT && (
          <Box sx={{ display: "flex", marginRight: 2 }}>
            <TextField
              fullWidth
              value={newLocation}
              onChange={handleChangeNewLocation}
              onKeyPress={handleAddEnter}
              placeholder="Enter location name"
            />
            <Button sx={{ marginLeft: 2 }} onClick={handleAdd} color="primary">
              Add
            </Button>
          </Box>
        )}
        <List sx={{ marginTop: 3 }}>
          {locations?.results?.map((location: ScalerLocation, index: number) => (
            <ListItem key={index} disablePadding>
              <ListItemButton selected={false} sx={{ cursor: "text" }}>
                <ListItemText onClick={(e) => handleTextClick(e, location)} sx={{ margin: 0 }}>
                  {editId === location.id ? (
                    <TextField
                      fullWidth
                      value={editText}
                      onChange={handleEditText}
                      onBlur={() => handleSave(location)}
                      autoFocus={true}
                      sx={{ fontSize: TEXT_FONT_SIZE }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          (e.target as HTMLInputElement).blur();
                        }
                      }}
                    />
                  ) : (
                    <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                      {location?.name}
                    </Typography>
                  )}
                </ListItemText>
                {project?.status !== APPROVED_PROJECT && project?.status !== ARCHIVED_PROJECT && (
                  <IconButton onClick={() => handleDelete(location?.id)}>
                    <Clear />
                  </IconButton>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
