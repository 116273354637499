import { useState } from "react";
import { useDebounce } from "utils/helpers";
import { Typography } from "utils/MuiWrapper/components";
import { PurchasingGrid } from "./PurchasingGrid";
import { TopCard } from "./TopCard";
import { useSearchParams } from "react-router-dom";
import { BOMFields } from "utils/enums";

const Purchasing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const poNumParam = searchParams.get(BOMFields.PONumber);
  const rfpNumParam = searchParams.get(BOMFields.RFPNumber);

  const [poNumberSearch, setPoNumberSearch] = useState<string>(poNumParam || "");
  const debouncedPOSearchVal = useDebounce(poNumberSearch, 500);

  const [rfpNumberSearch, setRfpNumberSearch] = useState<string>(rfpNumParam || "");
  const debouncedRfpSearchVal = useDebounce(rfpNumberSearch, 500);

  const handlePoSearchChange = (event) => {
    const value = event.target.value;
    const newParams = {};
    if (value) newParams[BOMFields.PONumber] = value;
    if (rfpNumberSearch) newParams[BOMFields.RFPNumber] = rfpNumberSearch;
    setSearchParams(newParams);
    setPoNumberSearch(value);
  };
  const handleRFPSearchChange = (event) => {
    const value = event.target.value;
    const newParams = {};
    if (poNumberSearch) newParams[BOMFields.PONumber] = poNumberSearch;
    if (value) newParams[BOMFields.RFPNumber] = value;
    setSearchParams(newParams);
    setRfpNumberSearch(value);
  };

  return (
    <>
      <Typography variant="h1">Purchasing</Typography>
      <TopCard
        poNumberSearch={poNumberSearch}
        handlePoSearchChange={handlePoSearchChange}
        rfpNumberSearch={rfpNumberSearch}
        handleRFPSearchChange={handleRFPSearchChange}
      />
      <PurchasingGrid poSearchVal={debouncedPOSearchVal} rfpSearchVal={debouncedRfpSearchVal} />
    </>
  );
};

export default Purchasing;
