import { getRequest, createRequest, updateRequest, deleteRequest } from "http/axiosClient";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { findIndex } from "lodash";
import { NewFieldCollection, Template, TemplateTypes } from "types/Template";
import { TemplateSectionField } from "types/TemplateSectionField";
import { TemplateSectionFieldOption } from "types/TemplateSectionFieldOption";
import { TemplateVariable } from "types/TemplateVariable";
import { QueryParamsType } from "types/Queries";
import { formatError } from "http/client";
import { AxiosError } from "axios";
import { FETCH_ALL_LIMIT } from "utils/constants";

// Fetch section fields ordered by ordinal
export async function fetchFields(templateId: string, fieldCollectionId: string) {
  try {
    const res = await getRequest(`/v1/api/template/${templateId}/section/${fieldCollectionId}/fields`, {
      order_by: "ordinal",
      limit: FETCH_ALL_LIMIT,
    });
    return res.data;
  } catch (error) {
    return formatError("Unable to fetch fields", error);
  }
}

// Fetch section field by field id
export async function fetchField(templateId: string, fieldCollectionId: string, columnOneFieldId: string) {
  try {
    const res = await getRequest(
      `/v1/api/template/${templateId}/section/${fieldCollectionId}/fields/${columnOneFieldId}`
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to fetch field", error);
  }
}

// Create field
export async function createField(
  templateId: string,
  fieldCollectionId: string,
  data: Omit<TemplateSectionField, "id" | "template_section_field_options">
) {
  try {
    const res = await createRequest(`/v1/api/template/${templateId}/section/${fieldCollectionId}/fields`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to create field", error);
  }
}

// Update field
export async function updateField(
  templateId: string,
  fieldCollectionId: string,
  fieldId: string,
  data: Partial<TemplateSectionField>
) {
  try {
    const res = await updateRequest(
      `/v1/api/template/${templateId}/section/${fieldCollectionId}/fields/${fieldId}`,
      data
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to update field", error);
  }
}

// Delete field
export async function deleteField(templateId: string, fieldCollectionId: string, fieldId: string) {
  try {
    await deleteRequest(`/v1/api/template/${templateId}/section/${fieldCollectionId}/fields/${fieldId}`);
  } catch (error) {
    return formatError("Unable to delete field", error);
  }
}

// Fetch field options
export async function fetchFieldOptions(templateId: string, fieldCollectionId: string, columnOneFieldId: string) {
  try {
    const res = await getRequest(
      `/v1/api/template/${templateId}/section/${fieldCollectionId}/fields/${columnOneFieldId}/options`,
      { order_by: "ordinal", limit: 10000 }
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to fetch field options", error);
  }
}

export const useFieldOptions = (templateId: string, fieldCollectionId: string, columnOneFieldId: string) => {
  return useQuery(["field-options", templateId, fieldCollectionId, columnOneFieldId], () =>
    fetchFieldOptions(templateId, fieldCollectionId, columnOneFieldId)
  );
};

// Create field option
export async function createFieldOption(
  templateId: string,
  fieldCollectionId: string,
  columnOneFieldId: string,
  data: Omit<TemplateSectionFieldOption, "id" | "template_section_field_option_id">
) {
  try {
    const res = await createRequest(
      `/v1/api/template/${templateId}/section/${fieldCollectionId}/fields/${columnOneFieldId}/options`,
      data
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to create field option", error);
  }
}

// Update field option
export async function updateFieldOption(
  templateId: string,
  fieldCollectionId: string,
  columnOneFieldId: string,
  fieldId: string,
  data: TemplateSectionFieldOption
) {
  try {
    const res = await updateRequest(
      `/v1/api/template/${templateId}/section/${fieldCollectionId}/fields/${columnOneFieldId}/options/${fieldId}`,
      data
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to update field option", error);
  }
}

// Delete field option
export async function deleteFieldOption(
  templateId: string,
  fieldCollectionId: string,
  columnOneFieldId: string,
  fieldId: string
) {
  try {
    await deleteRequest(
      `/v1/api/template/${templateId}/section/${fieldCollectionId}/fields/${columnOneFieldId}/options/${fieldId}`
    );
  } catch (error) {
    return formatError("Unable to delete field option", error);
  }
}

// Fetch field collections
export async function fetchFieldCollections(templateId: string) {
  try {
    const res = await getRequest(`/v1/api/template/${templateId}/section?limit=10000`, {
      order_by: "ordinal",
    });
    return res.data;
  } catch (error) {
    return formatError("Could not fetch field collections", error);
  }
}

export const useFetchFieldCollections = (templateId: string) => {
  return useQuery({
    queryKey: ["field-collections", templateId],
    queryFn: () => fetchFieldCollections(templateId),
    retry: false,
  });
};

// Create field collection
export async function createFieldCollection(templateId: string, data: NewFieldCollection) {
  try {
    const res = await createRequest(`/v1/api/template/${templateId}/section`, data);
    return res.data;
  } catch (error) {
    return formatError("Could not create field collection", error);
  }
}

// Update field collection
export type FieldCollectionData = Record<string, string>;
export async function updateFieldCollection(templateId: string, fieldCollectionId: string, data: FieldCollectionData) {
  try {
    const res = await updateRequest(`/v1/api/template/${templateId}/section/${fieldCollectionId}`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to update field collection", error);
  }
}

// Delete field collection
export async function deleteFieldCollection(templateId: string, fieldCollectionId: string) {
  try {
    await deleteRequest(`/v1/api/template/${templateId}/section/${fieldCollectionId}`);
  } catch (error) {
    return formatError("Unable to delete field collection", error);
  }
}
export const useFetchTemplate = (templateId: string, enabled = true) => {
  return useQuery({ queryKey: ["template", templateId], queryFn: () => fetchTemplate(templateId), enabled: enabled });
};

// Fetch template
export async function fetchTemplate(templateId) {
  try {
    const res = await getRequest(`/v1/api/template/${templateId}`);
    return res.data;
  } catch (error) {
    return formatError("Unable to fetch template", error);
  }
}

// Create template
export async function createTemplate(data: Template) {
  try {
    const res = await createRequest(`/v1/api/template`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to create template", error);
  }
}

// Update template
export async function updateTemplate(templateId: string, data: Template) {
  try {
    const res = await updateRequest(`/v1/api/template/${templateId}`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to update template", error);
  }
}

// Fetch templates
export async function fetchTemplates(params: QueryParamsType) {
  try {
    const res = await getRequest(`/v1/api/template`, params);
    return res.data;
  } catch (error) {
    return formatError("Unable to fetch templates", error);
  }
}

export const useTemplates = (params) => {
  return useQuery({
    queryKey: ["templates", params],
    queryFn: () => fetchTemplates(params),
    retry: false,
  });
};

// Publish template
export async function publishTemplate(templateId: string, data?: Partial<Template>) {
  try {
    const res = await updateRequest(`/v1/api/template/${templateId}/publish`, data);
    return res.data;
  } catch (error) {
    console.error(error);
    if (error instanceof AxiosError) {
      let errorMessages;
      try {
        errorMessages =
          typeof error?.response?.data?.message === "string" ? (
            <span>{error?.response?.data?.message}</span>
          ) : error?.response?.data?.message?.["Cannot publish template"]?.length === 1 ? (
            <span>{error?.response?.data?.message?.["Cannot publish template"][0]}</span>
          ) : (
            <ul>
              {error?.response?.data?.message?.["Cannot publish template"]?.map((error: string, index: number) => {
                return <li key={index}>{error}</li>;
              })}
            </ul>
          );
      } catch (error) {
        console.error(error);
        return Promise.reject("Could not publish template.");
      }
      return Promise.reject(errorMessages || "Could not publish template.");
    } else {
      return Promise.reject("Could not publish template.");
    }
  }
}

/**
 * Fetch all template variables
 * @returns {{ results: { id: uuid; name: string }[] }}
 */
export async function fetchTemplateVariables() {
  try {
    const res = await getRequest("/v1/api/template/variables?limit=1000");
    return res.data;
  } catch (error) {
    console.log(error);
    return formatError("Unable to fetch template variables", error);
  }
}

export const useTemplateVariables = () => {
  return useQuery({
    queryKey: ["template-variables"],
    queryFn: () => fetchTemplateVariables(),
    retry: false,
  });
};

/**
 * Fetch template variables by template ID
 * @returns {{ results: { template_id: uuid; variable_id: uuid }[] }}
 */
export async function fetchSelectedTemplateVarsById(templateId: string, params?: { type: string }) {
  try {
    const res = await getRequest(`/v1/api/template/${templateId}/variables`, params);
    return res.data;
  } catch (error) {
    return formatError("Unable to get selected variables", error);
  }
}

export const useSelectedTemplateVars = (templateId: string, params?: { type: TemplateTypes }) => {
  return useQuery({
    queryKey: ["selected-template-variables", templateId, params],
    queryFn: () => fetchSelectedTemplateVarsById(templateId, params),
    retry: false,
  });
};

export function _getVariableIndexById(variables: TemplateVariable[], id: string) {
  return findIndex(variables, (x) => x.variable_id === id);
}

// Update template variable by ID
export async function updateTemplateVariableById(id: string, params: { name: string }) {
  try {
    // Postman URL for GlobalVar endpoint = /v1/api/template/variables/:tempalteVariableId
    // Below was mocked URL, is this for the same endpoint? Did the URL pattern change?
    const res = await updateRequest(`/v1/api/template/variables/${id}`, params);
    return res.data;
  } catch (error) {
    return formatError("Unable to update template variable", error);
  }
}

// Delete template variable from template by template id and variable id
export async function deleteTemplateVariableById(templateId: string, id: string, params?: { type: string }) {
  try {
    let url = `/v1/api/template/${templateId}/variables/${id}`;
    if (params) url += `?type=${params?.type}`;
    const res = await deleteRequest(url);
    return res;
  } catch (error) {
    return formatError("Unable to unselect variable", error);
  }
}

export async function deleteGlobalVariableById(variableId: string) {
  try {
    const res = await deleteRequest(`/v1/api/template/variables/${variableId}`);
    return res;
  } catch (error) {
    return formatError("Unable to delete global variable", error);
  }
}

// Create template variable
export async function createGlobalTemplateVariable(name) {
  try {
    const data = { name: name };
    const res = await createRequest(`/v1/api/template/variables`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to create variable", error);
  }
}

// POST selected template variable
export async function createTemplateVariable(templateId: string, data: { variable_id: string; type?: string }) {
  try {
    const res = await createRequest(`/v1/api/template/${templateId}/variables`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to select variable", error);
  }
}

/**
 * Returns all template variables organized into a map
 * @returns {{ [variableId: uuid]: { id: uuid, name: string }}}
 */
export const useAllTemplateVariables = () => {
  return useQuery(["all-template-variables"], async () => {
    const response = await fetchTemplateVariables();

    return response.results.reduce((accumulator, templateVariable) => {
      accumulator[templateVariable.id] = templateVariable;
      return accumulator;
    }, {});
  });
};

/**
 * Maps a variable id into its name
 * @returns {"Loading..." | "Unknown" | string}
 */
export const getTemplateVariableName = ({
  allTemplateVariables,
  variableId,
}: {
  allTemplateVariables: TemplateVariable[];
  variableId: string;
}) => {
  if (!allTemplateVariables || !variableId) {
    return "Loading...";
  }

  if (variableId in allTemplateVariables) {
    return allTemplateVariables[variableId].name;
  }

  return "Unknown";
};

/**
 * Returns list of variables for a template, with name injected
 * @returns {{ id: uuid; name: string }[]}
 */
export const useVariablesForTemplate = (templateId: string, params?: { type: TemplateTypes }) => {
  const { data: allTemplateVariables, isSuccess: allTemplateVariablesSuccess } = useAllTemplateVariables();

  const { data: templateVariablesById, isSuccess: templateVariablesByIdSuccess } = useSelectedTemplateVars(
    templateId,
    params
  );

  const templateVariablesWithName = useMemo(() => {
    if (!allTemplateVariablesSuccess || !templateVariablesByIdSuccess) {
      return undefined;
    }

    return templateVariablesById?.results?.map((templateVariableById) => {
      return {
        id: templateVariableById.variable_id,
        name: getTemplateVariableName({
          allTemplateVariables,
          variableId: templateVariableById.variable_id,
        }),
      };
    });
  }, [allTemplateVariablesSuccess, templateVariablesByIdSuccess, templateVariablesById, allTemplateVariables]);

  return templateVariablesWithName;
};

// POST clone Project
export async function cloneProject(projectId: string, name: string) {
  try {
    const data = { name };
    const res = await createRequest(`/v1/api/projects/${projectId}/clone`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to clone project", error);
  }
}
// POST clone Template
export async function cloneTemplate(templateId: string, name: string) {
  try {
    const data = { name: name };
    const res = await createRequest(`/v1/api/template/${templateId}/clone`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to clone template", error);
  }
}

//Create/retrieve test for template
export async function testTemplate(templateId: string) {
  try {
    const res = await getRequest(`/v1/api/template/${templateId}/test`);
    return res.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorMessages =
        typeof error?.response?.data?.message === "string" ? (
          <span>{error?.response?.data?.message}</span>
        ) : error?.response?.data?.message?.["Could not retrieve test template."]?.length === 1 ? (
          <span>{error?.response?.data?.message?.["Could not retrieve test template."][0]}</span>
        ) : (
          <ul>
            {error?.response?.data?.message?.["Could not retrieve test template."]?.map(
              (error: string, index: number) => {
                return <li key={index}>{error}</li>;
              }
            )}
          </ul>
        );

      return Promise.reject(errorMessages || "Could not retrieve test template.");
    } else {
      return Promise.reject("Could not retrieve test template.");
    }
  }
}
