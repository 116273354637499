import { format, parseISO } from "date-fns"
import * as Locales from "date-fns/locale";

export const ConvertDateToUTC = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

export const StandardDate = (date) => {
  if (!date) return;

  const browserLocale = navigator.language.replace(/-/g, "");

  return format(parseISO(date), "MM/dd/yyyy", {
    locale: Locales[browserLocale],
  });
};

export const StandardDateTime = (dateTime) => {
  if (!dateTime) return;

  const browserLocale = navigator.language.replace(/-/g, "");
  return format(parseISO(dateTime), "MM/dd/yyyy hh:mm a", {
    locale: Locales[browserLocale],
  });
};