import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "utils/MuiWrapper/components";
import { fetchPONumbers } from "api/bom";
import { useDebounce } from "utils/helpers";
import { useReceivingPageState } from ".";
import { PrintModal } from "./PrintModal";
import { BOMItem } from "types/BOM";

export default function TopCard({ handleLocationSave, bulkPatchReceiving, allResults }) {
  const { searchString, setSearchString } = useReceivingPageState();
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [location, setLocation] = useState<string>("Receiving Area");

  // search value
  const [searchValue, setSearchValue] = useState<string>("");
  // search results
  const [options, setOptions] = useState<string[]>([]);
  // searching status
  const [isSearching, setIsSearching] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const { selectedRowsData: selectedRowsData } = useReceivingPageState((state) => state);

  useEffect(() => {
    // endpoint expects at least 3 characters for search criteria
    if (debouncedSearchValue.length >= 3) {
      setIsSearching(true);
      fetchPONumbers(debouncedSearchValue).then(
        (data) => {
          setOptions(data);
          setIsSearching(false);
        },
        () => {
          setOptions([]);
          setIsSearching(false);
        }
      );
    } else {
      setOptions([]);
      setIsSearching(false);
    }
  }, [debouncedSearchValue]);

  const handleSelectedNumber = (_, value: string | null) => {
    if (!value) return;

    setSearchString(value);
  };

  const updateSearchValue = (_, value: string, reason) => {
    if (reason === "clear") {
      setSearchString("");
    }

    setSearchValue(value);
  };

  const handleLocationChange = (e) => setLocation(e.target.value);

  const onLocationClick = () => {
    handleLocationSave(location);
    setLocation("");
  };

  const handleConfirmReceiveAll = () => setIsConfirmOpen(true);

  const handleReceiveAll = () => {
    const remaining = allResults?.reduce((accumulator, current: BOMItem) => {
      accumulator[current.id] = current.remaining;
      return accumulator;
    }, {});

    bulkPatchReceiving({
      bom_item_ids: remaining,
      location: location,
    });

    setIsConfirmOpen(false);
  };

  const handleConfirmClose = () => setIsConfirmOpen(false);

  const openPrint = () => {
    setIsPrintOpen(true);
  };

  return (
    <Card style={{ margin: "1% 0 1% 0" }}>
      <Dialog
        open={isConfirmOpen}
        onClose={handleConfirmClose}
        onKeyUp={(e) => {
          if (e.key === "Enter") handleReceiveAll();
        }}
      >
        <DialogTitle>Receive All</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            sx={{ marginTop: 5 }}
            label="Location"
            value={location}
            onChange={handleLocationChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={!location.trim()} onClick={handleReceiveAll}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <CardContent>
        <Grid container>
          <Grid item md={6}>
            <Stack direction="row" spacing={3} alignItems="center">
              <Typography variant="body1">Filter by PO Number:</Typography>
              <Autocomplete
                options={options}
                noOptionsText="No results match"
                filterOptions={(x) => x}
                loading={isSearching}
                value={searchString}
                onChange={handleSelectedNumber}
                inputValue={searchValue}
                onInputChange={updateSearchValue}
                blurOnSelect={true}
                renderInput={(params) => <TextField {...params} sx={{ width: 300 }} />}
                sx={{ input: { height: 8 } }}
              />
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-end">
              <Button disabled={selectedRowsData.length == 0} onClick={openPrint}>
                Print Label
              </Button>
              <Button color="secondary" onClick={handleConfirmReceiveAll} disabled={!allResults || !searchString}>
                Receive All
              </Button>
              <Typography variant="body1">Location:</Typography>
              <TextField sx={{ width: 300 }} name="location" value={location} onChange={handleLocationChange} />
              <Button onClick={onLocationClick}>Save</Button>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <PrintModal isOpen={isPrintOpen} setIsOpen={setIsPrintOpen}></PrintModal>
    </Card>
  );
}
