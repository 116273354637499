import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Divider, IconButton, Toolbar, Typography, Box } from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import { useBOMEntities } from "api/bom";
import { useTemplateVariables } from "api/template";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useGetComponentAttributes } from "api/component";

type BomVarGridItem = {
  name;
  variable_value;
};

export const BomVars = ({ isOpen, setIsOpen }) => {
  const { bomId } = useParams();
  const { data: templateVars } = useTemplateVariables();
  const { data: componentAttributes } = useGetComponentAttributes();
  const { data: bomEntities } = useBOMEntities(bomId || "", 1, 0);
  const [gridData, setGridData] = useState<BomVarGridItem[]>([]);
  const columns: ColDef[] = [
    { field: "name", headerName: "Name", minWidth: 350,},
    { field: "variable_value", headerName: "Value", minWidth: 245,},
  ];

  useEffect(() => {
    if (bomEntities && bomEntities["bom_variables"] && templateVars && componentAttributes) {
      setGridData(
        bomEntities["bom_variables"].map((element) => {
          let bomVariable: {
            name;
          };
          if (element.type === "template") {
            bomVariable = templateVars.results.find((x) => x.id == element.variable_id);
          } else {
            bomVariable = componentAttributes.find((x) => x.id == element.variable_id);
            if(bomVariable['speed'] &&  bomVariable['sfp_type'] && bomVariable['media_type']) {
              bomVariable.name = `Network Port ${bomVariable['media_type']} ${bomVariable['sfp_type']} ${bomVariable['speed']}`
            }
          }

          const newElement = { ...element, name: bomVariable ? bomVariable.name : "" };
          return newElement;
        })
      );
    }
  }, [bomEntities, templateVars, componentAttributes]);

  const handleClose = () => setIsOpen(false);

  const handleCancel = () => {
    handleClose();
  };

  const handleOnGridReady = (e) => {
    const columnState = {
      state: [
        {
          colId: 'name',
          sort: 'asc'
        }
      ]
    }
    e.columnApi.applyColumnState(columnState);
  }

  return (
    <>
      <Dialog open={isOpen} maxWidth="sm">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 18 }}>BOM Variables</Typography>
          <IconButton edge="start" onClick={handleCancel}>
            <Close />
          </IconButton>
        </Toolbar>
        <Box sx={{ width: "595px", height: "45em" }} className="ag-theme-alpine-dark ag-theme-custom">
          <AgGridReact rowData={gridData} columnDefs={columns} onGridReady={handleOnGridReady} />
        </Box>
        <Divider />
      </Dialog>
    </>
  );
};
