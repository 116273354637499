import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Stack, Typography } from "utils/MuiWrapper/components";
import { ProjectInfo } from "./ProjectInfo";
import { Templates } from "./Templates";
import { ProjectBOMs } from "./ProjectBOMs";
import { useProject } from "api/project";
import { APPROVED_PROJECT } from "utils/constants";
import { ProjectTeam } from "./ProjectTeam";
import { BudgetPane } from "./BudgetPane";

export const ProjectDetailViewPage = () => {
  const { projectId } = useParams();
  const { data: project } = useProject(projectId);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={2} mr={1}>
        <Typography variant="h1">{project?.name}</Typography>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={3} md={12} spacing={4}>
          <Stack spacing={4}>
            <ProjectInfo />

            {project?.id && <BudgetPane projectId={project?.id} />}

            <ProjectTeam />
          </Stack>
        </Grid>
        {project?.status !== APPROVED_PROJECT && (
          <Grid item lg={4} xs={12}>
            <Templates projectId={projectId} />
          </Grid>
        )}
        <Grid item lg={project?.status !== APPROVED_PROJECT ? 5 : 9} xs={12}>
          <ProjectBOMs />
        </Grid>
      </Grid>
    </>
  );
};
