import { Card, CardContent, Typography, TextField, Stack } from "utils/MuiWrapper/components";

export const TopCard = ({ poNumberSearch, handlePoSearchChange, rfpNumberSearch, handleRFPSearchChange }) => {
  return (
    <Card style={{ margin: "1% 0 1% 0" }}>
      <CardContent sx={{ marginTop: 2 }}>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="body1">Filter by PO Number:</Typography>
          <TextField
            value={poNumberSearch}
            onChange={handlePoSearchChange}
            autoComplete="false"
            autoCapitalize="false"
            autoCorrect="false"
          />
          <Typography variant="body1">Filter by RFP Number:</Typography>
          <TextField
            value={rfpNumberSearch}
            onChange={handleRFPSearchChange}
            autoComplete="false"
            autoCapitalize="false"
            autoCorrect="false"
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
