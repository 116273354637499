import { create } from "zustand";

type ProjectType = {
  name: "";
  sf_id: "";
  start_date: "";
  end_date: "";
  capex_code: "";
};

type ProjectInfoType = {
  projectInfo: ProjectType;
  setProjectInfo: (x) => void;
};

export const useProjectInfoState = create<ProjectInfoType>((set) => ({
  projectInfo: {
    name: "",
    sf_id: "",
    start_date: "",
    end_date: "",
    capex_code: "",
  },
  setProjectInfo: (item) => {
    set((state) => ({
      projectInfo: { ...state.projectInfo, [item.key]: item.value },
    }));
  },
}));
