import { useQuery } from "@tanstack/react-query";
import { getRequest, createRequest, updateRequest, deleteRequest } from "../http/axiosClient";
import { ScalerMapping } from "types/TemplateScaler";
import { formatError, patchRequest } from "http/client";

export async function addLocationToProject(projectId?: string, location?: string) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/locations`, {
      name: location,
    });
    return res.data;
  } catch (error) {
    return formatError("Could not add location", error);
  }
}

export async function getLocationsForProject(projectId?: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}/locations
    `);
    return res.data;
  } catch (error) {
    return formatError("Could not get locations", error);
  }
}

export const useProjectLocations = (projectId?: string) => {
  return useQuery({
    queryKey: ["project-locations", projectId],
    queryFn: () => getLocationsForProject(projectId),
    retry: false,
  });
};

export async function deleteLocationFromProject(projectId?: string, locationId?: string) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/locations/${locationId}`);
    return res.data;
  } catch (error) {
    return formatError("Could not delete location", error);
  }
}

export async function updateLocationInProject(projectId?: string, locationId?: string, name?: string) {
  try {
    const res = await updateRequest(
      `/v1/api/projects/${projectId}/locations/${locationId}
    `,
      { name }
    );
    return res.data;
  } catch (error) {
    return formatError("Could not update location", error);
  }
}

export async function getScalersForProjectDC(projectId: string, datacenterId: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}/datacenters/${datacenterId}/scalers
    `);
    return res.data;
  } catch (error) {
    return formatError("Could not get scaler values for the datacenter", error);
  }
}

export const useGetScalersForProjectDC = (projectId: string, datacenterId: string) => {
  return useQuery({
    queryKey: ["project-dc-scalers", projectId, datacenterId],
    queryFn: () => getScalersForProjectDC(projectId, datacenterId),
    retry: false,
  });
};

export async function createScalerMapping(projectId: string, data: ScalerMapping) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/scalers`, data);
    return res.data;
  } catch (error) {
    return formatError("Could not create scaler mapping", error);
  }
}

export async function patchScalerMapping(projectId: string, scalerId: string, data: ScalerMapping) {
  try {
    const res = await patchRequest(`/v1/api/projects/${projectId}/scalers/${scalerId}`, data);
    return res.data;
  } catch (error) {
    return formatError("Could not edit scaler", error);
  }
}

export async function deleteScalerMapping(projectId: string, scalerId: string) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/scalers/${scalerId}`);
    return res.data;
  } catch (error) {
    return formatError("Could not delete scaler", error);
  }
}
