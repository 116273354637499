import { StandardDate } from "./dates";
import { useUserLookupState } from "utils/GlobalStores/userStore";

export const PercentageRenderer = (colAPI) => {
  if (colAPI.value === undefined || colAPI.value === null) return "";
  return `${colAPI.value}%`;
};

export const NumberRenderer = (colAPI) => {
  if (colAPI.value === undefined || colAPI.value === null) return "";
  return colAPI.value.toLocaleString();
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const PriceRenderer = (colAPI) => {
  if (colAPI.value === undefined || colAPI.value === null) return "";
  return formatter.format(colAPI.value);
};

export const DateRenderer = (props) => {
  if (props.value === undefined || props.value === null) return "";
  return StandardDate(props.value);
};

export const UserRenderer = (props) => {
  const { userLookup } = useUserLookupState((state) => state);
  return props.value === undefined ? "" : (userLookup && userLookup[props.value]) ?? "";
}
