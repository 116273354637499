import { useMutation } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import { updateBOMItemById } from "api/bom";
import { getReceivingBOMItems } from "api/receiving";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { DateEditor } from "components/BOMView/DateEditor";
import { useCallback, useMemo, useRef, useState } from "react";
import { BomItemType } from "types/BOM";
import { DateRenderer, NumberRenderer, PercentageRenderer, PriceRenderer } from "utils/AgGridRenderers";
import { Typography } from "utils/MuiWrapper/components";
import { BOM_ITEM_STATUS_OPTIONS } from "utils/constants";
import { BomStatus } from "utils/enums";

export const TechReviewGrid = () => {
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [totalResults, setTotalResults] = useState(0);
  const gridRef = useRef<AgGridReact | null>(null);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "90vh", marginTop: 20 }), []);

  const dataSource = {
    rowCount: undefined,
    getRows: async (params) => {
      try {
        gridRef?.current?.api.showLoadingOverlay();
        const res = await getReceivingBOMItems({
          limit: 100,
          offset: params.startRow,
          order_by: "manufacturer",
          status: BomStatus.ReadyForTechReview,
          manager: true,
        });

        const { results, total_results } = res;
        setTotalResults(total_results);
        params.successCallback(results, total_results);
      } catch (error) {
        console.error(error);
        setAlert({
          type: "error",
          message: `${error instanceof Error ? error : "Could not get BOM items."}`,
        });
      } finally {
        gridRef?.current?.api?.hideOverlay();
      }
    },
  };

  const onGridReady = useCallback(
    (params) => {
      params.api.setDatasource(dataSource);
    },
    [totalResults]
  );

  const [columnDefs] = useState([
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: BOM_ITEM_STATUS_OPTIONS,
      },
    },
    {
      headerName: "Date Required",
      editable: true,
      field: "date_required",
      cellEditor: DateEditor,
      cellRenderer: DateRenderer,
    },
    {
      headerName: "RFP Number",
      field: "rfp_number",
      editable: true,
    },
    {
      headerName: "Quote Ref",
      field: "quote_ref",
      editable: true,
    },
    { field: "price", headerName: "Price", cellRenderer: PriceRenderer },
    { field: "discount", headerName: "Discount", cellRenderer: PercentageRenderer },
    {
      headerName: "Quantity",
      field: "qty",
      cellRenderer: NumberRenderer,
    },
    { field: "supplier", headerName: "Supplier" },
    { field: "supplier_part_number", headerName: "Supplier Part No" },
    { field: "manufacturer", headerName: "Manufacturer" },
    { field: "manufacturer_part_number", headerName: "Manufacturer Part No" },
    { field: "description", headerName: "Description" },
    { field: "net_cost", headerName: "Net Cost", cellRenderer: PriceRenderer },
    {
      field: "total_cost",
      headerName: "Total Cost",
      cellRenderer: PriceRenderer,
    },
    {
      field: "total_monthly",
      headerName: "Total Monthly",
      cellRenderer: PriceRenderer,
    },
    {
      headerName: "Quantity Received",
      field: "qty_rx",
    },
  ]);

  const handleCellChanged = (params) => {
    const value = params?.colDef?.field === "discount" ? parseInt(params?.newValue) : params?.newValue;
    const body = { [params?.colDef?.field]: value };
    updateBOMItem({ bomId: params?.data?.bom_id, bomItemId: params?.data?.id, body });
  };

  const { mutate: updateBOMItem } = useMutation({
    mutationFn: (data: BomItemType) => updateBOMItemById(data),
    onSuccess: () => {
      //Refresh data
      gridRef?.current?.api?.setDatasource(dataSource);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
      //Refresh data so erroneous changes don't remain
      gridRef?.current?.api?.setDatasource(dataSource);
    },
  });

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine-dark ag-theme-custom">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          onCellValueChanged={handleCellChanged}
          rowBuffer={0}
          rowModelType={"infinite"}
          cacheBlockSize={100}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={totalResults}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
        ></AgGridReact>
        <Typography variant="body2" align="right">
          Total results: {totalResults}
        </Typography>
      </div>
    </div>
  );
};
