import { BOM } from "types/BOM";
import { SAVED_BOM } from "./constants";

export const filterChildBOMs = (children: BOM[]) => {
  const templateBuckets = {};
  // put each template type in a bucket and get saved or latest version for each
  children.forEach((child) => {
    if (templateBuckets[child.project_template_id]) {
      if (
        child.status === "saved" ||
        (templateBuckets[child.project_template_id].status !== "saved" &&
          parseInt(child.version.substring(1)) >
            parseInt(templateBuckets[child.project_template_id].version.substring(1)))
      )
        templateBuckets[child.project_template_id] = child;
    } else {
      templateBuckets[child.project_template_id] = child;
    }
  });
  const values: BOM[] = Object.values(templateBuckets);

  // If some BOMs have been saved, only return those.
  const savedBOMs = values.filter((val) => val.status === SAVED_BOM);
  if (savedBOMs.length) {
    return savedBOMs;
  } else {
    return values;
  }
};
