import { getRequest } from "http/axiosClient";
import { FETCH_ALL_LIMIT } from "utils/constants";

export async function getAllPages(url = "", list = [], offset = 0) {
  const res = await getRequest(url, { limit: FETCH_ALL_LIMIT, offset });
  list = list.concat(res.data.results);
  if (res.data.total_results > list.length) {
    offset += FETCH_ALL_LIMIT;
    return await getAllPages(url, list, offset);
  } else {
    return { results: list, total_results: res.data.total_results };
  }
}
