import { create } from "zustand";
import { persist, StateStorage } from "zustand/middleware";
import secureLocalStorage from "react-secure-storage";
import { decodeToken } from "utils/helpers";
import { ITokenPayload } from "types/Token";

const authStorage: StateStorage = {
  getItem: (name: string): string | null => {
    const item = secureLocalStorage.getItem(name);
    return item as string | null;
  },
  setItem: (name: string, value: string): void => {
    secureLocalStorage.setItem(name, value);
  },
  removeItem: (name: string): void => {
    secureLocalStorage.removeItem(name);
  },
};

type AuthState = {
  accessToken?: string;
  userId?: string;
  setAccessToken: (value?: string) => void;
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      accessToken: undefined,
      setAccessToken: (value?: string) => {
        let decodedToken: ITokenPayload | undefined = undefined;

        if (value) {
          decodedToken = decodeToken(value);
        }

        set({ accessToken: value, userId: decodedToken?.user_id });
      },
    }),
    {
      name: "auth",
      getStorage: () => authStorage,
    }
  )
);
