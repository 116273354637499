import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Typography } from "utils/MuiWrapper/components";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "components/BOMView/BOMGrid.css";
import { APPROVED_PROJECT, BOM_COMMENT_REQUIRED, BOM_SOURCES, CHANGE_COMPONENT_FIELDS } from "utils/constants";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useMutation } from "@tanstack/react-query";
import { fetchBOMItemsById, updateBOMItemById } from "api/bom";
import { AddComponent } from "components/ComponentPartList/AddComponent";
import { useBOMViewState } from "./BOMView";
import { EditEndColDefs, InitialColumnDefs, StatusColDef } from "./ColumnDefinitions";
import CommentConfirmation from "./CommentConfirmation";
import { AxiosError } from "axios";
import { useGetUser } from "api/auth";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { CheckboxHeader } from "components/shared/CheckboxHeader";
import { BomItemType } from "types/BOM";

export const BOMGrid = ({ projectStatus }) => {
  const { bomId, numComponentsAdded, projectTemplateId } = useParams();
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { setSelectedRowIDs, isSelectAllChecked, setIsSelectAllChecked, setSelectedRowsData } = useBOMViewState(
    (state) => state
  );
  const [totalResults, setTotalResults] = useState(0);
  const [showComponents, setShowComponents] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedItemStatus, setSelectedItemStatus] = useState("");
  const [selectedItemQty, setSelectedItemQty] = useState(1);
  const [grandTotals, setGrandTotals] = useState({ total_cost: 0, total_monthly: 0 });
  const gridRef = useRef<AgGridReact | null>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "84vh" }), []);
  const [bomSource, setBomSource] = useState(BOM_SOURCES.CACHE);
  const isBOMEditable = projectStatus === APPROVED_PROJECT && bomSource !== BOM_SOURCES.CACHE && !projectTemplateId;
  const [commentConfirmOpen, setCommentConfirmOpen] = useState(false);
  const [stashedParams, setStashedParams] = useState();

  useEffect(() => {
    if (isSelectAllChecked) setIsSelectAllChecked(false);
  }, [bomId]);

  const dataSource = {
    rowCount: undefined,
    getRows: async (params) => {
      try {
        gridRef?.current?.api.showLoadingOverlay();
        const { results, total_results, totals, source } = await fetchBOMItemsById(bomId || "", {
          limit: 100,
          offset: params.startRow,
          order_by: "manufacturer",
        });
        setBomSource(source);
        setGrandTotals({ total_cost: totals.total_cost, total_monthly: totals.total_monthly_cost });
        setTotalResults(total_results);
        params.successCallback(results, total_results);
        restoreColumnState();
      } catch (error) {
        console.log("get data error: ", error);
        if (error instanceof AxiosError) {
          setAlert({
            type: "error",
            message: `Could not get BOM data. ${error?.response?.data?.detail || ""}`,
          });
        } else setAlert({ type: "error", message: "Could not get BOM data." });
      } finally {
        gridRef?.current?.api?.hideOverlay();
      }
    },
  };

  useEffect(() => {
    gridRef?.current?.api?.setDatasource(dataSource);
  }, [bomId, numComponentsAdded]);

  const { mutate: updateBOMItem } = useMutation({
    mutationFn: (data: BomItemType) => updateBOMItemById(data),
    onSuccess: () => {
      //Refresh data
      gridRef?.current?.api?.setDatasource(dataSource);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
      //Refresh data so erroneous changes don't remain
      gridRef?.current?.api?.setDatasource(dataSource);
    },
  });

  const onSelectAll = (event) => {
    setIsSelectAllChecked(event.target.checked);

    gridRef?.current?.api?.forEachNode(function (node) {
      // Setting 3rd arg to true so that UI won't lock up when many rows displayed
      node.setSelected(event.target.checked, false, true);
    });
    // Triggering selection changed after looping through each node so UI won't lock up
    handleSelectionChanged(gridRef.current);
  };

  useEffect(() => {
    if (gridRef) restoreColumnState();
  }, [isSelectAllChecked]);

  const getCheckBoxColumn = () => {
    return {
      checkboxSelection: true,
      lockPosition: true,
      minWidth: 50,
      resizable: false,
      headerComponent: CheckboxHeader,
      headerComponentParams: {
        onSelectAll,
        isSelectAllChecked,
      },
    };
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      lockVisible: true,
      lockPinned: true,
    };
  }, []);

  const onGridReady = useCallback(
    (params) => {
      params.api.setDatasource(dataSource);
    },
    [bomId, totalResults]
  );

  const onCellValueChanged = (params, comment?: string) => {
    const body = { [params?.colDef?.field]: params?.newValue };
    if ("qty" in body) body["qty"] = Number(body["qty"]);

    if (comment) {
      body["comments"] = comment;
    }
    updateBOMItem({ bomId: bomId || "", bomItemId: params?.data?.id, body });
    setStashedParams(undefined);
  };

  const handleCellDblClicked = (colAPI) => {
    //IMPORTANT: Only approved bom can be edited
    if (!isBOMEditable) return;

    if (CHANGE_COMPONENT_FIELDS[colAPI.colDef.field]) {
      setSelectedItemId(colAPI?.data?.id);
      setSelectedItemStatus(colAPI?.data?.status);
      setSelectedItemQty(colAPI?.data.qty);
      setShowComponents(true);
    }
  };

  const handleSelectionChanged = (gridAPI) => {
    const selectedIDs = gridAPI?.api?.getSelectedRows().map((row) => row.id);
    setSelectedRowIDs(selectedIDs);

    const selectedRows = gridAPI?.api?.getSelectedRows().map((row) => row);
    setSelectedRowsData(selectedRows);
    restoreColumnState();
  };

  const handleClose = (newValue?: string) => {
    setCommentConfirmOpen(false);
    if (newValue && newValue.length > 0 && stashedParams) {
      onCellValueChanged(stashedParams, newValue);
    } else if (stashedParams) {
      setAlert({
        type: "error",
        message: `The BOM item could not be updated. A comment must be provided for these changes.`,
      });
      setStashedParams(undefined);
    }
  };

  const handleOpen = (params) => {
    if (BOM_COMMENT_REQUIRED.includes(params.data.status)) {
      setStashedParams(params);
      setCommentConfirmOpen(true);
    } else {
      onCellValueChanged(params);
    }
  };

  function handleExportCSV(): void {
    gridRef?.current?.api?.exportDataAsCsv();
  }

  const saveColumnState = useCallback(
    (params) => {
      if (["uiColumnResized", "uiColumnMoved"].indexOf(params?.source) < 0) return;

      const columnState = JSON.stringify(gridRef?.current?.columnApi.getColumnState());
      if (user) localStorage.setItem(`userID:${user.id}-bomGridColumnState`, columnState);
    },
    [gridRef]
  );

  const restoreColumnState = useCallback(() => {
    if (!user) return;
    const userColumnState = localStorage.getItem(`userID:${user.id}-bomGridColumnState`);
    if (userColumnState) {
      const columnState = JSON.parse(userColumnState);
      gridRef?.current?.columnApi?.applyColumnState({ state: columnState, applyOrder: true });
    }
  }, [gridRef]);

  const resetColumnState = useCallback(() => {
    if (!user) return;
    gridRef?.current?.columnApi.resetColumnState();
    const columnState = JSON.stringify(gridRef?.current?.columnApi.getColumnState());
    localStorage.setItem(`userID:${user.id}-bomGridColumnState`, columnState);
  }, [gridRef]);

  const handleColumnResized = (params) => {
    if (params?.source === "gridOptionsChanged") restoreColumnState();
    saveColumnState(params);
  };

  return (
    <>
      {selectedItemId && (
        <AddComponent
          action="swap"
          itemStatus={selectedItemStatus}
          itemId={selectedItemId}
          isOpen={showComponents}
          setIsOpen={setShowComponents}
          qty={selectedItemQty}
        />
      )}
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine-dark ag-theme-custom">
          <AgGridReact
            ref={gridRef}
            onCellValueChanged={handleOpen}
            onCellDoubleClicked={handleCellDblClicked}
            defaultColDef={defaultColDef}
            columnDefs={
              bomSource === BOM_SOURCES.CACHE
                ? InitialColumnDefs(isBOMEditable)
                : isBOMEditable
                ? [
                    getCheckBoxColumn(),
                    ...StatusColDef(isBOMEditable),
                    ...InitialColumnDefs(isBOMEditable),
                    ...EditEndColDefs(isBOMEditable),
                  ]
                : [...InitialColumnDefs(isBOMEditable)]
            }
            maintainColumnOrder={true}
            onColumnResized={handleColumnResized}
            onColumnMoved={saveColumnState}
            suppressColumnMoveAnimation={true}
            onSelectionChanged={handleSelectionChanged}
            rowBuffer={0}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            rowModelType={"infinite"}
            cacheBlockSize={100}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={totalResults}
            maxBlocksInCache={10}
            pinnedTopRowData={[grandTotals]}
            onGridReady={onGridReady}
          ></AgGridReact>
          <Typography variant="body2" align="right">
            Total results: {totalResults}
          </Typography>
          <Button onClick={handleExportCSV}>Export CSV</Button>
          <Button onClick={resetColumnState} sx={{ marginLeft: 2 }}>
            Reset Columns
          </Button>
        </div>
      </div>
      <CommentConfirmation id="comment-dialog" open={commentConfirmOpen} onClose={handleClose} />
    </>
  );
};
