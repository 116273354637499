import { useEffect } from "react";
import { Table, TableBody, TableRow, TextField } from "utils/MuiWrapper/components";

import { StyledTableCell } from "./ProjectInfo";
import { useProjectInfoState } from "./ProjectInfoState";
import { NorthStarDesktopDatePicker } from "components/DesktopDatePicker";
import ProjectStatusLabel from "components/ProjectsPage/ProjectStatusLabel";

export const EditTable = ({ project }) => {
  const { projectInfo, setProjectInfo } = useProjectInfoState((state) => state);

  useEffect(() => {
    const name = project.name === undefined ? "" : project.name;
    const sf_id = project.sf_id === undefined ? "" : project.sf_id;
    const start_date = project.start_date === undefined ? null : project.start_date;
    const end_date = project.end_date === undefined ? null : project.end_date;
    const capex_code = project.capex_code === undefined ? "" : project.capex_code;

    setProjectInfo({ key: "name", value: name });
    setProjectInfo({ key: "sf_id", value: sf_id });
    setProjectInfo({ key: "start_date", value: start_date });
    setProjectInfo({ key: "end_date", value: end_date });
    setProjectInfo({ key: "capex_code", value: capex_code });
  }, [project, setProjectInfo]);

  const handleChange = (e) => {
    setProjectInfo({ key: e.target.name, value: e.target.value });
  };

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <StyledTableCell>Name</StyledTableCell>
          <StyledTableCell>
            <TextField fullWidth name="name" value={projectInfo.name} onChange={handleChange} />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>SF ID</StyledTableCell>
          <StyledTableCell>
            <TextField fullWidth name="sf_id" value={projectInfo?.sf_id} onChange={handleChange} />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Start Date</StyledTableCell>
          <StyledTableCell>
            <NorthStarDesktopDatePicker
              onChange={(value) => setProjectInfo({ key: "start_date", value })}
              value={projectInfo?.start_date}
              renderInput={(params) => <TextField name="start_date" fullWidth {...params} />}
            />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>End Date</StyledTableCell>
          <StyledTableCell>
            <NorthStarDesktopDatePicker
              onChange={(value) => setProjectInfo({ key: "end_date", value })}
              value={projectInfo?.end_date}
              renderInput={(params) => <TextField name="end_date" fullWidth {...params} />}
            />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Capex Code</StyledTableCell>
          <StyledTableCell>
            <TextField name="capex_code" onChange={handleChange} fullWidth value={projectInfo?.capex_code} />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Status</StyledTableCell>
          <StyledTableCell>
            <ProjectStatusLabel projectStatus={project?.status} />
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
