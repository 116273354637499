import React from "react";
import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import { useNavigate } from "react-router-dom";
import SearchBar from "../shared/SearchBar";
import TemplatesTable from "../shared/TemplatesTable";
import { createTemplate, useTemplates } from "api/template";
import { useDetailPaneStateStore } from "components/Library/DetailPaneState";
import { QueryParamsType } from "types/Queries";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { useLibraryTabStore } from "components/Library/TabState";

const Templates = ({ showActions = false }) => {
  const { searchTerm, updateSearchTerm, limit, updateLimit, offset, updateOffset } = useLibraryTabStore(
    (state) => state
  );
  const [params, setParams] = useState<QueryParamsType>({ limit: limit, offset: offset, quick_search: searchTerm });
  const { data: templates } = useTemplates(params);
  const [show, setShow] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const updateDetailType = useDetailPaneStateStore((state) => state.updateType);
  const updateDetailId = useDetailPaneStateStore((state) => state.updateId);

  useEffect(() => {
    updateDetailId("");
    updateDetailType("Template");
  }, []);

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const searchTemplates = (searchString: string) => {
    setParams({ ...params, quick_search: searchString, offset: 0 });
    updateSearchTerm(searchString);
    updateOffset(0);
  };

  const { mutate: createTemplateMutation } = useMutation({
    mutationFn: () => createTemplate({ name: templateName }),
    onSuccess: (data) => {
      handleClose();
      navigate(`/template-builder/${data.id}`, { state: { data } });
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const handleCreate = () => {
    createTemplateMutation();
  };

  const onSelectRow = (template) => {
    updateDetailId(template.id);
    updateDetailType("Template");
  };

  const handleSetOffset = (offset: number) => {
    const newOffset = offset * (params.limit ?? 1);
    setParams({ ...params, offset: newOffset });
    updateOffset(newOffset);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setParams({ ...params, limit: value });
    updateLimit(value);
  };

  return (
    <>
      <SearchBar delegateSearch={searchTemplates} handleShow={handleShow} searchTerm={searchTerm} />
      <TemplatesTable
        templates={templates}
        onSelectRow={onSelectRow}
        offset={(params.offset ?? 0) / (params.limit ?? 1)}
        setOffset={handleSetOffset}
        showActions={showActions}
        setRows={handleChangeRowsPerPage}
        limit={params?.limit}
        rowsPerPageOptions={[10, 50, 100]}
      />
      <Dialog
        open={show}
        onClose={handleClose}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            handleCreate();
          }
        }}
      >
        <DialogContent dividers>
          <DialogTitle>
            Create Template
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
        </DialogContent>
        <DialogContent>
          <TextField fullWidth label="Template Name" autoComplete="off" autoFocus onChange={handleTemplateNameChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button color="primary" onClick={handleCreate}>
            Create & Add Fields
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Templates;
