import { useParams } from "react-router-dom";
import { Typography } from "utils/MuiWrapper/components";

import { DATACENTER_TEMPLATE } from "utils/constants";
import { useProject } from "api/project";
import { ProjectTemplate } from "types/ProjectTemplate";
import { useEffect, useState } from "react";
import { useBOMProject } from "api/bom";
import { BOM } from "types/BOM";
import { DataCenter } from "./DataCenter";
import { useScalerStore } from "./ScalerState";

export const DataCenters = () => {
  const { setStandardBOMs } = useScalerStore((state) => state);
  const { projectId } = useParams();
  const { data: project } = useProject(projectId);
  const { data: projectBOMs } = useBOMProject(projectId);

  const [filteredTemplates, setFilteredTemplates] = useState([]);

  useEffect(() => {
    if (project?.project_templates) {
      setFilteredTemplates(
        project.project_templates.filter((template: ProjectTemplate) => template.type === DATACENTER_TEMPLATE)
      );
    }
  }, [project]);

  useEffect(() => {
    if (projectBOMs) {
      const lookup = project?.project_templates
        ?.filter((template: ProjectTemplate) => template.type !== DATACENTER_TEMPLATE)
        ?.reduce((accumulator, curr: ProjectTemplate) => {
          accumulator[curr.id] = curr;
          return accumulator;
        }, {});
      const boms: BOM[] = [];
      projectBOMs?.saved_boms?.results.forEach((parent) => {
        parent?.children.forEach((child: BOM) => {
          if (lookup[child.project_template_id]) boms.push(child);
        });
      }, {});
      setStandardBOMs(boms);
    }
  }, [projectBOMs, project]);

  return (
    <>
      {filteredTemplates.length ? (
        filteredTemplates?.map((template: ProjectTemplate, index) => <DataCenter template={template} key={index} />)
      ) : (
        <Typography variant="body2" sx={{ marginTop: 10 }}>
          No data center templates have been added to the project.
        </Typography>
      )}
    </>
  );
};
