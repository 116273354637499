import React, { useEffect, useState } from "react";
import { fetchFieldCollections, createFieldCollection } from "api/template";
import { fetchComponentAttributes } from "api/component";
import Iteration from "./iteration";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";

const IterationThree = ({ templateId, templateIsPublished }) => {
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [iterationId, setIterationId] = useState("");
  const [componentAttributes, setComponentAttributes] = useState([]);

  useEffect(() => {
    if (templateId) {
      getIteration(templateId);
      getComponentAttributes();
    }
  }, []);

  const getIteration = async (templateId) => {
    try {
      const data = await fetchFieldCollections(templateId);
      const iteration = data.results.find((item) => item.type === "iteration" && item.ordinal === 3);
      if (iteration) {
        setIterationId(iteration.id);
      } else {
        createIteration();
      }
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ type: "error", message: error.message });
      } else {
        setAlert({ type: "error", message: "There was an error while getting field collections." });
      }
    }
  };

  const createIteration = async () => {
    const data = {
      name: "Iteration 3",
      ordinal: 3,
      clonable: false,
      multipliable: false,
      type: "iteration",
    };
    try {
      const fieldCollection = await createFieldCollection(templateId, data);
      setIterationId(fieldCollection.id);
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ type: "error", message: error.message });
      } else {
        setAlert({ type: "error", message: "There was an error while creating the iteration." });
      }
    }
  };

  const getComponentAttributes = async () => {
    try {
      const data = await fetchComponentAttributes();
      setComponentAttributes(
        data?.map((value) => {
          return {
            id: value.id,
            name: value.speed
              ? `Network Port - ${value.media_type} ${value["sfp_type"]} ${value["speed"]}`
              : value.name,
          };
        })
      );
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ type: "error", message: error.message });
      } else {
        setAlert({ type: "error", message: "There was an error while getting component attributes." });
      }
    }
  };

  return (
    <Iteration
      templateId={templateId}
      templateIsPublished={templateIsPublished}
      iterationId={iterationId}
      selectOptions={componentAttributes}
      sequenceId={3}
    />
  );
};

export default IterationThree;
