import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { create } from "zustand";
import { Box, Button, Paper, Stack } from "utils/MuiWrapper/components";
import { Delete as DeleteIcon } from "utils/MuiWrapper/icons";
import { IconButton, Tooltip } from "@nepgpe/components-library";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { BOMGrid } from "./BOMGrid";
import { createBOMById, deleteBOMById, useBOMItems, useBOMProject } from "api/bom";
import { useMutation } from "@tanstack/react-query";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { useProject } from "api/project";
import { AddComponent } from "components/ComponentPartList/AddComponent";
import { APPROVED_PROJECT, BOM_SOURCES, TEXT_FONT_SIZE, ARCHIVED_PROJECT } from "utils/constants";
import { GroupEdit } from "components/GroupEdit/GroupEdit";
import { AuditLog } from "./AuditLog";
import { BomVars } from "./BomVars";
import { AddBOMItem } from "./AddBOMItem/AddBOMItem";

export const BOMView = () => {
  const { bomId, projectId, projectTemplateId } = useParams();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isConfirmSaveOpen, setIsConfirmSaveOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isBOMItemAddOpen, setIsBOMItemAddOpen] = useState(false);
  const [isGroupEditOpen, setIsGroupEditOpen] = useState(false);
  const [isLogOpen, setIsLogOpen] = useState(false);
  const [isVarsOpen, setIsVarsOpen] = useState(false);
  const { selectedRowIds } = useBOMViewState((state) => state);
  const navigate = useNavigate();
  const { data: project, error } = useProject(projectId || "");
  const { data: bomItems } = useBOMItems(bomId, 1, 0);
  const { data: projectBOMData } = useBOMProject(projectId);

  useEffect(() => {
    if (error && error instanceof Error) {
      setAlert({ type: "error", message: error.message });
    }
  }, [error]);

  const projectTemplate = useCallback(() => {
    return project?.project_templates?.find((template) => template.id === projectTemplateId);
  }, [project, projectTemplateId]);

  const { mutate: saveBOM } = useMutation({
    mutationFn: () => createBOMById(projectId, projectTemplateId, projectTemplate()?.name),
    onSuccess: () => {
      navigate(`/projects/${projectId}`);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const { mutate: deleteBOM } = useMutation({
    mutationFn: () => {
      setIsConfirmOpen(false);
      return deleteBOMById(bomId);
    },
    onSuccess: () => window.close(),
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  const getProjectTemplateName = () => {
    let projectTemplateName = "";

    // If project template BOM is coming from the cache, i.e it hasn't been saved yet, get its name from the list of project templates.
    // Otherwise, get its name from the list of saved project template BOMs.
    if (bomItems?.source === "cache") {
      projectTemplateName = projectTemplate()?.name;
    } else {
      projectBOMData?.saved_boms?.results?.forEach((parentBOM) => {
        const projectTemplate = parentBOM?.children?.find((childBOM) => childBOM?.id === bomId);
        if (projectTemplate) projectTemplateName = projectTemplate?.name;
      });
    }
    return projectTemplateName;
  };

  const getParentBOMName = () => {
    let parentBOMName = "";

    // If parent BOM hasn't been saved yet, get its name from project.
    // Otherwise, get it's name from list of saved project parent BOMs.
    if (bomItems?.source === "cache") {
      parentBOMName = project?.name;
    } else {
      const parentBOM = projectBOMData?.saved_boms?.results?.find((result) => result.id === bomId);
      parentBOMName = parentBOM?.name;
    }
    return parentBOMName;
  };

  const handleSave = () => setIsConfirmSaveOpen(true);
  const handleDelete = () => setIsConfirmOpen(true);
  const handleAdd = () => setIsAddOpen(true);
  const handleAddBOMItem = () => setIsBOMItemAddOpen(true);
  const handleBOMItemAddClose = () => {
    setIsBOMItemAddOpen(false);
  };

  const showAdd = () => {
    return project?.status === APPROVED_PROJECT && !projectTemplateId && bomItems?.source !== BOM_SOURCES.CACHE;
  };

  const handleGroupEdit = () => {
    setIsGroupEditOpen(true);
  };

  const handleLog = () => {
    setIsLogOpen(true);
  };

  const handleVariable = () => {
    setIsVarsOpen(true);
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmOpen}
        title={"Are you sure you want to want to delete the BOM?"}
        setIsOpen={setIsConfirmOpen}
        confirmAction={deleteBOM}
        displayText={{ confirm: "Yes", cancel: "No" }}
      />
      <ConfirmationDialog
        isOpen={isConfirmSaveOpen}
        title={"Are you sure you want to want to save the BOM?"}
        setIsOpen={setIsConfirmSaveOpen}
        confirmAction={saveBOM}
        displayText={{ confirm: "Yes", cancel: "No" }}
      />
      <Box
        sx={{
          position: "sticky",
          top: 1,
          mb: 3,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            fontSize: TEXT_FONT_SIZE,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 4 }}>BOM Name:</Box>
            <Paper elevation={2} sx={{ padding: 1, pr: 30 }}>
              {projectTemplateId ? getProjectTemplateName() : getParentBOMName()}
            </Paper>
          </Box>
          <Box>
            {showAdd() && project && !(project.status === "hidden") && (
              <Stack direction="row">
                <Button size="medium" sx={{ mr: 5 }} onClick={handleAdd}>
                  Add Component
                </Button>
                <AddComponent action="add" isOpen={isAddOpen} setIsOpen={setIsAddOpen} />
                <Tooltip title="Add item to BOM that isn't present in components list.">
                  <Button size="medium" sx={{ mr: 5 }} onClick={handleAddBOMItem}>
                    Add BOM Item
                  </Button>
                </Tooltip>
                <AddBOMItem isOpen={isBOMItemAddOpen} handleClose={handleBOMItemAddClose} />
                <Button
                  size="medium"
                  sx={{ mr: 5 }}
                  disabled={!selectedRowIds.length || project?.status === ARCHIVED_PROJECT}
                  onClick={handleGroupEdit}
                >
                  Group Edit
                </Button>
                <GroupEdit isOpen={isGroupEditOpen} setIsOpen={setIsGroupEditOpen} />
                <Button
                  size="medium"
                  sx={{ mr: 5 }}
                  disabled={!selectedRowIds.length || selectedRowIds.length !== 1}
                  onClick={handleLog}
                >
                  Item History
                </Button>
                <AuditLog isOpen={isLogOpen} setIsOpen={setIsLogOpen} />
              </Stack>
            )}
            {projectTemplateId && (
              <>
                <Button size="medium" sx={{ mr: 5 }} onClick={handleVariable}>
                  BOM Variables
                </Button>
                <BomVars isOpen={isVarsOpen} setIsOpen={setIsVarsOpen}></BomVars>
              </>
            )}
            {bomItems?.source === BOM_SOURCES.CACHE && project && !(project.status === "hidden") && (
              <Button
                sx={{
                  px: 8,
                  mr: 5,
                }}
                size="medium"
                onClick={handleSave}
              >
                Save BOM
              </Button>
            )}
            {bomItems?.source === BOM_SOURCES.CACHE && project && !(project.status === "hidden") && (
              <IconButton onClick={handleDelete}>
                <DeleteIcon sx={{ fontSize: 30 }} />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      {project && <BOMGrid projectStatus={project?.status} />}
    </>
  );
};

type BOMViewStateType = {
  selectedRowIds: string[];
  setSelectedRowIDs: (x: []) => void;
  isSelectAllChecked: boolean;
  setIsSelectAllChecked: (x: boolean) => void;
  selectedRowsData: [];
  setSelectedRowsData: (x) => void;
};

export const useBOMViewState = create<BOMViewStateType>((set) => ({
  selectedRowIds: [],
  setSelectedRowIDs: (selectedRowIds) => set({ selectedRowIds }),
  isSelectAllChecked: false,
  setIsSelectAllChecked: (isSelectAllChecked) => set({ isSelectAllChecked }),
  selectedRowsData: [],
  setSelectedRowsData: (selectedRowsData) => set({ selectedRowsData }),
}));
