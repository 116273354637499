import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Card, Divider, Grid, Tooltip, Typography, Link } from "utils/MuiWrapper/components";
import AddIcon from "@mui/icons-material/Add";
import { APPROVED_PROJECT, ARCHIVED_PROJECT, CARD_BG_COLOR } from "utils/constants";
import { BOMScaler } from "./BOMScaler";
import { useProject } from "api/project";
import { useGetScalersForProjectDC } from "api/project-scaler";
import { AddScaler } from "./AddScaler";
import { useScalerStore } from "./ScalerState";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { hasPermission, useProjectTeamManagement } from "utils/helpers";
import { useGetUser } from "api/auth";
import { Action, Roles } from "utils/enums";
import { ScalerMapping } from "types/TemplateScaler";

export const DataCenter = ({ template }) => {
  const { projectId } = useParams();
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const { data: project } = useProject(projectId);
  const { isProjectMember } = useProjectTeamManagement(project, userId);
  const { data: dataCenterMappings } = useGetScalersForProjectDC(projectId || "", template?.template_id);
  const { setIsAddOpen, setTemplate, setEditScaler, standardBOMs } = useScalerStore((state) => state);
  const [formRows, setFormRows] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (dataCenterMappings) {
      setFormRows(
        dataCenterMappings.results
          .filter((result: ScalerMapping) => result.project_template_id === template.id)
          .map((result: ScalerMapping) => <BOMScaler template={template} rowData={result} />)
      );
    }
  }, [dataCenterMappings]);

  const handleLinkBOM = () => {
    setEditScaler({});
    setTemplate(template);
    setIsAddOpen(true);
  };

  return (
    <Card key={template.id} variant="elevation" sx={{ marginTop: 8, padding: 4, backgroundColor: CARD_BG_COLOR }}>
      <AddScaler />
      <Typography variant="h3" sx={{ marginBottom: 2 }}>
        {template.name}
      </Typography>
      <Divider sx={{ marginLeft: -2 }} />
      <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 3 }}>
        <Grid item xs={4}>
          <Typography color="text.secondary">BOM</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="text.secondary">Scaler Quantity</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="text.secondary">Locations</Typography>
        </Grid>
      </Grid>
      {formRows.map((row, index) => (
        <Fragment key={index}> {row}</Fragment>
      ))}
      {(isProjectMember || user?._embedded.role.name === Roles.Architect) &&
        hasPermission(user?.permissions?.scaler, Action.Create) && (
          <Tooltip
            title={
              !standardBOMs.length ? "Generate standard template BOMs to enable linking them to this datacenter." : null
            }
          >
            <span>
              <Link
                component="button"
                onClick={handleLinkBOM}
                disabled={!standardBOMs.length}
                sx={{
                  "&[disabled]": {
                    color: "grey",
                    cursor: "default",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  },
                }}
              >
                {project?.status !== APPROVED_PROJECT && project?.status !== ARCHIVED_PROJECT && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AddIcon fontSize="medium" sx={{ marginRight: 1 }} />
                    Link BOM
                  </Box>
                )}
              </Link>
            </span>
          </Tooltip>
        )}
    </Card>
  );
};
