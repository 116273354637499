import { StatusLabel } from "utils/MuiWrapper/components";

const ProjectStatusLabel = ({ projectStatus }) => {
  return (
    <StatusLabel
      label={projectStatus}
      color={projectStatus === "in_progress" ? "warning" : projectStatus === "archived" ? "neutral" : "success"}
    />
  );
};

export default ProjectStatusLabel;
