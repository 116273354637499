import { getRequest, createRequest, updateRequest, deleteRequest } from "../http/axiosClient";
import { useQuery } from "@tanstack/react-query";
import { QueryParamsType } from "types/Queries";
import { Template } from "types/Template";
import { ProjectTemplateSectionField } from "types/ProjectTemplateSectionField";
import { formatError } from "http/client";
import { getAllPages } from "./helper-requests";

// Fetch projects
export async function fetchProjects(queryParams?: QueryParamsType) {
  try {
    const res = await getRequest(`/v1/api/projects`, queryParams);
    return res.data;
  } catch (error) {
    return formatError(`Could not get projects`, error);
  }
}

export const useProjects = (params: QueryParamsType) => {
  return useQuery(["projects", params], () => fetchProjects(params));
};

export async function fetchAllProjects() {
  try {
    const projectList = await getAllPages("/v1/api/projects");

    return projectList?.results?.reduce((accumulator, current) => {
      accumulator[current.id] = current.name;
      return accumulator;
    }, {});
  } catch (error) {
    return formatError(`Could not get all projects`, error);
  }
}

export const useProjectLookup = (enabled = true) => {
  return useQuery({
    queryKey: ["projects-lookup"],
    queryFn: () => fetchAllProjects(),
    enabled: enabled,
  });
};

// Fetch favorite projects
export async function fetchFavoriteProjects(queryParams?: QueryParamsType) {
  try {
    const res = await getRequest(`/v1/api/projects/favorite`, queryParams);
    return res.data;
  } catch (error) {
    return formatError(`Could not get favorite projects`, error);
  }
}

export const useFavoriteProjects = (params: QueryParamsType) => {
  return useQuery(["favorite-projects", params], () => fetchFavoriteProjects(params));
};

// Fetch project by ID
export async function fetchProjectById(projectId: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}`);
    return res.data;
  } catch (error) {
    return formatError(`Could not get project data`, error);
  }
}

// Update project by ID
export async function updateProjectById(projectId, data) {
  try {
    const res = await updateRequest(`/v1/api/projects/${projectId}`, data);
    return res.data;
  } catch (error) {
    return formatError("Your changes could not be saved.", error);
  }
}

// Update project team
export async function updateProjectTeam(projectId, data) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/team`, data);
    return res.data;
  } catch (error) {
    return formatError("Your changes could not be saved.", error);
  }
}

// Delete project team member
export async function deleteProjectTeamMember(projectId, userId) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/team/${userId}`);
    return res.data;
  } catch (error) {
    return formatError(`The team member could not be removed from the project.`, error);
  }
}

// Favorite project
export async function favoriteProjectById(projectId) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/favorite`, {});
    return res.data;
  } catch (error) {
    return formatError(`The project could not be added to favorites`, error);
  }
}

// Unfavorite project
export async function unfavoriteProjectById(projectId) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/favorite`);
    return res.data;
  } catch (error) {
    return formatError(`The project could not be removed from favorites.`, error);
  }
}

export type ProjectData = Record<string, string>;

// Create project
export async function createProject(data: ProjectData) {
  try {
    const res = await createRequest(`/v1/api/projects`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to create project", error);
  }
}

// Fetch project template
export async function fetchProjectTemplate(projectId: string, projectTemplateId: string) {
  try {
    const res = await getRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}`);
    return res.data;
  } catch (error) {
    return formatError("Unable to fetch project template", error);
  }
}

// Add template to a project
export async function addTemplateToProject(projectId: string, templateId: string, data: Template) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/templates/${templateId}`, data);
    return res.data;
  } catch (error) {
    return formatError("The template could not be added to the project.", error);
  }
}

// Delete template from a project
export async function deleteProjectTemplate(projectId: string, projectTemplateId: string) {
  try {
    const res = await deleteRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}`);
    return res.data;
  } catch (error) {
    return formatError("The template could not be deleted from the project", error);
  }
}

// Fetch project template sections
export type ProjectTemplateFetchPatams = Record<string, string>;

export async function fetchProjectTemplateSections(
  projectId: string,
  projectTemplateId: string,
  params: ProjectTemplateFetchPatams
) {
  try {
    const res = await getRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections?limit=10000`,
      params
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to fetch project template sections", error);
  }
}

export type ProjectTemplateSectionData = Record<string, string>;

// Update project template section
export async function updateProjectTemplateSection(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  data: ProjectTemplateSectionData
) {
  try {
    const res = await updateRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}`,
      data
    );
    return res.data;
  } catch (error) {
    return formatError("The section could not be updated", error);
  }
}

// Delete project template section
export async function deleteProjectTemplateSection(projectId, projectTemplateId, projectSectionId) {
  try {
    const res = await deleteRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}`
    );
    return res.data;
  } catch (error) {
    return formatError("The section could not be deleted", error);
  }
}

// Update project template
export type ProjectTemplateData = Record<string, string>;

export async function updateProjectTemplate(projectId: string, projectTemplateId: string, data: ProjectTemplateData) {
  try {
    const res = await updateRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}`, data);
    return res.data;
  } catch (error) {
    return formatError("Unable to update project template", error);
  }
}

export async function createProjectTemplateSectionField(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  data: ProjectTemplateSectionField
) {
  try {
    const res = await createRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/fields`,
      data
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to create project template section field", error);
  }
}

// Delete project template section field
export async function deleteProjectTemplateSectionField(
  projectId,
  projectTemplateId,
  projectSectionId,
  projectTemplateSectionFieldId
) {
  try {
    const res = await deleteRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/fields/${projectTemplateSectionFieldId}`
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to delete field", error);
  }
}

// Update project template section field
export async function updateProjectTemplateSectionField(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  projectSectionFieldId: string,
  data: ProjectTemplateSectionField
) {
  try {
    const res = await updateRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/fields/${projectSectionFieldId}`,
      data
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to update section", error);
  }
}

/**
 * Returns a project
 */
export const useProject = (projectId?: string, enabled = true) => {
  return useQuery(["project", projectId], () => fetchProjectById(projectId || ""), { enabled: enabled });
};

export const useProjectForDetail = (enabled: boolean, projectId?: string) => {
  return useQuery(["projectDetailPane", projectId], () => fetchProjectById(projectId || ""), { enabled: enabled });
};

// Clone project template section
export async function cloneProjectTemplateSection(
  projectId: string,
  projectTemplateId: string,
  projectSectionId: string,
  data?: ProjectTemplateSectionData
) {
  try {
    const res = await createRequest(
      `/v1/api/projects/${projectId}/templates/${projectTemplateId}/sections/${projectSectionId}/clone`,
      data
    );
    return res.data;
  } catch (error) {
    return formatError("Unable to clone template section", error);
  }
}

export async function cloneTemplateToAnotherProject(
  projectId: string,
  projectTemplateId: string,
  name: string,
  targetProjectId: string
) {
  try {
    const res = await createRequest(`/v1/api/projects/${projectId}/templates/${projectTemplateId}/clone`, {
      name: name,
      target_project_id: targetProjectId,
    });
    return res.data;
  } catch (error) {
    return formatError("Unable to clone template", error);
  }
}
