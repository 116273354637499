import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Box } from "utils/MuiWrapper/components";
import { useState, forwardRef, useEffect } from "react";
import { StandardDate } from "utils/dates";
import { useReceivingPageState } from ".";
import { useGetUser } from "api/auth";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useProject } from "api/project";

export const LabelGrid = forwardRef((props, ref) => {
  const { userId } = useAuthStore();
  const { data: user } = useGetUser(userId);
  const [createdBy, setCreatedBy] = useState("");
  const { data: createdByUser } = useGetUser(createdBy, createdBy !== "");
  const [projectId, setProjectId] = useState("");
  const { data: project } = useProject(projectId, projectId !== "");
  const [groupedItems, setGroupeditems] = useState<object>({});
  const { searchString: poNumber, selectedRowsData } = useReceivingPageState((state) => state);

  useEffect(() => {
    if (selectedRowsData && selectedRowsData.length > 0) {
      if (selectedRowsData[0].user && selectedRowsData[0].user !== "SYSTEM") {
        setCreatedBy(selectedRowsData[0].user);
      }
      if (selectedRowsData[0].project_id) {
        setProjectId(selectedRowsData[0].project_id);
      }

      const groups = selectedRowsData.reduce(
        (rowGroups: object, { manufacturer, manufacturer_part_number, received }) => {
          (rowGroups[manufacturer] = rowGroups[manufacturer] || []).push({
            manufacturer_part_number: manufacturer_part_number,
            received: received,
          });
          return rowGroups;
        },
        {}
      );
      setGroupeditems(groups);
    }
  }, [JSON.stringify(selectedRowsData)]);

  return (
    <Box ref={ref}>
      {Object.entries(groupedItems).map(([key, rows]) => (
        <TableContainer sx={{ width: "300px", paddingTop: "10px" }} key={`${key}`}>
          <Table
            aria-labelledby="tableTitle"
            sx={{ backgroundColor: "white", pageBreakAfter: "always", breakInside: "avoid" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "black" }}>
                  <Box>Rec: {user?.email.split("@")[0]}</Box>
                  <Box>
                    PO: <b>{poNumber}</b>
                  </Box>
                  <Box>{project?.capex_code}</Box>
                  <Box>{key}</Box>
                </TableCell>
                <TableCell style={{ color: "black" }} align="right">
                  <Box>{StandardDate(new Date().toISOString())}</Box>
                  <br />
                  <Box>{project?.name}</Box>
                  <Box>
                    <b>{createdByUser?.name}</b>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index: number) => (
                <TableRow
                  key={`${row.manufacturer_part_number}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ color: "black" }}>{row.manufacturer_part_number}</TableCell>
                  <TableCell style={{ color: "black" }} align="right">
                    {row.received ? row.received : "1"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </Box>
  );
});
