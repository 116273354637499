import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "utils/MuiWrapper/components";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Menu } from "utils/MuiWrapper/components";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { changePassword, useGetUser } from "api/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";

export function getAvatarInitials(name: string) {
  if (name == null) return "";
  return name[0] + (name.indexOf(" ") > 0 ? name[name.indexOf(" ") + 1] : "");
}

const UserAvatar = () => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { userId, setAccessToken } = useAuthStore();
  const { data: user } = useGetUser(userId);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  useEffect(() => {
    setNewPassword("");
    setConfirmNewPassword("");
  }, [showChangePassword]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeCookie("token", { path: "/" });
    setAccessToken(undefined);
    queryClient.invalidateQueries({ queryKey: ["get_user"] });

    // Will redirect to auth page but also reset application state if user is changing
    window.location.reload();
  };
  const handleChangePassword = () => {
    if (newPassword !== confirmNewPassword) {
      setAlert({ type: "error", message: "Passwords must match." });
      return;
    }
    changePassword(user?.id, newPassword, confirmNewPassword)
      .then(() => {
        setAlert({ type: "success", message: "Password change successfully." });
        setShowChangePassword(false);
      })
      .catch((error: Error) => setAlert({ type: "error", message: error.message }));
  };

  return (
    <Box m="auto">
      <Stack direction="row" justifyContent="center">
        <Avatar sx={{ cursor: "pointer" }} onClick={handleClick}>
          {getAvatarInitials(user?.name ?? "")}
        </Avatar>
        <FormControl sx={{ ml: "13px" }} size="small">
          <InputLabel sx={{ marginTop: 3 }}>{user?.name ?? ""}</InputLabel>
        </FormControl>
        <Menu id="basic-menu" anchorEl={anchorEl} open={anchorEl !== null} onClose={handleClose}>
          <MenuItem onClick={() => setShowChangePassword(true)}>Change Password</MenuItem>
          <MenuItem onClick={() => setShowConfirmLogout(true)}>Logout</MenuItem>
        </Menu>
        <Dialog
          open={showConfirmLogout}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to logout of Northstar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirmLogout(false)}>Cancel</Button>
            <Button onClick={handleLogout} autoFocus>
              Logout
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showChangePassword}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DialogTitle>Change Password</DialogTitle>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <Stack spacing="1em">
              <TextField
                autoComplete="new-password"
                type="password"
                autoFocus
                value={newPassword}
                label="New Password"
                onChange={(e) => setNewPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleChangePassword();
                }}
              />
              <TextField
                type="password"
                autoComplete="new-password"
                label="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleChangePassword();
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowChangePassword(false)}>Cancel</Button>
            <Button onClick={handleChangePassword}>Change Password</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
};

export default UserAvatar;
