export const NULL_ADDRESS = "00000000-0000-0000-0000-000000000000";
export const NEW_CATEGORY = "new-category";
export const SELECT_OPTION = "select-option";
export const ORDER = "desc";
export const RANGE_PLACEHOLDER = "Start:End:Step";
export const ALERT_BACKGROUND_COLOR = "#1F2324";
export const CARD_BG_COLOR = "#333548";
export const ACTIVE_BORDER = "#1976d2";
export const TEXT_FONT_SIZE = "14px";
export const TEXTFIELD_BORDER_RADIUS = "10px";
export const WARNING_COLOR = "#EFC100";
export const BOM_ITEM_STATUS_OPTIONS = ["Ready For Purchase", "Hold", "Ready For Tech Review", "Donor", "Deleted"];
export const BOM_COMMENT_REQUIRED = ["Partial RX", "Delivered", "Purchased", "Ready For Purchase"];
export const PUBLISHED_TEMPLATE = "published";
export const IN_PROGRESS_BOM = "in_progress";
export const SAVED_BOM = "saved";
export const APPROVED_PROJECT = "approved";
export const ARCHIVED_PROJECT = "archived";
export const CHANGE_COMPONENT_FIELDS = {
  manufacturer: true,
  manufacturer_part_number: true,
  description: true,
};

export const BOM_SOURCES = {
  DB: "db",
  CACHE: "cache",
};
export const HELPER_TEXT = {
  SELECT: "Please select a value.",
  CATEGORY: "Please select a category.",
};
export const GROUP_EDIT_BOM_FIELDS = [
  { displayName: "Status", value: "status" },
  { displayName: "Quantity", value: "qty" },
  { displayName: "Quote Ref", value: "quote_ref" },
  { displayName: "Date Required", value: "date_required" },
  { displayName: "RFP Number", value: "rfp_number" },
  { displayName: "PO Date", value: "po_date" },
  { displayName: "Location", value: "location" },
  { displayName: "Comments", value: "comments" },
];
export const PARENT_IN_PROGRESS_MSG = "Generate and save an overall BOM to enable loading in BOM view";
export const DATACENTER_TEMPLATE = "dc";
export const DC_TEMPLATE = "dc_template";
export const ITERATION_TYPE = "iteration";
export const FETCH_ALL_LIMIT = 10000;
