import { Add as AddIcon } from "utils/MuiWrapper/icons";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "utils/MuiWrapper/components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import FieldCollection from "./FieldCollection";
import GlobalVariables from "./GlobalVariables";
import { getMaxOrdinal } from "utils/Ordinalutil";
import {
  fetchFieldCollections,
  fetchTemplate,
  createFieldCollection,
  updateTemplate,
  publishTemplate,
  testTemplate,
  useTemplateVariables,
  useSelectedTemplateVars,
} from "api/template";
import {
  createTemplateVariable,
  createGlobalTemplateVariable,
  updateTemplateVariableById,
  deleteTemplateVariableById,
  deleteGlobalVariableById,
} from "api/template";

import { ConfirmDialog } from "../shared/dialogs";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { DATACENTER_TEMPLATE, PUBLISHED_TEMPLATE, WARNING_COLOR } from "utils/constants";
import { TabPanel, a11yProps } from "components/shared/TabPanel";
import IterationTwo from "./Iterations/IterationTwo";
import IterationThree from "./Iterations/IterationThree";
import { DataCenterConfirm } from "./DataCenter/DataCenterConfirm";
import { NewFieldCollection, Template, TemplateBuilderTemplate } from "types/Template";
import { DataCenterVariables } from "./DataCenter/DataCenterVariables";
import { DataCenterSection } from "./DataCenter/DataCenterSection";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { TemplateVariable } from "types/TemplateVariable";

const templateIsPublishedMessage = "Template is published. Changes cannot be made.";

const TemplateBuilder = () => {
  const navigate = useNavigate();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { templateId } = useParams();

  // State
  const [currentTab, setCurrentTab] = useState(1);
  const [isDatacenterConfirmOpen, setIsDatacenterConfirmOpen] = useState(false);
  const [fieldCollections, setFieldCollections] = useState([]);
  const [template, setTemplate] = useState<TemplateBuilderTemplate>({
    status: "",
    name: "",
    id: "",
    type: "standard",
    created_at: "",
    updated_at: "",
    description: "",
  });
  const [maxOrdinal, setMaxOrdinal] = useState(0);
  const [selectedGlobalVariable, setSelectedGlobalVariable] = useState<TemplateVariable>();
  const [showConfirmDeleteGlobalVariableDialog, setShowConfirmDeleteGlobalVariableDialog] = useState(false);
  const [isPublishTemplateModalOpen, setIsPublishTemplateModalOpen] = useState(false);

  // Query Hooks
  const { data: variables, refetch: refetchVariables, error: variablesError } = useTemplateVariables();
  const {
    data: templateVariables,
    refetch: refetchTemplateVars,
    error: templateVarsError,
  } = useSelectedTemplateVars(templateId || "", {
    type: "standard",
  });

  const { refetch: refetchTemplate } = useQuery({
    queryKey: ["template", templateId],
    queryFn: () => fetchTemplate(templateId),
    retry: false,
    onSuccess: (data) => {
      setTemplate(data);
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });

  const { refetch: refetchFieldCollections } = useQuery({
    queryKey: ["field-collections", templateId],
    queryFn: () => fetchFieldCollections(templateId || ""),
    retry: false,
    onSuccess: (data) => {
      const filteredCollections = data.results.filter((collection) => {
        return collection.type === "collection";
      });
      setFieldCollections(filteredCollections);
      const maxOrdinal = getMaxOrdinal(filteredCollections);
      setMaxOrdinal(maxOrdinal);
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });

  // Side-effects
  useEffect(() => {
    if (template?.type === DATACENTER_TEMPLATE) setCurrentTab(0);
  }, [template?.type]);

  useEffect(() => {
    if (template?.description && template?.description?.length >= 256) {
      setAlert({ type: "warning", message: "Please enter a description that is less than 256 characters." });
    }
  }, [template?.description]);

  useEffect(() => {
    if (variablesError instanceof Error) setAlert({ type: "error", message: variablesError.message });
    if (templateVarsError instanceof Error) setAlert({ type: "error", message: templateVarsError.message });
  }, [variablesError, templateVarsError]);

  // Mutations
  const { mutate: _publishTemplate } = useMutation({
    mutationFn: (id: string) => publishTemplate(id),
    onSuccess: () => {
      refetchTemplate();
    },
    onError: (error: JSX.Element) => {
      setAlert({
        type: "error",
        message: error,
      });
    },
  });

  const { mutate: _createFieldCollection } = useMutation({
    mutationFn: (data: NewFieldCollection) => createFieldCollection(templateId || "", data),
    onSuccess: () => {
      refetchFieldCollections();
      setMaxOrdinal((prev) => prev + 1);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  const { mutate: _updateTemplate } = useMutation({
    mutationFn: (data: Template) => updateTemplate(templateId || "", data),
    onSuccess: (data) => {
      setTemplate(data);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  const { mutate: _createGlobalTemplateVariable } = useMutation({
    mutationFn: (name: string) => createGlobalTemplateVariable(name),
    onSuccess: () => {
      refetchVariables();
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  const { mutate: assignTemplateVariable } = useMutation({
    mutationFn: (variableId: string) =>
      createTemplateVariable(templateId || "", { variable_id: variableId, type: "standard" }),
    onSuccess: () => {
      refetchTemplateVars();
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });

  const { mutate: unassignTemplateVariable } = useMutation({
    mutationFn: (variableId: string) => deleteTemplateVariableById(templateId ?? "", variableId),
    onSuccess: () => {
      refetchTemplateVars();
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });

  const { mutate: _updateTemplateVariableById } = useMutation({
    mutationFn: (data: { id: string; params: { name: string } }) => updateTemplateVariableById(data.id, data.params),
    onSuccess: () => {
      refetchVariables();
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });

  const { mutate: _deleteGlobalVariableById } = useMutation({
    mutationFn: (id: string) => deleteGlobalVariableById(id),
    onSuccess: () => {
      refetchVariables();
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error.message });
    },
  });

  // Event handlers
  const handleFieldChange = (value: string) => {
    setTemplate({ ...template, name: value });
  };

  const handlePublishTemplate = () => {
    _publishTemplate(template?.id || "");
    setIsPublishTemplateModalOpen(false);
  };

  const handleConfirmPublishTemplate = () => {
    setIsPublishTemplateModalOpen(true);
  };

  const handleCreateFieldCollection = () => {
    const data = {
      name: "New Field Collection",
      ordinal: maxOrdinal + 1,
      clonable: false,
      multipliable: false,
      type: "collection",
    };
    _createFieldCollection(data);
  };

  const updateGlobalVariable = (id: string, params: { name: string }) => {
    _updateTemplateVariableById({ id, params });
  };

  const deleteGlobalVariable = (variable) => {
    setSelectedGlobalVariable(variable);
    setShowConfirmDeleteGlobalVariableDialog(true);
  };

  const handleConfirmDeleteGlobalVariableDialogClose = () => setShowConfirmDeleteGlobalVariableDialog(false);

  const handleConfirmDeleteGlobalVariable = () => {
    _deleteGlobalVariableById(selectedGlobalVariable?.id || "");
    setShowConfirmDeleteGlobalVariableDialog(false);
    setSelectedGlobalVariable(undefined);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleDatacenterClick = () => {
    if (template?.type !== DATACENTER_TEMPLATE) setIsDatacenterConfirmOpen(true);
  };

  const handleTestTemplate = () => {
    testTemplate(template?.id || "").then(
      (retVal) => {
        window.open(
          `/user-view/project/${retVal.project_id}/template/${retVal.template_id}/project-template/${retVal.project_template_id}`,
          "_blank",
          "noreferrer"
        );
      },
      (error: JSX.Element) => {
        console.log(error);
        setAlert({
          type: "error",
          message: error,
        });
      }
    );
  };

  const handleCloseEditor = () => {
    navigate("/");
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} width="100%" display="flex" justifyContent="center" alignItems="center">
        <Box
          width="98%"
          style={{
            marginBottom: "auto",
          }}
        >
          <Box p={2} display="flex" alignItems="center">
            <Box marginRight="1%">Template Name</Box>
            <Tooltip title={template?.status === "published" ? templateIsPublishedMessage : null}>
              <TextField
                id="filled-basic"
                hiddenLabel
                size="small"
                autoComplete="off"
                disabled={template?.status === "published"}
                value={template?.name}
                onChange={(e) => {
                  handleFieldChange(e.target.value);
                }}
                onBlur={(e) => {
                  _updateTemplate({ name: e.target.value });
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
              />
            </Tooltip>
            <Box marginRight="1%" marginLeft={4}>
              Description
            </Box>
            <Tooltip title={template?.status === "published" ? templateIsPublishedMessage : null}>
              <TextField
                disabled={template?.status === "published"}
                value={template?.description}
                sx={{ width: 200 }}
                onChange={(e) => {
                  setTemplate({ ...template, description: e.target.value });
                }}
                onBlur={(e) => {
                  _updateTemplate({ description: e.target.value });
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLImageElement>) => {
                  if (e.key === "Enter") {
                    (e.target as HTMLImageElement).blur();
                  }
                }}
              />
            </Tooltip>
            <ConfirmDialog
              title="Confirm"
              text="Are you sure you want to delete this global variable?"
              showConfirmDialog={showConfirmDeleteGlobalVariableDialog}
              handleConfirmDialogClose={handleConfirmDeleteGlobalVariableDialogClose}
              handleConfirmDelete={handleConfirmDeleteGlobalVariable}
            />
            {template?.status === "published" && (
              <Typography variant="h1" sx={{ ml: "10%" }}>
                {templateIsPublishedMessage}
              </Typography>
            )}
            <span style={{ marginLeft: "auto" }}>
              <Button
                variant="contained"
                disabled={!(template?.status === "published" || template?.status === "in_progress")}
                color="primary"
                size="medium"
                onClick={handleTestTemplate}
                style={{ padding: " 10px 15px" }}
              >
                Test Template
              </Button>
            </span>
            <Tooltip title={template?.status === "published" ? templateIsPublishedMessage : null}>
              <span style={{ marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  disabled={template?.status === "published"}
                  size="medium"
                  color="primary"
                  onClick={handleConfirmPublishTemplate}
                  style={{ padding: " 10px 15px" }}
                >
                  {template?.status === "published" ? "Published" : "Publish Template"}
                </Button>
                <ConfirmationDialog
                  title="Are you sure you want to publish this template?"
                  isOpen={isPublishTemplateModalOpen}
                  setIsOpen={setIsPublishTemplateModalOpen}
                  displayText={{ confirm: "Yes", cancel: "No" }}
                  confirmAction={handlePublishTemplate}
                />
              </span>
            </Tooltip>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleCloseEditor}
              style={{ marginLeft: "1em" }}
            >
              Close Editor
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1, paddingTop: 0 }}>
            <GlobalVariables
              templateId={templateId}
              templateIsPublished={template?.status === "published"}
              variables={variables}
              createGlobalVariable={_createGlobalTemplateVariable}
              updateGlobalVariable={updateGlobalVariable}
              deleteGlobalVariable={deleteGlobalVariable}
              templateVariables={templateVariables?.results}
              assignTemplateVariable={assignTemplateVariable}
              unAssignTemplateVariable={unassignTemplateVariable}
            />
          </Box>

          <Box sx={{ flexGrow: 1 }} paddingTop="3em">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={currentTab} onChange={handleChangeTab} aria-label="template builder tabs">
                <Tab label="Data Center" {...a11yProps(0)} onClick={handleDatacenterClick} />
                <Tab label="Iteration 1" {...a11yProps(1)} />
                <Tab label="Iteration 2" {...a11yProps(2)} />
                <Tab label="Iteration 3" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              {template?.status !== PUBLISHED_TEMPLATE && (
                <DataCenterConfirm
                  isOpen={isDatacenterConfirmOpen}
                  setIsOpen={setIsDatacenterConfirmOpen}
                  getTemplate={refetchTemplate}
                />
              )}
              {template?.type === DATACENTER_TEMPLATE && (
                <>
                  <DataCenterVariables variables={variables} template={template} />
                  <DataCenterSection templateStatus={template?.status} />
                </>
              )}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Box>
                {fieldCollections.map((fieldCollection, i) => {
                  return (
                    <FieldCollection
                      key={i}
                      count={i}
                      fieldCollection={fieldCollection}
                      templateId={templateId}
                      templateIsPublished={template?.status === "published"}
                      getFieldCollections={refetchFieldCollections}
                    />
                  );
                })}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  paddingBottom="2%"
                  style={{ marginBottom: "2%", marginTop: "2%" }}
                >
                  <FormControl>
                    <Button
                      startIcon={<AddIcon />}
                      disabled={template?.status === "published"}
                      onClick={handleCreateFieldCollection}
                      sx={fieldCollections?.length === 0 ? { border: `1px solid ${WARNING_COLOR}` } : {}}
                    >
                      New Field Collection
                    </Button>
                    {fieldCollections?.length === 0 && <FormHelperText>Create a field collection.</FormHelperText>}
                  </FormControl>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <IterationTwo templateId={templateId} templateIsPublished={template?.status === "published"} />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <IterationThree templateId={templateId} templateIsPublished={template?.status === "published"} />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TemplateBuilder;
