import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, TextField, Typography, useTheme } from "utils/MuiWrapper/components";
import TemplateUserViewSection from "./TemplateUserViewSection";
import { useParams } from "react-router-dom";
import { fetchProjectTemplate, fetchProjectTemplateSections, updateProjectTemplate, useProject } from "api/project";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchTemplate } from "api/template";
import GenerateBOM, { useGenerateBOMState } from "./GenerateBOM";
import DeleteProjectTemplate from "./DeleteProjectTemplate";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";
import { hasPermission, useProjectTeamManagement } from "utils/helpers";
import { Action, Roles } from "utils/enums";
import { SELECT_OPTION, TEXT_FONT_SIZE, ARCHIVED_PROJECT } from "utils/constants";

export default function TemplateUserView() {
  const theme = useTheme();
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const { templateId, projectId, projectTemplateId } = useParams();
  const { data: project } = useProject(projectId);
  const { isProjectMember } = useProjectTeamManagement(project, userId);

  const [localName, setLocalName] = useState("");
  const { isLoading, projectSections, updateProjectTemplateName, projectTemplateData } = useTemplateUserViewData({
    templateId,
    projectId,
    projectTemplateId,
    setLocalName,
  });
  const { setHasError } = useGenerateBOMState((state) => state);

  useEffect(() => {
    // if project template changes, reset error state
    setHasError(false);
  }, [projectTemplateId]);

  const collectionCounts = {};
  projectSections?.forEach((section) => {
    if (collectionCounts[section?.template_section?.name]) {
      collectionCounts[section?.template_section?.name]++;
    } else {
      collectionCounts[section?.template_section?.name] = 1;
    }
  });

  const calculateValidity = async () => {
    // TODO: invalidating query requires 2 executions to be correct.
    // await queryClient.invalidateQueries(["project-sections", projectId, projectTemplateId]);

    const res = await fetchProjectTemplateSections(projectId ?? "", projectTemplateId ?? "", { order_by: "ordinal" });

    let isValid = true;
    const sections = res.results.filter(
      (x) =>
        x.type === "collection" &&
        (x.project_template_section_fields.fixed_fields.length ||
          x.project_template_section_fields.non_fixed_fields.length)
    );
    sections.every((section) => {
      const failingFixed = section.project_template_section_fields.fixed_fields.find(
        (x) => x.selected_value === SELECT_OPTION || x.selected_value === undefined
      );
      const failingNonFixed = section.project_template_section_fields.non_fixed_fields.find(
        (x) => x.selected_value === SELECT_OPTION || x.selected_value === undefined
      );
      if (failingFixed !== undefined || failingNonFixed !== undefined) {
        isValid = false;
      }
    });
    return isValid;
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  const canCreatePermission =
    (isProjectMember || user?._embedded.role.name === Roles.Architect) &&
    hasPermission(user?.permissions?.template_user, Action.Create) && project?.status !== ARCHIVED_PROJECT;
  const canEditPermission =
    (isProjectMember || user?._embedded.role.name === Roles.Architect) &&
    hasPermission(user?.permissions?.template_user, Action.Edit) && project?.status !== ARCHIVED_PROJECT;
  const canDeletePermission =
    (isProjectMember || user?._embedded.role.name === Roles.Architect) &&
    hasPermission(user?.permissions?.template_user, Action.Delete) && project?.status !== ARCHIVED_PROJECT;

  const sortedSections = projectSections.sort((a, b) => a.template_section.ordinal - b.template_section.ordinal);

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          backgroundColor: theme.palette.secondary.dark,
          top: 1,
          pt: 4,
          mb: 7,
          zIndex: 1,
        }}
      >
        <Paper
          elevation={4}
          sx={{
            fontSize: TEXT_FONT_SIZE,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 6,
            border: "1px solid #a9a9a9",
            px: 2,
            py: 3,
          }}
        >
          <Paper elevation={0} sx={{ width: "20%", py: "2px", px: "4px" }}>
            {projectTemplateData?.name || ""}
          </Paper>
          <TextField
            id="project-template-name"
            hiddenLabel
            autoComplete="off"
            value={localName}
            onChange={(event) => setLocalName(event.target.value)}
            onBlur={(event) => updateProjectTemplateName(event.target.value)}
            sx={{ width: "30%" }}
            placeholder="Enter a project template name..."
            InputProps={{ style: { fontSize: TEXT_FONT_SIZE } }}
            disabled={!canEditPermission}
          />
          {canCreatePermission && (
              <GenerateBOM
                templateId={templateId}
                templateType={projectTemplateData?.type}
                projectId={projectId}
                projectTemplateId={projectTemplateId}
                preflightChecks={async () => await calculateValidity()}
              />
            )}
          {canDeletePermission && <DeleteProjectTemplate projectId={projectId} projectTemplateId={projectTemplateId} />}
        </Paper>
      </Box>

      <Box sx={{ minHeight: "100vh" }}>
        <Grid sx={{ padding: "0px 25px 0", fontSize: "13px" }} container>
          {sortedSections?.map((projectSection, i) =>
            projectSection.type !== "iteration" ? (
              <TemplateUserViewSection
                key={i}
                projectId={projectId}
                projectTemplateId={projectTemplateId}
                projectSection={projectSection}
                canDeleteCollections={collectionCounts[projectSection.template_section?.name] > 1}
                canCreatePermission={canCreatePermission}
                canEditPermission={canEditPermission}
                canDeletePermission={canDeletePermission}
              />
            ) : null
          )}
          {sortedSections?.map((projectSection, i) =>
            projectSection.type === "iteration" ? (
              <TemplateUserViewSection
                key={i}
                projectId={projectId}
                projectTemplateId={projectTemplateId}
                projectSection={projectSection}
                canDeleteCollections={false}
                canCreatePermission={canCreatePermission}
                canEditPermission={canEditPermission}
                canDeletePermission={canDeletePermission}
              />
            ) : null
          )}
        </Grid>
      </Box>
    </>
  );
}

const useTemplateUserViewData = ({ templateId, projectId, projectTemplateId, setLocalName }) => {
  const queryClient = useQueryClient();
  const { isLoading: isProjectTemplateLoading } = useQuery(
    ["project-template", projectId, projectTemplateId],
    async () => {
      const res = await fetchProjectTemplate(projectId, projectTemplateId);
      return res;
    },
    {
      onSuccess: (data) => {
        setLocalName(data.name);
      },
    }
  );
  const { data: projectSections, isLoading: isProjectSectionsLoading } = useQuery(
    ["project-sections", projectId, projectTemplateId],
    async () => {
      const res = await fetchProjectTemplateSections(projectId, projectTemplateId, { order_by: "ordinal" });
      return res.results;
    }
  );

  const { mutate: updateProjectTemplateName } = useMutation({
    mutationFn: async (name: string) => {
      return await updateProjectTemplate(projectId, projectTemplateId, {
        name,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["project", projectId]);
    },
  });

  const { data: projectTemplateData } = useQuery(["template", templateId], () => fetchTemplate(templateId));

  return {
    isLoading: isProjectTemplateLoading || isProjectSectionsLoading,
    projectSections,
    updateProjectTemplateName,
    projectTemplateData,
    setLocalName,
  };
};
