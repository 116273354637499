import React from "react";
import { Button, Menu, MenuItem } from "utils/MuiWrapper/components";
import { MoreVertical } from "utils/MuiWrapper/icons";
import { Project } from "types/Project";
import { CloneProjectModal } from "components/ProjectsPage/CloneProjectModal";
import { ArchiveProjectModal } from "./ArchiveProjectModal";

interface ProjectMenuOptionsType {
  project: Project;
  refreshTable: () => void;
  handleFavorites: (project) => void;
}

export function ProjectMenuOptions(props: ProjectMenuOptionsType) {
  const { project, refreshTable, handleFavorites } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isCloneModalOpen, setCloneModal] = React.useState(false);
  const [isArchiveModalOpen, setArchiveModal] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CloneProjectModal
        isCloneModalOpen={isCloneModalOpen}
        setCloneModal={setCloneModal}
        projectId={project.id}
        projectName={project.name}
        refreshTable={refreshTable}
      />

      <ArchiveProjectModal
        isArchiveModalOpen={isArchiveModalOpen}
        setArchiveModal={setArchiveModal}
        projectId={project.id}
        refreshTable={refreshTable}
      />

      <Button color="secondary" startIcon={<MoreVertical />} onClick={(event) => handleClick(event)} />

      <Menu id="basic-menu" anchorEl={anchorEl} open={anchorEl !== null} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setCloneModal(true);
          }}
        >
          Clone
        </MenuItem>

        {project.status !== "archived" && (
          <MenuItem
            onClick={() => {
              setArchiveModal(true);
            }}
          >
            Archive
          </MenuItem>
        )}
        <MenuItem onClick={() => handleFavorites(project)}>
          {project.is_favorited ? "Remove Favorite" : "Add Favorite"}
        </MenuItem>
      </Menu>
    </>
  );
}
