import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Box, CssBaseline } from "utils/MuiWrapper/components";

import { setMiniSidenav, useFortuneTellerUIContext } from "./context";

import routes, { projectSideNavRoutes } from "./routes";
import { MUIThemeProvider } from "@nepgpe/components-library";
import { TopSidenav, ProjectSidenav } from "components/Sidenav";
import { SignInPage } from "components/SignInPage";
import { AlertSnackbar } from "components/AlertSnackbar/AlertSnackbar";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser, useGetUsers } from "api/auth";
import Protected from "components/Protected";
import { useProjectLookup } from "api/project";
import { useProjectLookupState } from "utils/GlobalStores/projectStore";
import { useUserLookupState } from "utils/GlobalStores/userStore";

const drawerWidth = 260;

export default function App() {
  const [cookies] = useCookies();
  const [isAuthorized, setAuthorized] = useState(!!cookies.token);
  const [context, dispatch] = useFortuneTellerUIContext();
  const { miniSidenav, menuPinned } = context;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const userId = useAuthStore((state) => state.userId);
  const { data: user, isLoading, isError: isGetUserError } = useGetUser(userId, isAuthorized);

  const { data: projectNameLookup } = useProjectLookup(isAuthorized && !isGetUserError);
  const { updateProjectLookup } = useProjectLookupState((state) => state);
  const { data: allUsers } = useGetUsers(isAuthorized && !isGetUserError);
  const { updateUserLookup } = useUserLookupState((state) => state);

  useEffect(() => {
    if (projectNameLookup) {
      updateProjectLookup(projectNameLookup);
    }
  }, [projectNameLookup]);

  useEffect(() => {
    if (allUsers) {
      const lookup = allUsers?.results?.reduce((accumulator, current) => {
        accumulator[current?.id] = current?.name;
        return accumulator;
      }, {});
      updateUserLookup(lookup);
    }
  }, [allUsers]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      !menuPinned && setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        const element =
          route.allowList || route.blockList ? (
            <Protected
              conditional={
                user &&
                (!route.allowList || route.allowList.includes(user._embedded.role.name)) &&
                (!route.blockList || !route.blockList.includes(user._embedded.role.name))
              }
            >
              {route.component}
            </Protected>
          ) : (
            route.component
          );

        return <Route path={route.route} element={element} key={route.key} />;
      }

      return null;
    });

  if (!isAuthorized || isGetUserError) {
    return (
      <MUIThemeProvider>
        <>
          <CssBaseline />
          <SignInPage authorize={() => setAuthorized(true)} />
          <AlertSnackbar />
        </>
      </MUIThemeProvider>
    );
  }

  return !isLoading ? (
    <MUIThemeProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Routes>
          {projectSideNavRoutes.map((route) => (
            <Route
              path={route.route}
              key={route.key}
              element={
                <ProjectSidenav
                  width={drawerWidth}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              }
            />
          ))}

          <Route
            path="*"
            element={
              <TopSidenav width={drawerWidth} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />
            }
          />
        </Routes>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <AlertSnackbar />
        </Box>
      </Box>
    </MUIThemeProvider>
  ) : null;
}
