import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { cloneTemplateToAnotherProject } from "api/project";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import ModalSelect from "components/ProjectsPage/Modal/ModalSelect";
import { Project } from "types/Project";
import { ProjectTemplate } from "types/ProjectTemplate";
import { AxiosError } from "axios";

interface cloneProjectProps {
  projectTemplate: ProjectTemplate;
  isCloneModalOpen: boolean;
  setCloneModal: (state: boolean) => void;
  parentProjectId: string;
  refetchProjectQuery: () => void;
  presetProject: Project | null;
  setPresetProject: (value: Project | null) => void;
}

export function CloneToAnotherProjectModal(props: cloneProjectProps) {
  const {
    isCloneModalOpen,
    setCloneModal,
    projectTemplate,
    parentProjectId,
    refetchProjectQuery,
    presetProject,
    setPresetProject,
  } = props;
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [name, setName] = useState(`Clone of ${projectTemplate.name}`);
  const [selectedProject, selectProject] = useState<Project | null>(presetProject);

  useEffect(() => {
    selectProject(presetProject);
  }, [presetProject]);

  const toggleModal = () => {
    setCloneModal(!isCloneModalOpen);
  };

  const { mutate: clone } = useMutation({
    mutationFn: () =>
      cloneTemplateToAnotherProject(parentProjectId, projectTemplate.id, name, selectedProject?.id ?? ""),
    onSuccess: () => {
      refetchProjectQuery();
      toggleModal();
    },
    onError: (error: AxiosError) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  return (
    <Box>
      <Dialog open={isCloneModalOpen}>
        <DialogContent dividers>
          <DialogTitle marginRight="10px">
            Enter a new Template Name
            {presetProject ? "" : " and Select a Destination Project"} :
            <IconButton
              onClick={() => {
                setPresetProject(null);
                selectProject(null);
                setName("");
                toggleModal();
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        </DialogContent>
        <DialogContent>
          <Stack direction="column" spacing={4}>
            <FormControl
              sx={{
                width: "100%",
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                fullWidth
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
            </FormControl>
            {selectedProject === null ? (
              <ModalSelect selectProject={selectProject} />
            ) : (
              <>
                <Typography>Copy Template to Project: {selectedProject.name}</Typography>
                {presetProject ? null : (
                  <Button
                    onClick={() => {
                      selectProject(null);
                    }}
                  >
                    Reselect
                  </Button>
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              clone();
            }}
            sx={{
              marginLeft: "auto",
            }}
            color="primary"
            disabled={name.length > 0 && selectedProject !== null ? false : true}
          >
            Copy Template
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
