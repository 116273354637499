import * as yup from "yup";

export const componentSchema = yup.object().shape({
  manufacturer_id: yup.string().uuid().required("Manufacturer is required."),
  part_number: yup.string().required("Part Number is required."),
  part_description: yup.string().required("Part Description is required."),
  price: yup.number().min(0, "Price cannot be negative.").required("Price is required."),
  discount: yup.number().integer().min(0, "Discount cannot be negative.").required("Discount is required."),
  weight: yup.number().min(0, "Weight cannot be negative.").required("Weight is required."),
  rack_units: yup
    .number()
    .integer("Cannot have partial rack units")
    .min(0, "Weight cannot be negative.")
    .required("Rack Units is required."),
  ac_connections: yup
    .number()
    .integer("Cannot have partial AC Connections")
    .min(0, "AC Connections cannot be negative.")
    .required("AC Connections is required."),
  power_usage: yup.number().min(0, "Power Usage cannot be negative.").required("Power Usage is required."),
});
