import { useMemo } from "react";
import { Paper, Table, TableBody, TablePagination, Link } from "utils/MuiWrapper/components";
import { StandardTableHead, StyledTableCell, StyledTableRow, getComparator } from "../tableDependencies";
import { formatDistance } from "date-fns";
import { CARD_BG_COLOR } from "utils/constants";

export default function ModalTable(props) {
  const { projects, getProjects, orderBy, limit, offset, setOffset, totalResults, rowsPerPageOptions, selectProject } =
    props;

  const order = "desc";
  const rowsPerPage = limit;
  const page = offset;
  const setPage = setOffset;

  const sortedProjects = useMemo(() => {
    return projects?.slice()?.sort(getComparator(order, orderBy));
  }, [projects, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getProjects(rowsPerPage, newPage);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mb: "25px" }}>
      <Table stickyHeader aria-label="sticky table">
        <StandardTableHead numSelected={projects.length} order={order} orderBy={orderBy} rowCount={projects.length} />
        <TableBody data-testid="projects-table">
          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.sort(getComparator(order, orderBy)).slice() */}
          {sortedProjects &&
            sortedProjects.map((project, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={project.id}>
                  <StyledTableCell component="th" id={labelId} scope="row" padding="normal">
                    <Link
                      component={"button"}
                      variant="body2"
                      onClick={() => {
                        selectProject(project);
                      }}
                    >
                      {project.name}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatDistance(Date.parse(project.last_modified), new Date(), { addSuffix: true })}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        sx={{ backgroundColor: CARD_BG_COLOR }}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalResults}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}
