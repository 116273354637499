import React, { useMemo } from "react";
import { Paper, Table, TableBody, TablePagination, Box } from "utils/MuiWrapper/components";
import { useDetailPaneStateStore } from "../Library/DetailPaneState";

import { EnhancedTableHead, getComparator, StyledTableCell, StyledTableRow } from "./tableDependencies";
import { formatDistance } from "date-fns";
import { ProjectMenuOptions } from "components/ProjectsPage/ProjectMenuOptions";
import { CARD_BG_COLOR, ORDER } from "utils/constants";
import ProjectStatusLabel from "./ProjectStatusLabel";
import { useLibraryTabStore } from "components/Library/TabState";

export default function ProjectsTable(props) {
  const {
    projects,
    orderBy,
    limit,
    offset,
    params,
    setParams,
    totalResults,
    rowsPerPageOptions,
    handleFavorites,
    refreshTable,
    handleChangeRowsPerPage,
  } = props;

  const updateDetailType = useDetailPaneStateStore((state) => state.updateType);
  const updateDetailId = useDetailPaneStateStore((state) => state.updateId);
  const { updateOffset } = useLibraryTabStore((state) => state);

  const sortedProjects = useMemo(() => {
    return projects?.slice()?.sort(getComparator(ORDER, orderBy));
  }, [projects, ORDER, orderBy]);

  const handleChangePage = (event, newPage) => {
    const newOffset = newPage * params.limit;
    setParams({ ...params, offset: newOffset });
    updateDetailId("");
    updateDetailType("Project");
    updateOffset(newOffset);
  };

  const handleClick = (projectId) => {
    updateDetailId(projectId);
    updateDetailType("Project");
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mb: "25px" }}>
      <Box sx={{ maxHeight: "74vh", overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={ORDER} orderBy={orderBy} />
          <TableBody data-testid="projects-table">
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.sort(getComparator(order, orderBy)).slice() */}
            {sortedProjects &&
              sortedProjects.map((project) => {
                return (
                  <StyledTableRow
                    sx={{ cursor: "pointer" }}
                    hover
                    tabIndex={-1}
                    key={project.id}
                    onClick={() => handleClick(project.id)}
                  >
                    <StyledTableCell align="left">{project.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      <ProjectStatusLabel projectStatus={project.status} />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatDistance(Date.parse(project.last_modified), new Date(), { addSuffix: true })}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <ProjectMenuOptions
                        project={project}
                        refreshTable={refreshTable}
                        handleFavorites={(p) => handleFavorites(p)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        sx={{ backgroundColor: CARD_BG_COLOR }}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalResults}
        rowsPerPage={limit}
        page={offset / limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}
