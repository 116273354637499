import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { PUBLISHED_TEMPLATE } from "utils/constants";
import { createFieldCollection, useFetchFieldCollections, useVariablesForTemplate } from "api/template";
import Iteration from "../Iterations/iteration";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { ScalarVariable } from "types/TemplateVariable";

export const DataCenterSection = ({ templateStatus }) => {
  const { templateId } = useParams();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [iterationId, setIterationId] = useState("");
  const varsForTemplate = useVariablesForTemplate(templateId || "", { type: "dc" });
  const [prefixedVars, setPrefixedVars] = useState<ScalarVariable[]>();
  const { data: fieldCollections, refetch: refetchFieldCollections } = useFetchFieldCollections(templateId || "");

  useEffect(() => {
    if (varsForTemplate) {
      const prefixed = varsForTemplate.map((variable: ScalarVariable) => {
        return { ...variable, name: `SV: ${variable.name}` };
      });
      setPrefixedVars(prefixed);
    }
  }, [varsForTemplate]);

  const { mutate: createField } = useMutation({
    mutationFn: () =>
      createFieldCollection(templateId || "", {
        name: "Data Center",
        ordinal: 0,
        clonable: false,
        multipliable: false,
        type: "dc",
      }),
    onSuccess: () => {
      refetchFieldCollections();
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error?.message,
      });
    },
  });

  useEffect(() => {
    if (templateId && fieldCollections) {
      const dcCollection = fieldCollections?.results?.find((item) => item.type === "dc");
      if (!dcCollection) {
        createField();
      } else {
        setIterationId(dcCollection.id);
      }
    }
  }, [templateId, fieldCollections]);

  return (
    <Iteration
      templateId={templateId}
      templateIsPublished={templateStatus === PUBLISHED_TEMPLATE}
      iterationId={iterationId}
      sequenceId={0}
      selectOptions={prefixedVars}
    />
  );
};
