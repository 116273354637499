import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import { updateProjectById } from "api/project";
import { ARCHIVED_PROJECT } from "utils/constants";
import { useQueryClient } from "@tanstack/react-query";

interface archiveProjectProps {
  projectId: string;
  isArchiveModalOpen: boolean;
  setArchiveModal: (state: boolean) => void;
  refreshTable: () => void;
}

export function ArchiveProjectModal(props: archiveProjectProps) {
  const queryClient = useQueryClient();
  const { isArchiveModalOpen, setArchiveModal, projectId } = props;
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const toggleModal = () => {
    setArchiveModal(!isArchiveModalOpen);
  };

  const { mutate: archive } = useMutation({
    mutationFn: () => {
      return updateProjectById(projectId, {
        status: ARCHIVED_PROJECT,
      });
    },
    onSuccess: () => {
      toggleModal();
      props.refreshTable();
      queryClient.invalidateQueries(["projectDetailPane", projectId]);
    },
    onError: (error: Error) => {
      setAlert({ type: "error", message: error?.message });
    },
  });

  return (
    <Box>
      <Dialog open={isArchiveModalOpen}>
        <DialogContent dividers>
          <DialogTitle marginRight="10px">
            Are you sure you want to archive this project?
            <IconButton
              onClick={() => {
                toggleModal();
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              archive();
            }}
            sx={{
              marginLeft: "auto",
            }}
            color="primary"
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
