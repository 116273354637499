import React from "react";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import SearchBar from "../shared/SearchBar";
import { unfavoriteProjectById, useProjects } from "api/project";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { QueryParamsType } from "types/Queries";
import { Box, Link, Paper, Table, TableBody, TablePagination } from "utils/MuiWrapper/components";
import { EnhancedTableHead, StyledTableCell, StyledTableRow } from "./tableDependencies";
import { CARD_BG_COLOR, ORDER } from "utils/constants";
import { formatDistance } from "date-fns";
import { ProjectMenuOptions } from "./ProjectMenuOptions";
import { Project } from "types/Project";
import { useDetailPaneStateStore } from "components/Library/DetailPaneState";
import ProjectStatusLabel from "./ProjectStatusLabel";
import { useLibraryTabStore } from "components/Library/TabState";

const FAVORITES_LIMIT = 10000;

export default function FavoritesTable() {
  const queryClient = useQueryClient();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { searchTerm, updateSearchTerm, offset, updateOffset } = useLibraryTabStore((state) => state);
  const [params, setParams] = useState<QueryParamsType>({
    order_by: "updated_at",
    order_direction: "desc",
    limit: FAVORITES_LIMIT,
    offset: offset,
    quick_search: searchTerm,
  });
  const updateDetailType = useDetailPaneStateStore((state) => state.updateType);
  const updateDetailId = useDetailPaneStateStore((state) => state.updateId);
  const [favoriteProjects, setFavoriteProjects] = useState<Project[]>();
  const { data: projects } = useProjects(params);

  useEffect(() => {
    updateDetailId("");
    updateDetailType("Favorites");
  }, []);

  useEffect(() => {
    if (projects?.results) setFavoriteProjects(projects.results.filter((result) => result.is_favorited));
  }, [projects]);

  const refreshTable = () => {
    queryClient.invalidateQueries(["projects", params]);
  };

  const searchProjects = (searchString: string) => {
    setParams({ ...params, quick_search: searchString, offset: 0 });
    updateSearchTerm(searchString);
    updateOffset(0);
  };

  const handleFavorites = (project) => {
    unfavoriteProject(project.id);
  };

  const { mutate: unfavoriteProject } = useMutation({
    mutationFn: (projectId) => unfavoriteProjectById(projectId),
    onSuccess: () => {
      queryClient.invalidateQueries(["projects", params]);
    },
    onError: (error: Error) => {
      setAlert({
        type: "error",
        message: error.message,
      });
    },
  });

  return favoriteProjects ? (
    <>
      <SearchBar delegateSearch={searchProjects} noCreate={true} searchTerm={searchTerm} />
      <Paper sx={{ width: "100%", overflow: "hidden", mb: "25px" }}>
        <Box sx={{ maxHeight: "74vh", overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead order={ORDER} orderBy="last_modified" />
            <TableBody data-testid="projects-table">
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.sort(getComparator(order, orderBy)).slice() */}
              {favoriteProjects?.map((project, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow sx={{ cursor: "pointer" }} hover role="checkbox" tabIndex={-1} key={project.id}>
                    <StyledTableCell align="left">
                      <Link to={`/projects/${project.id}`} component={RouterLink}>
                        {project.name}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <ProjectStatusLabel projectStatus={project.status} />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatDistance(Date.parse(project.last_modified), new Date(), { addSuffix: true })}
                    </StyledTableCell>
                    <StyledTableCell id={labelId} align="right">
                      <ProjectMenuOptions
                        project={project}
                        refreshTable={refreshTable}
                        handleFavorites={(p) => handleFavorites(p)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>

        <TablePagination
          sx={{ backgroundColor: CARD_BG_COLOR }}
          rowsPerPageOptions={[10]}
          component="div"
          count={favoriteProjects.length}
          rowsPerPage={params.limit || 0}
          page={(params.offset || 0) / (params.limit || 0)}
          onPageChange={() => {
            return null;
          }}
        />
      </Paper>
    </>
  ) : null;
}
