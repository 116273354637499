import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "utils/MuiWrapper/components";
import { useNavigate } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "components/ProjectsPage/tableDependencies";
import { formatDistance } from "date-fns";
import { TemplateMenuOptions } from "components/Templates/TemplateMenuOptions";
import { Box } from "utils/MuiWrapper/components";
import ProjectStatusLabel from "components/ProjectsPage/ProjectStatusLabel";
type TableProps = {
  templates;
  onSelectRow?;
  offset?;
  setOffset?;
  showActions?: boolean;
  setRows?;
  limit?;
  rowsPerPageOptions?;
};

export default function TemplatesTable({
  templates,
  onSelectRow,
  offset,
  setOffset,
  showActions,
  setRows,
  limit,
  rowsPerPageOptions,
}: TableProps) {
  const navigate = useNavigate();
  const navigateToEditor = (template) => {
    navigate(`/template-builder/${template.id}`, { state: { template } });
  };
  const handlePageChange = (event, newPage) => setOffset(newPage);
  const handleRowChange = (event) => setRows(event);

  return (
    <>
      <Box sx={{ maxHeight: "74vh", overflow: "auto" }}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left" width={3}>
                  Status
                </StyledTableCell>
                <StyledTableCell align="right">Last Revised</StyledTableCell>
                {showActions && <StyledTableCell align="right">Actions</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody data-testid="templates-table">
              {templates?.results?.map((template) => (
                <StyledTableRow
                  sx={{ cursor: "pointer" }}
                  hover={true}
                  selected={true}
                  onClick={() => {
                    (onSelectRow || navigateToEditor)(template);
                  }}
                  key={template.id}
                >
                  <StyledTableCell component="th" scope="row">
                    {template.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ProjectStatusLabel projectStatus={template.status} />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatDistance(Date.parse(template.updated_at), new Date(), { addSuffix: true })}
                  </StyledTableCell>
                  {showActions && (
                    <StyledTableCell align="right">
                      <TemplateMenuOptions template={template} />
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {setOffset && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={templates?.total_results || 0}
          rowsPerPage={limit}
          page={templates?.total_results ? offset : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowChange}
        />
      )}
    </>
  );
}
