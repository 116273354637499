import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "utils/MuiWrapper/components";
import MUIDataTable from "mui-datatables";
import { CreateComponent } from "components/ComponentToolbox/CreateComponent";
import { useState } from "react";
import { useSearchComponentStateStore } from "./SearchComponentState";
import { AddBOMItem } from "components/BOMView/AddBOMItem/AddBOMItem";

const columns = [
  {
    name: "id",
    label: "id",
    options: {
      display: false,
      viewColumns: false,
    },
  },
  {
    name: "partNumber",
    label: "Part No.",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "partDescription",
    label: "Part Description",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "manufacturer",
    label: "Manufacturer",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "price",
    label: "Price",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        const pf = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD", // TODO: Update this, when pricing is returned by region
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return pf.format(value);
      },
    },
  },
  {
    name: "discount",
    label: "Discount",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        const df = new Intl.NumberFormat("en-US", {
          style: "percent",
          minimumFractionDigits: 2,
        });

        return df.format(Number(value / 100));
      },
    },
  },
  {
    name: "acConnections",
    label: "AC Connections",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "powerUsage",
    label: "Power Usage",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "rackUnits",
    label: "Rack Units",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export type ComponentTableData = {
  options: object;
  data: [];
};

type ComponentPartListTypes = {
  isOpen: boolean;
  handleClose: () => void;
  tableData: ComponentTableData;
  showQuantity?: boolean;
  quantity?: number | "";
  setQuantity?: React.Dispatch<React.SetStateAction<number | "">>;
  handleCreate: (id: string) => void;
  isBOMSelector?: boolean;
};

export const ComponentPartList = ({
  isOpen,
  handleClose,
  tableData,
  showQuantity,
  quantity,
  setQuantity,
  handleCreate,
  isBOMSelector = false,
}: ComponentPartListTypes) => {
  const [showCreate, setShowCreate] = useState(false);
  const [showAddBOMItem, setShowAddBOMItem] = useState(false);
  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "" ? "" : parseInt(event.target.value);
    if (setQuantity) {
      setQuantity(value);
    }
  };

  const searchState = useSearchComponentStateStore((state) => state);

  function handlePreserveSearchChange(): void {
    searchState.updatePreserve(!searchState.preserve);
  }

  function handleCreateClose(component): void {
    if (component !== null) {
      handleCreate(component);
    }
    setShowCreate(false);
  }
  function handleBOMClose(didAddItem: boolean): void {
    setShowAddBOMItem(false);
    if (didAddItem) {
      handleClose();
    }
  }
  return (
    <>
      <CreateComponent isOpen={showCreate} handleClose={handleCreateClose} />
      <AddBOMItem isOpen={showAddBOMItem} handleClose={handleBOMClose} />
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" PaperProps={{ sx: { overflowY: "hidden" } }}>
        <DialogContent dividers sx={{ overflowY: "hidden" }}>
          <DialogTitle sx={{ float: "left" }}>Select Component</DialogTitle>
          <FormGroup sx={{ float: "right", marginTop: "1em" }}>
            <FormControlLabel
              control={<Switch />}
              checked={searchState.preserve}
              onChange={handlePreserveSearchChange}
              label="Preserve Search"
            />
          </FormGroup>
        </DialogContent>
        <DialogContent>
          {showQuantity && (
            <TextField required label="Quantity" type="number" value={quantity} onChange={handleQuantityChange} />
          )}
          <MUIDataTable data={tableData.data} columns={columns} options={tableData.options} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreate(true)}>Create Component</Button>
          {isBOMSelector && !tableData?.data?.length && (
            <Button onClick={() => setShowAddBOMItem(true)}>Add BOM Item</Button>
          )}

          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
