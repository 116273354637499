import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "utils/MuiWrapper/components";
import { AgGridReact } from "ag-grid-react";
import { useBOMViewState } from "components/BOMView/BOMView";
import { EditEndColDefs, InitialColumnDefs, StatusColDef } from "components/BOMView/ColumnDefinitions";
import { useGetUser } from "api/auth";
import { useAuthStore } from "utils/GlobalStores/authStore";

export const GroupEditPreview = () => {
  const userId = useAuthStore((state) => state.userId);
  const { data: user } = useGetUser(userId);
  const gridRef = useRef<AgGridReact | null>(null);
  const { selectedRowsData, setSelectedRowIDs } = useBOMViewState((state) => state);
  const [rowData, setRowData] = useState([]);

  const [columnDefsTest] = useState([
    {
      checkboxSelection: true,
      minWidth: 50,
      resizable: false,
      lockPosition: true,
    },
    ...StatusColDef(false),
    ...InitialColumnDefs(false),
    ...EditEndColDefs(false),
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      lockVisible: true,
      lockPinned: true,
    };
  }, []);

  useEffect(() => {
    setRowData(selectedRowsData);
  }, [selectedRowsData]);

  const handleOnFirstDataRendered = (params) => {
    // Setting 3rd arg to true so that UI won't lock up when many rows displayed
    params.api.forEachNode((node) => node.setSelected(true, false, true));
  };

  const handleSelectionChanged = (gridAPI) => {
    const selectedIDs = gridAPI?.api?.getSelectedRows().map((row) => row.id);
    setSelectedRowIDs(selectedIDs);
  };

  const restoreColumnState = useCallback(() => {
    if (!user) return;
    const userColumnState = localStorage.getItem(`userID:${user.id}-bomGridColumnState`);
    if (userColumnState) {
      const columnState = JSON.parse(userColumnState);
      gridRef?.current?.columnApi?.applyColumnState({ state: columnState, applyOrder: true });
    }
  }, [gridRef]);

  return (
    <Box sx={{ width: "100%", height: "100%" }} className="ag-theme-alpine-dark ag-theme-custom">
      <AgGridReact
        ref={gridRef}
        onModelUpdated={() => restoreColumnState()}
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefsTest}
        suppressColumnMoveAnimation={true}
        rowSelection={"multiple"}
        onFirstDataRendered={handleOnFirstDataRendered}
        onSelectionChanged={handleSelectionChanged}
      ></AgGridReact>
    </Box>
  );
};
