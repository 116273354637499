import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Templates from "components/Templates/Templates";
import ProjectsPage from "components/ProjectsPage";
import FavoritesTable from "components/ProjectsPage/FavoritesTable";
import { TabPanel, a11yProps } from "components/shared/TabPanel";
import { useAuthStore } from "utils/GlobalStores/authStore";
import { useGetUser } from "api/auth";
import { Roles } from "utils/enums";
import { useLibraryTabStore } from "./TabState";

export default function TemplateProjectTabView() {
  const userId = useAuthStore((state) => state.userId);
  const { tabIndex, updateTabIndex, updateSearchTerm, updateOffset, updateLimit } = useLibraryTabStore(
    (state) => state
  );
  const { data: user } = useGetUser(userId);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    updateTabIndex(newValue);
    updateSearchTerm("");
    updateOffset(0);
    updateLimit(10);
  };

  return (
    <Box mt={3} sx={{ width: "100%" }}>
      <Tabs value={tabIndex} onChange={handleChange}>
        <Tab label="Favorites" {...a11yProps(0)} />
        <Tab label="Projects" {...a11yProps(1)} />
        {user?._embedded.role.name === Roles.Architect && <Tab label="Templates" {...a11yProps(2)} />}
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <FavoritesTable />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ProjectsPage />
      </TabPanel>
      {user?._embedded.role.name === Roles.Architect && (
        <TabPanel value={tabIndex} index={2}>
          <Templates showActions={true} />
        </TabPanel>
      )}
    </Box>
  );
}
