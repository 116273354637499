import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { getComponents } from "api/component";
import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";
import { Link, Button, Select, MenuItem, FormControl, FormHelperText, Typography } from "utils/MuiWrapper/components";
import { Link as RouterLink } from "react-router-dom";
import SearchBar from "../shared/SearchBar";
import { CreateComponent } from "./CreateComponent";
import { StandardDate } from "utils/dates";
import { AxiosError } from "axios";

const ComponentToolbox = () => {
  const [totalResults, setTotalResults] = useState(0);
  const [quickSearchText, setQuickSearchText] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState();
  const columns: ColDef[] = [
    { field: "manufacturer", headerName: "Manufacturer", minWidth: 200, valueFormatter: getName, sortable: true },
    { field: "part_number", headerName: "Number", minWidth: 200, sortable: true },
    {
      field: "part_description",
      headerName: "Description",
      minWidth: 200,
      flex: 1,
      suppressSizeToFit: true,
      resizable: false,
    },
    { field: "price", headerName: "Price", valueFormatter: currencyFormat, minWidth: 200 },
    { field: "discount", headerName: "Discount", valueFormatter: currencyFormat, minWidth: 200 },
    {
      field: "created_at",
      headerName: "Created",
      resizable: false,
      sortable: true,
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
    {
      field: "updated_at",
      headerName: "Last Modified",
      resizable: false,
      sortable: true,
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
  ];
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "84vh" }), []);
  const gridRef = useRef<AgGridReact | null>(null);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  const dataSource = {
    rowCount: undefined,
    getRows: async (params) => {
      try {
        gridRef?.current?.api.showLoadingOverlay();
        const res = await getComponents({
          limit: 100,
          offset: params.startRow,
          quick_search: quickSearchText,
          order_by: params.sortModel[0]?.colId,
          order_direction: params.sortModel[0]?.sort,
        });
        const { results, total_results } = res;
        setTotalResults(total_results);
        params.successCallback(results, total_results);
      } catch (error) {
        console.log("get data error: ", error);
        if (error instanceof AxiosError) {
          setAlert({
            type: "error",
            message: `Could not get component data. ${error?.response?.data?.detail || ""}`,
          });
        } else {
          setAlert({
            type: "error",
            message: `Could not get component data`,
          });
        }
      } finally {
        gridRef?.current?.api?.hideOverlay();
      }
    },
  };

  const handleClose = () => {
    setIsOpen(false);
    gridRef?.current?.api?.setDatasource(dataSource);
  };

  function currencyFormat(num) {
    if (num.value) return num.value.toFixed(2);
    else {
      return "0.00";
    }
  }

  function getName(manufacturer) {
    return manufacturer.value?.name;
  }

  useEffect(() => {
    gridRef?.current?.api?.setDatasource(dataSource);
  }, [quickSearchText]);

  const onGridReady = useCallback(
    (params) => {
      params.api.setDatasource(dataSource);
    },
    [totalResults, quickSearchText]
  );

  const searchComponents = (searchString: string) => {
    setQuickSearchText(searchString);
  };

  const onPageSizeChanged = useCallback((event) => {
    gridRef?.current?.api?.paginationSetPageSize(parseInt(event.target.value));
    gridRef?.current?.api?.setDatasource(dataSource);
  }, []);

  const onSelectionChanged = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows();
    if (selectedRows) {
      setSelected(selectedRows[0]);
      setIsOpen(true);
    }
  };

  return (
    <>
      <Typography variant="h1">Components</Typography>

      <CreateComponent isOpen={isOpen} handleClose={handleClose} data={selected} />
      <SearchBar
        delegateSearch={searchComponents}
        handleShow={() => {
          setSelected(undefined);
          setIsOpen(true);
        }}
      />
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine-dark ag-theme-custom">
          <AgGridReact
            ref={gridRef}
            columnDefs={columns}
            alwaysShowHorizontalScroll
            infiniteInitialRowCount={totalResults}
            onGridReady={onGridReady}
            rowModelType={"infinite"}
            rowSelection="single"
            pagination={true}
            onSelectionChanged={onSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
      <Link to={`/manufacturers`} component={RouterLink}>
        <Button
          sx={{
            mt: 2,
          }}
          variant="contained"
        >
          To Manufacturers
        </Button>
      </Link>
      {/* <Link to={`/contacts`} component={RouterLink}>
        <Button
          sx={{
            mt: 2,
            mx: 2,
          }}
          variant="contained"
        >
          To Contacts
        </Button>
      </Link> */}
      <FormControl sx={{ float: "right", mt: 2 }}>
        <Select value={100} label="Page Size" onChange={onPageSizeChanged}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
        </Select>
        <FormHelperText sx={{ mt: 1 }}>Page Size</FormHelperText>
      </FormControl>
    </>
  );
};

export default ComponentToolbox;
