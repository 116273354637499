import { DesktopDatePicker } from "utils/MuiWrapper/datepicker";

type NorthStarDesktopDatePickerProps = {
  value: string;
  onChange: (value: string, keyboardInputValue?: string | undefined) => void;
  renderInput: (params) => JSX.Element;
};

export const NorthStarDesktopDatePicker = (props: NorthStarDesktopDatePickerProps) => {
  const onChange = (value, keyboardInputValue?: string | undefined) => {
    const result =
      value && value.getUTCFullYear() > 999
        ? new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), 12, 0, 0)
        : value;
    props.onChange(result, keyboardInputValue);
  };

  const handleValue = (value) => {
    if (!value) return value;
    const result = new Date(value);
    return result && result.getUTCFullYear() > 999 ? new Date(result.setHours(12, 0, 0, 0)) : result;
  };

  return <DesktopDatePicker value={handleValue(props.value)} onChange={onChange} renderInput={props.renderInput} />;
};
