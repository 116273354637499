import { useState } from "react";
import { Table, TableBody, TableRow, TextField, Autocomplete, Chip, Paper } from "utils/MuiWrapper/components";
import { styled } from "@mui/material/styles";

import { StyledTableCell } from "./ProjectInfo";
import { useGetUsers } from "api/auth";
import { User } from "types/User";
import { TeamMember } from "types/Project";

export const EditTeam = ({ project, editProjectTeam, deleteProjectTeamMember }) => {
  const [selectedProjectManager, setSelectedProjectManager] = useState<User>();
  const [selectedProjectMember, setSelectedProjectMember] = useState<User>();

  const [projectManagerValue, setProjectManagerValue] = useState("");
  const [projectMemberValue, setProjectMemberValue] = useState("");

  const { data: users } = useGetUsers();

  const handleProjectManagerSelect = (_, user: User | null) => {
    if (!user) return;

    setSelectedProjectManager(undefined);
    setProjectManagerValue("");
    editProjectTeam({ user_id: [user.id], role: "project_manager" });
  };

  const handleProjectMemberSelect = (_, user: User | null) => {
    if (!user) return;

    setSelectedProjectMember(undefined);
    setProjectMemberValue("");
    editProjectTeam({ user_id: [user.id], role: "project_member" });
  };

  const handleProjectManagerValue = (_, value: string, reason) => {
    if (reason !== "reset") {
      setProjectManagerValue(value);
    }
  };

  const handleProjectMemberValue = (_, value: string, reason) => {
    if (reason !== "reset") {
      setProjectMemberValue(value);
    }
  };

  const handleDelete = (teamMember?: TeamMember) => () => {
    if (!teamMember) return null;

    deleteProjectTeamMember(teamMember);
  };

  const projectManagerMap = new Map<string, TeamMember>();
  const projectMemberMap = new Map<string, TeamMember>();
  project?.project_teams?.map((teamMember: TeamMember) =>
    teamMember.role === "project_manager"
      ? projectManagerMap.set(teamMember.user_id, teamMember)
      : projectMemberMap.set(teamMember.user_id, teamMember)
  );

  const usersMap = new Map<string, User>();
  const teamOptions: Array<User> = [];
  users?.results?.map((user) => {
    usersMap.set(user.id, user);

    if (!projectManagerMap.has(user.id) && !projectMemberMap.has(user.id)) {
      teamOptions.push(user);
    }
  });

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <StyledTableCell>Project Manager</StyledTableCell>
          <StyledTableCell>
            <Paper
              sx={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {Array.from(projectManagerMap.values())?.map((teamMember) => {
                return (
                  <ListItem key={teamMember.user_id}>
                    <Chip label={usersMap.get(teamMember.user_id)?.name} onDelete={handleDelete(teamMember)} />
                  </ListItem>
                );
              })}
            </Paper>
            <Autocomplete
              options={teamOptions}
              getOptionLabel={(user: User) => user.name}
              noOptionsText="No users found"
              blurOnSelect={true}
              value={selectedProjectManager}
              onChange={handleProjectManagerSelect}
              inputValue={projectManagerValue}
              onInputChange={handleProjectManagerValue}
              renderInput={(params) => <TextField {...params} fullWidth />}
              sx={{ input: { height: 8 } }}
            />
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>Project Team</StyledTableCell>
          <StyledTableCell>
            <Paper
              sx={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {Array.from(projectMemberMap.values())?.map((teamMember) => {
                return (
                  <ListItem key={teamMember.user_id}>
                    <Chip label={usersMap.get(teamMember.user_id)?.name} onDelete={handleDelete(teamMember)} />
                  </ListItem>
                );
              })}
            </Paper>
            <Autocomplete
              options={teamOptions}
              getOptionLabel={(user: User) => user.name}
              noOptionsText="No users found"
              blurOnSelect={true}
              value={selectedProjectMember}
              onChange={handleProjectMemberSelect}
              inputValue={projectMemberValue}
              onInputChange={handleProjectMemberValue}
              renderInput={(params) => <TextField {...params} fullWidth />}
              sx={{ input: { height: 8 } }}
            />
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
