import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "utils/MuiWrapper/components";
import { useState, useEffect } from "react";
import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { useFormik } from "formik";
import { manufacturerSchema } from "./ManufacturerSchema";
import {
  deleteManufacturerAddress,
  patchManufacturer,
  patchManufacturerAddress,
  postManufacturer,
  postManufacturerAddress,
} from "api/manufacturer";
import { FormModalProps } from "components/ComponentToolbox/CreateComponent";
import { AddEditAddress } from "./AddEditAddress";
import AddIcon from "@mui/icons-material/Add";
import { Address, AddressDTO } from "types/Address";

export type CreateManufacturerInitialValues = {
  name: string;
  addresses: Address[];
};

export const CreateManufacturer = ({ isOpen, handleClose, data }: FormModalProps) => {
  const onSubmit = () => {
    addressDeletionList.every((addressID) => {
      deleteManufacturerAddress(addressID, id).catch((error) => {
        setAlert({ type: "error", message: error.message });
      });
    });
    setAddressDeletionList([]);

    if (id) {
      patchManufacturer(id, { name: values.name }).then(
        (response) => {
          const promises: Promise<unknown>[] = [];
          values.addresses.forEach((addr: Address) => {
            if (addr.id) {
              promises.push(
                patchManufacturerAddress(addr.id, id, addr as AddressDTO).catch((error) => {
                  setAlert({ type: "error", message: error.message });
                })
              );
            } else {
              promises.push(
                postManufacturerAddress(response?.data?.id, addr as AddressDTO).catch((error) => {
                  setAlert({ type: "error", message: error.message });
                })
              );
            }
          });
          Promise.all(promises).then(() => {
            setAlert({ type: "success", message: "The manufacturer was changed successfully" });
            resetForm();
            handleClose(response?.data);
          });
        },
        (error) => {
          setAlert({ type: "error", message: error.message });
        }
      );
    } else {
      postManufacturer({ name: values.name })
        .then((response) => {
          const promises: Promise<unknown>[] = [];
          values.addresses.forEach((addr) => {
            promises.push(
              postManufacturerAddress(response?.data?.id, addr).catch((error) => {
                setAlert({ type: "error", message: error.message });
              })
            );
            Promise.all(promises).then(() => {
              setAlert({ type: "success", message: "The manufacturer was created successfully" });
              resetForm();
              handleClose(response?.data);
            });
          });
        })
        .catch((error) => {
          setAlert({ type: "error", message: error.message });
        });
    }
  };
  const initialValues: CreateManufacturerInitialValues = { name: "", addresses: [] };
  const { values, errors, handleBlur, touched, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: manufacturerSchema,
    onSubmit,
  });

  const [id, setId] = useState<string>();
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const [addressDeletionList, setAddressDeletionList] = useState<string[]>([]);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);

  useEffect(() => {
    setShowAddressModal(false);
    resetForm();
    if (data) {
      setId(data.id);
      setFieldValue("name", data.name);
      setFieldValue("addresses", data.address);
    } else {
      setId(undefined);
    }
  }, [isOpen]);

  const hasError = (touched: boolean | undefined, error: string | undefined) => {
    return touched && (error ?? "").length > 0;
  };

  const handleAddressChange = (addr?) => {
    setShowAddressModal(false);
    if (!addr) return;
    if (addr?.id) {
      setFieldValue("addresses", [...values.addresses.filter((x) => x.id !== addr.id), addr]);
    } else {
      setFieldValue("addresses", [...values.addresses, addr]);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth PaperProps={{ sx: { overflowY: "hidden" } }}>
      <DialogContent>
        <DialogTitle>{id ? "Edit" : "Add"} Manufacturer</DialogTitle>
      </DialogContent>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={10}>
            <Grid item md={6}>
              <TextField
                name="name"
                margin="normal"
                fullWidth
                autoFocus
                label="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={hasError(touched.name, errors.name)}
                helperText={hasError(touched.name, errors.name) ? errors.name : ""}
              />
              <h4>Addresses</h4>
              {!values.addresses?.length && (
                <p style={{ marginTop: "1em", marginBottom: "1em" }}>No addresses to display...</p>
              )}
              {values.addresses?.map((addr: Address, index) => (
                <Stack spacing="1em" direction="row" mb={2} key={index}>
                  <Stack>
                    <p>{addr.address_line1}</p>
                    <p>{addr.address_line2}</p>
                    <p>
                      {addr.city}, {addr.state_region} {addr.postcode}
                    </p>
                    <p>{addr.country}</p>
                    <p>{addr.phone_number}</p>
                  </Stack>
                  <Stack spacing={1}>
                    <Button
                      disabled={showAddressModal}
                      onClick={() => {
                        setSelectedAddress(addr);
                        setShowAddressModal(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={showAddressModal}
                      onClick={() => {
                        setFieldValue("addresses", [
                          ...values.addresses.filter((x: Address, arrIndex) => arrIndex !== index),
                        ]);
                        if (addr.id) {
                          setAddressDeletionList([...addressDeletionList, addr.id]);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Stack>
              ))}
              <Button
                startIcon={<AddIcon />}
                fullWidth
                disabled={showAddressModal}
                onClick={() => {
                  setSelectedAddress(undefined);
                  setShowAddressModal(true);
                }}
              >
                Address
              </Button>
            </Grid>
            <Grid item md={6}>
              <AddEditAddress
                isOpen={showAddressModal}
                handleClose={(addr) => {
                  handleAddressChange(addr);
                }}
                data={selectedAddress}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit" disabled={showAddressModal} sx={{ float: "right" }}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
