import { useProjectLookupState } from "utils/GlobalStores/projectStore";
import { StandardDate } from "utils/dates";
import { UserRenderer } from "utils/AgGridRenderers";

const NumberFormat = (colAPI) => {
  if (colAPI.value === undefined) return "";
  return colAPI.value.toLocaleString();
};

const ProjectNameFormat = (colAPI) => {
  const { projectLookup } = useProjectLookupState((state) => state);
  return colAPI.value === undefined ? "" : (projectLookup && projectLookup[colAPI.value]) ?? "";
};

export const InitialColumnDefs = () => {
  return [
    {
      field: "received",
      headerName: "Received",
      valueFormatter: (params) =>
        params.data?.received !== undefined && params.data.received !== null && params.data.received !== ""
          ? params.data.received
          : "0",
      editable: true,
    },
    { field: "remaining", headerName: "Remaining", cellRenderer: NumberFormat },
    { field: "qty", headerName: "PO Quantity", cellRenderer: NumberFormat },
    { field: "qty_rx", headerName: "Quantity Received", cellRenderer: NumberFormat },
    { field: "project_id", headerName: "Project", cellRenderer: ProjectNameFormat },
    { field: "supplier", headerName: "Supplier" },
    { field: "manufacturer", headerName: "Manufacturer" },
    {
      field: "manufacturer_part_number",
      headerName: "Manufacturer Part Number",
    },
    { field: "description", headerName: "Description" },
    {
      headerName: "Last Received",
      field: "last_received",
      cellRenderer: (props) => {
        if (props.value === undefined) return "";

        return StandardDate(props.value);
      },
    },
    {
      field: "user",
      headerName: "User",
      cellRenderer: UserRenderer,
    },
    {
      field: "rfp_number",
      headerName: "RFP Number",
      cellRenderer: NumberFormat,
    },
    {
      field: "comment",
      headerName: "Comment",
    },
  ];
};
