export function getMaxOrdinal(fields) {
  if (fields.length === 0) {
    return 0;
  }
  if (fields.length === 1) {
    return fields[0].ordinal;
  }
  const max = fields.reduce((first, second) => {
    return first.ordinal > second.ordinal ? first : second;
  });
  return max.ordinal;
}
