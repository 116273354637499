import { create } from "zustand";

type UserMapType = {
  [id: string]: string;
};

type UserType = {
  userLookup: UserMapType | null;
  updateUserLookup: (x: UserMapType) => void;
};

export const useUserLookupState = create<UserType>((set) => ({
  userLookup: null,
  updateUserLookup: (userLookup) => set({ userLookup }),
}));
