import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button } from "utils/MuiWrapper/components";

import { useAlertSnackbarState } from "../AlertSnackbar/AlertSnackbar";
import { create } from "zustand";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import { generateBOM } from "http/client";
import { DATACENTER_TEMPLATE } from "utils/constants";
import { AxiosError } from "axios";

export default function GenerateBOM({ templateId, templateType, projectId, projectTemplateId, preflightChecks }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const { setHasError } = useGenerateBOMState((state) => state);

  const { mutate: createBOM } = useMutation({
    mutationFn: () =>
      generateBOM({
        template_id: templateId,
        project_id: projectId,
        project_template_id: projectTemplateId,
        datacenter_bom: templateType === DATACENTER_TEMPLATE,
      }),
    onSuccess: (response) => {
      setHasError(false);
      setIsConfirmOpen(false);
      window.open(
        `/bom-view/${response?.data?.bom_cache_key}/project/${projectId}/project-template/${projectTemplateId}`,
        "_blank"
      );
    },
    onError: (data) => {
      setHasError(true);
      setIsConfirmOpen(false);
      if (data instanceof AxiosError) {
        setAlert({ type: "error", message: data.response?.data?.error_message || "The BOM could not be generated" });
      } else {
        setAlert({ type: "error", message: "The BOM could not be generated" });
      }
    },
  });

  const handleBOMButton = () => setIsConfirmOpen(true);

  const handleBOMConfirm = async () => {
    if (await preflightChecks()) {
      createBOM();
    } else {
      setHasError(true);
      setIsConfirmOpen(false);
      setAlert({ type: "error", message: "Template is missing values required to generate BOM." });
    }
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmOpen}
        title={"Are you sure you want to generate a BOM?"}
        setIsOpen={setIsConfirmOpen}
        confirmAction={async () => handleBOMConfirm()}
        displayText={{ confirm: "Yes", cancel: "No" }}
      />
      <Button
        variant="contained"
        onClick={handleBOMButton}
        size="medium"
        sx={{
          padding: 6,
        }}
      >
        Generate BOM
      </Button>
    </>
  );
}

type GenerateBOMType = {
  hasError: boolean;
  setHasError: (x: boolean) => void;
};

export const useGenerateBOMState = create<GenerateBOMType>((set) => ({
  hasError: false,
  setHasError: (hasError: boolean) => set({ hasError }),
}));
