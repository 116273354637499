import { Navigate } from "react-router-dom";

const Protected = ({ conditional, children }) => {
  if (!conditional) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default Protected;
