import { getRequest, patchRequest, postRequest } from "../http/componentClient";
import { QueryParamsType } from "../types/Queries";
import { deleteRequest, formatError } from "http/client";

export async function getManufacturers(params?: QueryParamsType) {
  try {
    const res = await getRequest("/v1/api/manufacturers", params);
    return res.data;
  } catch (error) {
    return formatError(`Could not fetch manufacturers.`, error);
  }
}
export async function postManufacturer(data) {
  try {
    const res = await postRequest(`/v1/api/manufacturers`, data);
    return res;
  } catch (error) {
    return formatError(`Could not save manufacturer`, error);
  }
}
export async function postManufacturerAddress(id, data) {
  try {
    const res = await postRequest(`/v1/api/manufacturers/${id}/addresses`, data);
    return res;
  } catch (error) {
    return formatError(`Could not save address`, error);
  }
}

export async function patchManufacturer(id, data) {
  try {
    const res = await patchRequest(`/v1/api/manufacturers/${id}`, data);
    return res;
  } catch (error) {
    return formatError(`Could not update manufacturer`, error);
  }
}
export async function patchManufacturerAddress(id, manufacturerID, data) {
  try {
    const res = await patchRequest(`/v1/api/manufacturers/${manufacturerID}/addresses/${id}`, data);
    return res;
  } catch (error) {
    return formatError(`Could not save address`, error);
  }
}
export async function deleteManufacturerAddress(id, manufacturerID) {
  try {
    const res = await deleteRequest(`/v1/api/manufacturers/${manufacturerID}/addresses/${id}`);
    return res;
  } catch (error) {
    return formatError(`Could not delete address`, error);
  }
}
