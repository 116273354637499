import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
} from "utils/MuiWrapper/components";
import { Close } from "utils/MuiWrapper/icons";
import AddIcon from "@mui/icons-material/Add";
import React, { useState, useEffect } from "react";
import Rule from "./Rule";
import debounce from "lodash.debounce";
import { createRule, createRuleAction, createRuleConditions, updateRuleAction } from "api/rule";
import { RuleVariableSelect } from "components/TemplateBuilder/Iterations/Rules/RuleVariableSelect";
import { ComponentPartList, ComponentTableData } from "../../../ComponentPartList/ComponentPartList";
import { getComponents } from "api/component";
import { useQueryClient } from "@tanstack/react-query";
import { QueryParamsType } from "types/Queries";
import { ACTIVE_BORDER } from "utils/constants";
import { useSearchComponentStateStore } from "components/ComponentPartList/SearchComponentState";
import { useAlertSnackbarState } from "components/AlertSnackbar/AlertSnackbar";

const RuleSet = ({
  ruleSet,
  templateId,
  templateIsPublished,
  fieldCollectionId,
  columnOneFieldId,
  columnTwoFieldId,
  addRuleActionValue,
  count,
  sequenceId,
}) => {
  const queryClient = useQueryClient();
  const setAlert = useAlertSnackbarState((state) => state.setAlert);
  const [ruleActionVariableId, setRuleActionVariableId] = useState(null);
  const [rules, setRules] = useState(ruleSet.rules);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [newRuleType, setNewRuleType] = useState("");
  const [component, setComponent] = useState("");
  const [isRuleSetActive, setIsRuleSetActive] = useState(false);
  const [componentsTableData, setComponentsTableData] = useState<ComponentTableData>({ options: {}, data: [] });
  const [disableDelete, setDisableDelete] = useState(false);
  const [numRowsPerPage, setNumRowsPerPage] = useState(10);
  const searchState = useSearchComponentStateStore((state) => state);
  const stateSearchTerm = useSearchComponentStateStore((state) => state.searchTerm);

  useEffect(() => {
    if (
      columnOneFieldId &&
      columnTwoFieldId &&
      ruleSet.columnOne === columnOneFieldId &&
      ruleSet.columnTwo === columnTwoFieldId
    ) {
      setIsRuleSetActive(true);
    } else {
      setIsRuleSetActive(false);
    }
    setRules(ruleSet.rules);
  }, [ruleSet, columnOneFieldId, columnTwoFieldId]);

  useEffect(() => {
    if (rules.length <= 1) {
      setDisableDelete(true);
    } else {
      setDisableDelete(false);
    }
  }, [rules]);

  const parseAndSetComponentsTableData = (components, totalComponents = 0, onRowClick, rowsPerPage, searchTerm) => {
    const data = components.map((component) => {
      return {
        id: component.id,
        partNumber: component.part_number,
        partDescription: component.part_description,
        manufacturer: component.manufacturer?.name,
        price: component.price,
        discount: component.discount,
        acConnections: component.ac_connections,
        powerUsage: component.power_usage,
        rackUnits: component.rack_units,
      };
    });

    const options = {
      search: true,
      searchProps: {
        autoComplete: "off",
      },
      searchText: searchTerm,
      download: false,
      print: false,
      filter: false,
      selectableRows: "none",
      onRowClick: onRowClick,
      serverSide: true,
      count: totalComponents,
      pagination: true,
      searchAlwaysOpen: true,
      viewColumns: false,
      onTableChange: debouncedOnTableChange,
      rowsPerPage: rowsPerPage || numRowsPerPage,
      rowsPerPageOptions: [10, 50, 100],
      onChangeRowsPerPage: (numRows: number) => setNumRowsPerPage(numRows),
    };

    setComponentsTableData((prev) => ({ ...prev, data, options }));
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);

  const handleCreateAdditionRule = () => {
    const ruleActionData = {
      ordinal: 0,
      variable_id: ruleActionVariableId,
    };
    handleCreateRule("addition", ruleActionData);
  };

  const handleCreateRule = async (ruleType: string, ruleActionData) => {
    try {
      const ruleData = {
        salience: 1,
        template_section_field_options: {
          col1: ruleSet.columnOne,
          col2: ruleSet.columnTwo,
          variable_id: ruleSet.variableId,
        },
        rule_type: ruleType,
        template_section_id: fieldCollectionId,
        sequence_id: sequenceId,
      };

      const rule = await createRule(templateId, ruleData);

      const ruleConditionData = {
        operator: "and",
        rule_conditions: [
          {
            operator: "eq",
            template_section_field_option_id: ruleSet.columnOne,
            value: ruleSet.columnOne,
            variable_id: ruleSet.variableId,
          },
          {
            operator: "eq",
            template_section_field_option_id: ruleSet.columnTwo,
            value: ruleSet.columnTwo,
            variable_id: ruleSet.variableId,
          },
        ],
      };
      const ruleCondition = await createRuleConditions(rule.id, ruleConditionData);
      rule.rule_conditions.push(ruleCondition);

      const rule_action = await createRuleAction(rule.id, ruleActionData);
      rule.rule_actions.push(rule_action);

      const _rule = addRuleActionValue(rule);
      setRules([...rules, _rule]);
      handleClose();
      setNewRuleType("");
      setComponent("");
      setRuleActionVariableId(null);
      queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ type: "error", message: error.message });
      } else {
        setAlert({ type: "error", message: "There was an error while creating the rule." });
      }
    }
  };

  const onRowClick = (rowData) => {
    const ruleActionData = {
      ordinal: 0,
      value: `${rowData[1]} - ${rowData[2]}`,
      component_id: rowData[0],
    };
    handleCreateRule("component", ruleActionData);
    handleClose2();
  };

  const handleAddComponentRule = async () => {
    setNewRuleType("component");

    const params = {
      limit: numRowsPerPage,
      quick_search: searchState.preserve ? stateSearchTerm : "",
    };

    try {
      await getComponents(params).then((res) => {
        parseAndSetComponentsTableData(res.results, res.total_results, onRowClick, null, params.quick_search);
        handleShow2();
      });
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ type: "error", message: error.message });
      } else {
        setAlert({ type: "error", message: "Could not get components." });
      }
    }
  };

  const handleAddAdditionRule = () => {
    setNewRuleType("addition");
    handleShow();
  };

  const debouncedOnTableChange = debounce((action, tableState) => {
    handleOnTableChange(action, tableState);
  }, 500);

  const handleOnTableChange = async (action, tableState) => {
    if (action === "search" || action === "changePage" || action === "changeRowsPerPage") {
      const params: QueryParamsType = {
        limit: tableState.rowsPerPage,
        offset: tableState.page * tableState.rowsPerPage,
      };
      if (tableState.activeColumn) {
        params.order_by = tableState.sortOrder.name;
        params.order_direction = tableState.sortOrder.direction;
      }

      if (tableState.searchText) {
        params.quick_search = tableState.searchText;
        searchState.updateSearchTerm(tableState.searchText);
      }

      await getComponents(params)
        .then((res) => {
          parseAndSetComponentsTableData(
            res.results,
            res.total_results,
            localStorage.getItem("componentAction") === "update"
              ? onUpdateComponentRowClick
              : localStorage.getItem("componentAction")
              ? onAddComponentRowClick
              : onRowClick,
            tableState?.rowsPerPage,
            tableState?.searchText
          );
        })
        .catch((error) => {
          if (error instanceof Error) {
            setAlert({ type: "error", message: error.message });
          } else {
            setAlert({ type: "error", message: "Could not get components." });
          }
        });
    }
  };

  const onAddComponentRowClick = async (rowData) => {
    const rule = JSON.parse(localStorage.getItem("rule") || "");
    const ruleActionData = {
      ordinal: 0,
      value: `${rowData[1]} - ${rowData[2]}`,
      component_id: rowData[0],
    };
    try {
      const rule_action = await createRuleAction(rule.id, ruleActionData);
      rule.rule_actions.push(rule_action);
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ type: "error", message: error.message });
      } else {
        setAlert({ type: "error", message: "Could not create rule action." });
      }
    }
    const _rule = addRuleActionValue(rule);
    setRules(
      rules.map((r) => {
        if (r.id === _rule.id) {
          return _rule;
        }
        return r;
      })
    );
    localStorage.removeItem("rule");
    localStorage.removeItem("componentAction");
    handleClose2();
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const addRuleComponent = async (rule) => {
    localStorage.setItem("componentAction", "add");
    localStorage.setItem("rule", JSON.stringify(rule));

    const params = {
      limit: numRowsPerPage,
      quick_search: searchState.preserve ? stateSearchTerm : "",
    };

    await getComponents(params)
      .then((res) => {
        parseAndSetComponentsTableData(
          res.results,
          res.total_results,
          onAddComponentRowClick,
          null,
          params.quick_search
        );
        handleShow2();
      })
      .catch((error) => {
        if (error instanceof Error) {
          setAlert({ type: "error", message: error.message });
        } else {
          setAlert({ type: "error", message: "Could not get components." });
        }
      });
  };

  const onUpdateComponentRowClick = async (rowData) => {
    const rule = JSON.parse(localStorage.getItem("rule") || "");
    const ruleActionData = {
      ordinal: 0,
      value: `${rowData[1]} - ${rowData[2]}`,
      component_id: rowData[0],
    };
    if (rule) {
      try {
        const rule_action = await updateRuleAction(rule.id, rule.rule_actions[0].id, ruleActionData);
        rule.rule_actions[0] = rule_action;
      } catch (error) {
        if (error instanceof Error) {
          setAlert({ type: "error", message: error.message });
        } else {
          setAlert({ type: "error", message: "Could not update rule action." });
        }
      }
      const _rule = addRuleActionValue(rule);
      setRules(
        rules.map((r) => {
          if (r.id === _rule.id) {
            return _rule;
          }
          return r;
        })
      );
    }
    localStorage.removeItem("rule");
    localStorage.removeItem("componentAction");
    handleClose2();
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const updateRuleComponent = async (rule) => {
    localStorage.setItem("componentAction", "update");
    localStorage.setItem("rule", JSON.stringify(rule));

    const params = {
      limit: numRowsPerPage,
      quick_search: searchState.preserve ? stateSearchTerm : "",
    };

    await getComponents(params)
      .then((res) => {
        parseAndSetComponentsTableData(
          res.results,
          res.total_results,
          onUpdateComponentRowClick,
          null,
          params.quick_search
        );
        handleShow2();
      })
      .catch((error) => {
        if (error instanceof Error) {
          setAlert({ type: "error", message: error.message });
        } else {
          setAlert({ type: "error", message: "Could not get components." });
        }
      });
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const removeRule = (ruleId) => {
    setRules(
      rules.filter((rule) => {
        return rule.id != ruleId;
      })
    );
    queryClient.invalidateQueries(["template-rules", templateId, fieldCollectionId]);
  };

  const editRule = (rule) => {
    setRules(
      rules.map((r) => {
        if (r.id === rule.id) {
          return rule;
        }
        return r;
      })
    );
  };

  function handleCreateComponent(component) {
    const rowDataFake = [component.id, component.part_number, component.part_description];
    const func =
      localStorage.getItem("componentAction") === "update"
        ? onUpdateComponentRowClick
        : localStorage.getItem("componentAction")
        ? onAddComponentRowClick
        : onRowClick;

    func(rowDataFake);
  }

  return (
    <Box
      p={0.2}
      marginTop="3%"
      marginBottom="3%"
      sx={
        isRuleSetActive
          ? { border: `2px solid ${ACTIVE_BORDER}`, borderRadius: "5px" }
          : { border: "2px solid #555", borderRadius: "5px" }
      }
    >
      <Box display="flex" justifyContent="center">
        Rule Set {count}
      </Box>
      <Box>
        {rules?.map((rule, i) => {
          return (
            <Rule
              fieldCollectionId={fieldCollectionId}
              key={i}
              count={i}
              rule={rule}
              addRuleComponent={addRuleComponent}
              updateRuleComponent={updateRuleComponent}
              editRule={editRule}
              removeRule={removeRule}
              disableDelete={disableDelete}
              templateId={templateId}
              templateIsPublished={templateIsPublished}
            />
          );
        })}
      </Box>
      {isRuleSetActive ? (
        <Stack direction={{ xl: "row", lg: "column" }} justifyContent="center">
          <Button
            startIcon={<AddIcon />}
            disabled={templateIsPublished}
            variant="contained"
            onClick={handleAddComponentRule}
          >
            Component
          </Button>
          <Button
            startIcon={<AddIcon />}
            disabled={templateIsPublished}
            variant="contained"
            onClick={handleAddAdditionRule}
          >
            Addition
          </Button>
        </Stack>
      ) : (
        <></>
      )}

      <Dialog open={show} onClose={handleClose} PaperProps={{ sx: { overflowY: "hidden" } }}>
        <DialogContent dividers sx={{ overflowY: "hidden" }}>
          <DialogTitle>
            Add Rule Details
            <IconButton disabled={templateIsPublished} onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
        </DialogContent>
        <DialogContent>
          <Stack direction="column" spacing={4}>
            {newRuleType === "addition" && (
              <FormControl disabled={templateIsPublished}>
                <InputLabel id="rule-variable-select-label">Variable</InputLabel>
                <RuleVariableSelect
                  fieldCollectionId={fieldCollectionId}
                  value={ruleActionVariableId ?? "0"}
                  templateId={templateId}
                  onSelect={(event) => setRuleActionVariableId(event.target.value)}
                />
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={templateIsPublished} onClick={handleClose}>
            Close
          </Button>

          <Button
            disabled={
              templateIsPublished ||
              !newRuleType ||
              (newRuleType === "component" && !component) ||
              (newRuleType === "addition" && !ruleActionVariableId)
            }
            color="primary"
            onClick={() => handleCreateAdditionRule()}
          >
            Create Rule
          </Button>
        </DialogActions>
      </Dialog>
      <ComponentPartList
        isOpen={show2}
        handleClose={handleClose2}
        tableData={componentsTableData}
        handleCreate={handleCreateComponent}
      />
    </Box>
  );
};

export default RuleSet;
